import { types } from 'mobx-state-tree'

const model = types.model('Statement', {
  Type: types.literal('Statement'),
  objectId: types.identifier,
  args: types.string,
  string: types.string,
})

export default model
