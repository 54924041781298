import { types } from 'mobx-state-tree'
import RGBA from '../../RGBA'
import BackgroundColorOption from '../../BackgroundColorOption'
import Keyframe from '../../Keyframe'
import UI from './ui'

const Rectangle = {
  width:    types.number,
  height:   types.number,
}

const Layerable = {
  zIndex:   types.number,
  tabIndex: types.maybeNull(types.number),
}

const Fill = {
  backgroundColor: RGBA,
  backgroundColorOptions: types.array(BackgroundColorOption),
}

const Stroke = {
  borderThickness:  types.number,
  borderColor:      RGBA,
  borderStyle:      types.string,
  borderRadius:     types.number,
}

const Shadow = {
  boxShadowX:       types.number,
  boxShadowY:       types.number,
  boxShadowBlur:    types.number,
  boxShadowSpread:  types.number,
  boxShadowColor:   RGBA,
}

const Typography = {
  fontColor:        RGBA,
  fontSize:         types.number,
  fontFamily:       types.string,
  fontWeight:       types.number,
  fontStyle:        types.string,
  textAlign:        types.string,
  textShadowX:      types.number,
  textShadowY:      types.number,
  textShadowBlur:   types.number,
  textShadowSpread: types.number,
  textShadowColor:  RGBA,
}

const Visible = {
  active:           types.boolean,
  opacity:          types.number,
  activeKeyframes:  types.array(Keyframe),
  opacityKeyframes: types.array(Keyframe),
}

const Transformable = {
  origin:            types.array(types.number),
  position:          types.array(types.number),
  scale:             types.array(types.number),
  rotation:          types.array(types.number),
  positionKeyframes: types.array(Keyframe),
  scaleKeyframes:    types.array(Keyframe),
  rotationKeyframes: types.array(Keyframe),
}

const PseudoState = types.model({
  ...Rectangle,
  ...Layerable,
  ...Fill,
  ...Stroke,
  ...Shadow,
  ...Typography
})

const PseudoStates = types.model({
  active: types.compose(PseudoState),
  disabled: types.compose(PseudoState),
  hover: types.compose(PseudoState),
})

const MenuGroupPseudoStates = types.model({
  active: types.compose(PseudoState),
  disabled: types.compose(PseudoState),
  hover: types.compose(PseudoState),
  visited: types.compose(PseudoState),
  flagged: types.compose(PseudoState),
})


const Stateful = {
  states: PseudoStates
}

const LimitedPseudoState = types.model({
  ...Rectangle,
  ...Layerable,
  ...Stroke,
  ...Fill
})

const LimitedStateful = {
  states: types.model({
    active: types.compose(LimitedPseudoState),
    disabled: types.compose(LimitedPseudoState),
    hover: types.compose(LimitedPseudoState),
  })
}

const InputPseudoState = types.model({
  ...Rectangle,
  ...Layerable,
  ...Fill,
  ...Stroke,
  ...Typography
})

const InputStateful = {
  states: types.model({
    active: types.compose(InputPseudoState),
    disabled: types.compose(InputPseudoState),
    hover: types.compose(InputPseudoState),
  })
}

export {
  Rectangle,
  Layerable,
  Fill,
  Stroke,
  Shadow,
  Typography,
  Visible,
  Transformable,
  Stateful,
  LimitedStateful,
  InputStateful,
  UI,
  MenuGroupPseudoStates,
}

