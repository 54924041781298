import { action } from 'mobx'
import { autorun } from 'mobx'
import { observable } from 'mobx'
import { TASK_STATE } from './task-state.constants'
import { TASK_SORT_DIR } from './task-search.constants'
import { TASK_SORT_ORDER } from './task-search.constants'
import { TASK_SORT_ORDER_VIEW } from './task-search.constants'

export function taskQueueModel(taskQueueStore, taskStateStore, alert, prompt, app) {
  const EMPTY = observable([])
  const SORTABLE = {
    Position: true,
    Name: true,
    Version: false,
    Status: true,
    Author: true,
    Abort: false,
    RequestedBy: true,
    RequestTime: true,
    CompletionTime: true,
  }
  const bindings = {
    canSortProp: action.bound,
    confirmAbort: action.bound,
    isActiveSortProp: action.bound,
    nextPage: action.bound,
    reset: action.bound,
    resetSearch: action.bound,
    sortablePropHook: action.bound,
    updateSearch: action.bound,
    updateFilterAll: action.bound,
    updateFilterUser: action.bound,
    updateSort: action.bound,
  }
  const state = {
    get isActiveSortDesc() {
      return taskQueueStore.sortDir === TASK_SORT_DIR.DESC ?
        true :
        false
    },
    get isEmpty() {
      return taskQueueStore.data.length === 0
    },
    get isInputDisabled() {
      const { fetchingQuery } = taskQueueStore
      const { fetchingNextPage } = taskQueueStore
      return  fetchingNextPage || fetchingQuery
    },
    get isLoading() {
      const { fetchingQuery } = taskQueueStore
      return fetchingQuery
    },
    get isAllUserFilterSelected() {
      return taskQueueStore.filterUser === ''
    },
    get isUserFilterSelected() {
      return !this.isAllUserFilterSelected
    },
    get visible() {
      const { data: query } = taskQueueStore
      const { isLoading } = taskQueueStore
      if (isLoading) return EMPTY
      else return query.map((t,i) =>  {
        const isAbortable = 
          t.status === TASK_STATE.QUEUED ||
          t.status === TASK_STATE.INITIALIZING ||
          t.status === TASK_STATE.CAPTURING ||
          t.status === TASK_STATE.REQUEUED
        const abort = isAbortable ?
          () => this.confirmAbort(t) :
          null
        const position = i + 1
        return { ...t, abort, position }
      })
      .sort((a, b) => a.position - b.position)
    },
    canSortProp(viewProp) {
      return SORTABLE[viewProp] ? true : false
    },
    isActiveSortProp(viewProp) {
      const { sortProp } = taskQueueStore
      return TASK_SORT_ORDER_VIEW[sortProp] === viewProp
    },
    sortablePropHook(autoHook, viewProp) {
      `${autoHook}.${viewProp.toLowerCase().replace(/\s+/g, "-")}`
    },
    async confirmAbort({ id, name, }) {
      prompt.confirm(`Abort capture task for ${name}`, confirmed => {
        if (confirmed) taskStateStore.abortTask(id)
      })
    },
    async nextPage() {
      const { isLoading } = taskQueueStore
      const { hasLastPage } = taskQueueStore
      if (isLoading || hasLastPage) return

      await taskQueueStore.queryNextPage()

    },
    reset() {
      taskQueueStore.reset()
    },
    resetSearch() {
      this.updateSearch('')
    },
    async updateFilterAll() {
      await taskQueueStore.queryChangedFilter('')
    },
    async updateFilterUser() {
      await taskQueueStore.queryChangedFilter(app.session.username)
    },
    async updateSearch(value) {
      await taskQueueStore.queryChangedSearch(value)
    },
    async updateSort(next) {
      await taskQueueStore.queryChangedSort(TASK_SORT_ORDER[next])
    },
  }

  const model = observable(state, bindings)

  autorun(() => {
    const { err } = taskQueueStore
    if (err) alert.show('error', err.message ?? err)
  })

  autorun(() => {
    const t = taskQueueStore.changed
    if (!t || t.taskStatus !== TASK_STATE.ABORTED) return
    else taskQueueStore.remove(t.taskId)
  })

  return model
}
