import { types } from 'mobx-state-tree'
import Expandable from './Expandable'
import RawAudio from './Media/RawAudio'

// TODO: is this even used any more?
  const AudioRowCurrent = types.model('AudioRowCurrent', {
    active: types.boolean,
    progress: types.number,
  })

// TODO: is this even used any more?
  const AudioRowScriptState = types.model('AudioRowScriptState', {
    expanded: types.boolean,
    showInfo: types.boolean,
    showAlerts: types.boolean,
  })

const AudioRowVar = types.model('AudioRowVar', {
  value: types.string,
  build: types.string,
  style: types.string,
})

const AudioRowTimelineState = types
  .model('AudioRowTimelineState', {
    muted: types.boolean,
    showText: types.boolean,
    locked: types.boolean,
  })

const AudioRowDetailsState = types.model('AudioRowDetailsState', {
  properties: Expandable,
  media: Expandable,
})

const model = types
  .model('AudioRow', {
    Type: types.literal('AudioRow'),
    objectId: types.identifier,
    name: types.string,
    // MediaOptions
    audioMedia: types.maybeNull(types.reference(RawAudio)),
    startTime: types.number,
    variables: types.map(AudioRowVar),
    current: AudioRowCurrent,
    scriptState: AudioRowScriptState,
    timelineState: AudioRowTimelineState,
    detailsState: AudioRowDetailsState,
  })

export default model
