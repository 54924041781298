import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import ContentEditable from '../../components/Editable'
import ScriptMenu from './Menu'
import Row from './Row'
import { contains } from '../../utils'
import { ONDROP_WHITELIST } from '../../constants'
import { updateProp } from '../../actions/edition'
import { duplicate } from '../../actions/edition'
import { toggleProp } from '../../actions/edition'
import { insertSequenceInChapter } from '../../actions/edition'
import { removeSequence } from '../../actions/edition'
import { moveSequence } from '../../actions/edition'
import { insertAudioRow } from '../../actions/edition'
import { moveAudioRow } from '../../actions/edition'
import { parentOf } from '../../actions/edition'

class SequenceComponent extends React.Component {
  editableEnter = (e) => {
    this.hoverOver = true
  }
  onDragStart = (e) => {
    const { state, sequence } = this.props
    const { global: globalState } = state
    e.stopPropagation()
    e.dataTransfer.setData('text/sequence', sequence.objectId)
    e.dataTransfer.setData('text/panel', 'Script')
    globalState.draggingFromScript = true
    globalState.dragging([sequence])
  }
  onDragOver = (e) => {
    e.stopPropagation()
    let isAudioRow = contains(e.dataTransfer.types, 'text/row')
    let isSequence = contains(e.dataTransfer.types, 'text/sequence')
    if (isAudioRow || isSequence) {
      e.preventDefault()
      this.dragOver = true
    }
  }
  onDragLeave = (e) => {
    this.dragOver = false
  }
  onDrop = (e) => {
    const { state, sequence, index } = this.props
    const { global: globalState } = state
    const audioRows = sequence.audioRows
    const chapter = parentOf(sequence)
    e.stopPropagation()
    this.dragOver = false
    let isAudioRow = contains(e.dataTransfer.types, 'text/row')
    let isSequence = contains(e.dataTransfer.types, 'text/sequence')
    const droppedItems = globalState.dropped()
    const droppedFrom = e.dataTransfer.getData('text/panel')
    const isValidDrop = ONDROP_WHITELIST['Script'].includes(droppedFrom)
    if (!isValidDrop) return
    if (isAudioRow) {
      e.preventDefault()
      var r = droppedItems[0]
      moveAudioRow(sequence, r, audioRows.length)
    }
    if (isSequence) {
      var s = droppedItems[0]
      moveSequence(chapter, s, index)
    }
  }
  onClick = (e) => {
    const { state, sequence } = this.props
    const scriptState = state.script
    e.stopPropagation()
    scriptState.activeElement = sequence.objectId
  }
  editableOnKeyDown = (e) => {
    const { state, scriptObjects, sequence } = this.props
    const scriptState = state.script
    const isTab = e.keyCode === 9
    const isEnter = e.keyCode === 13
    const isShift = e.shiftKey
    // Tab
    if (isTab) {
      e.preventDefault()
      e.stopPropagation()
      const index = scriptObjects.indexOf(sequence.objectId)
      const mod = isShift ? -1 : 1
      scriptState.activeElement = scriptObjects[index + mod]
    }
    // Enter
    if (isEnter) {
      e.preventDefault()
      e.stopPropagation()
      if (!isShift) {
        const index = scriptObjects.indexOf(sequence.objectId)
        scriptState.activeElement = scriptObjects[index + 1]
      }
    }
  }
  editableOnBlur = (val) => {
    const { state, sequence } = this.props
    const scriptState = state.script
    this.updateName(val)
    scriptState.activeElement =
      scriptState.activeElement === sequence.objectId ? null : scriptState.activeElement
    this.hoverOver = false
  }
  updateName = (val) => {
    const { sequence } = this.props
    updateProp('name', val, sequence)
  }
  toggleExpand = () => {
    const { sequence } = this.props
    toggleProp('expanded', sequence.scriptState)
  }
  constructor(props) {
    super(props)
    this.dragOver = false
    this.hoverOver = false
  }
  render() {
    const { state, scriptObjects, sequence } = this.props
    const { isParentSelected } = this.props
    const { showOnlySelected } = this.props
    const { structure: structureState, script: scriptState, ui } = state
    const { selections } = structureState

    const isSelected = selections.nodes.includes(sequence) ? true : false
    const isVisible = showOnlySelected ? (isSelected || isParentSelected) ? true : false : true
    const hasFocus = scriptState.activeElement === sequence.objectId
    const expanded = sequence.scriptState.expanded
    const isDragZone = this.dragOver 

    const selectedClassName = isSelected ? ' selected' : ''
    const dragZoneClassName = isDragZone ? ' over' : ''
    const visibleClassName = isVisible ? '' : ' only-selected'
    const focusClassName = hasFocus ? ' focus' : ''

    const className =
      `script-object sequence${visibleClassName}${selectedClassName}${dragZoneClassName}${focusClassName}`

    const clearSelected = () => {
      if (isSelected) {
        selections.removeSelection(sequence)
      }
    }

    const rowEls = expanded
      ? sequence.audioRows.map((row, i) => (
          <Row
            key={row.objectId}
            state={state}
            scriptObjects={scriptObjects}
            row={row}
            index={i}
          />
        ))
      : []

    const nameProps = {
      ref: 'editable',
      disabled: !this.hoverOver,
      html: sequence.name || '',
      placeholder: 'Sequence',
      onSave: this.updateName,
      onKeyDown: this.editableOnKeyDown,
      onBlur: this.editableOnBlur,
      onMouseEnter: this.editableEnter
    }

    const props = {
      className: className.trim(),
      draggable: true,
      onDragStart: this.onDragStart,
      onDragOver: this.onDragOver,
      onDragLeave: this.onDragLeave,
      onDrop: this.onDrop,
      onClick: this.onClick
    }
    const isSelectedCheck = (el) => {
      isSelected ? ui.scrollControl.setScriptPanelSelectedEl(el) : null
    }
    return (
      <div {...props} id={sequence.objectId} ref={isSelectedCheck}>
        <div className="inner">
          <div className="flex">
            <div className="input">
              <i className="sprite widget sequence-script" title="Sequence"></i><ContentEditable {...nameProps} />
            </div>
          </div>
          <div className="actions">
              <button
                type="button"
                className={`chevron ${expanded ? '' : 'collapsed'}`}
                onClick={this.toggleExpand}>
                <i className="sprite script chevron" />
              </button>
            </div>
          <SequenceMenu object={sequence} clearSelected={clearSelected} />
        </div>
        <div className={`row-list`}>{expanded ? rowEls : null}</div>
      </div>
    )
  }
}

const SequenceMenu = observer(({ object: sequence, clearSelected }) => {
  const { scriptState } = sequence
  const chapter = parentOf(sequence)
  const onMouseUp = (e) => {
    e.stopPropagation()
    if (!scriptState.expanded) toggleProp('expanded', scriptState)
    insertAudioRow(sequence)
  }
  const insertBefore = (e) => {
    e.stopPropagation()
    let position = chapter.sequences.indexOf(sequence)
    insertSequenceInChapter(chapter, position)
  }
  const insertAfter = (e) => {
    e.stopPropagation()
    let position = chapter.sequences.indexOf(sequence) + 1
    insertSequenceInChapter(chapter, position)
  }
  const remove = (e) => {
    e.stopPropagation()
    clearSelected()
    removeSequence(chapter, sequence)
  }
  const duplicateSequence = () => duplicate(sequence)
  // TODO: implement Routing here
  // const viewSequence = e => {
  //   e.stopPropagation()
  //   routeToLayout(app, sequence)
  // }
  //const routeTo = 'Structure' === app.route.Type ? <li onMouseUp={viewSequence}>View Sequence</li> : null
  return (
    <ScriptMenu>
      <ol>
        <li onMouseUp={onMouseUp}>Insert Audio Row</li>
        <li className="divider" />
        <li onMouseUp={insertBefore}>Add Sequence Above</li>
        <li onMouseUp={insertAfter}>Add Sequence Below</li>
        <li className="divider" />
        <li onMouseUp={duplicateSequence}>Duplicate Sequence</li>
        <li className="divider" />
        <li onMouseUp={remove}>Remove Sequence</li>
      </ol>
    </ScriptMenu>
  )
})

decorate(SequenceComponent, {
  dragOver: observable,
  hoverOver: observable,
})

const Sequence = observer(SequenceComponent)

export default Sequence
