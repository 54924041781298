import { types } from 'mobx-state-tree'

const model = types
  .model('RawSprite', {
    Type: types.literal('RawSprite'),
    objectId: types.identifier,
    fileName: types.string,
    fileExt: types.string,
    rowCount: types.number,
    columnCount: types.number,
    frameCount: types.number,
    width: types.number,
    height: types.number,
    src: types.string,
    dateTime: types.maybeNull(types.string),
    size: types.maybeNull(types.number),
    userName: types.maybeNull(types.string),
  })

export default model
