export type SemVer = {
  major: number
  minor: number
  working: number
}

export function isSemVer(x: unknown) : x is SemVer {
  if (typeof x !== 'object' || x === null) {
    return false
  }
  const semver = x as SemVer
  const isType =
    Number.isSafeInteger(semver.major) &&
    Number.isSafeInteger(semver.minor) &&
    Number.isSafeInteger(semver.working)

  return isType
}

export function getVersionString({ major, minor, working}: SemVer) : string {
  return `${major}.${minor}.${working}`
}

export function isVersionEqual(a: SemVer, b: SemVer) : boolean {
  return a.major === b.major &&
    a.minor === b.minor &&
    a.working === b.working
}

export function getPreviousVersion(history: Array<SemVer>, current: SemVer) : SemVer {
  const end = history.length - 1
  const index = history.findIndex(v =>
    v.major === current.major &&
    v.minor === current.minor &&
    v.working === current.working)

  if (index === -1 || index === end) {
    return current
  }
  return history[index + 1]
}

export function getNextVersion(history: Array<SemVer>, current: SemVer) : SemVer {
  const index = history.findIndex(v => v.major === current.major && v.minor === current.minor && v.working === current.working)
  if (index === -1 || index === 0) {
    return current
  }
  return history[index - 1]
}
