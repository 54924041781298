import { v4 as UUID } from 'uuid'
import { observable, action } from 'mobx'


export default function GlobalState(app) {
  const alertState = observable({
    items: [], // { id: UUID(), type: 'success' || 'error' || 'warn', message: String }
    reset: function() {
      this.items.clear()
    },
    hide: function(alert) {
      this.items.remove(alert)
    },
    show: function(type, message) {
      if (type === 'success') app.alert.success({ msg: message })
      else if (type === 'error') app.alert.error({ msg: message })
      else if (type === 'warn') app.alert.warn({ msg: message })
      else throw new Error(`Encountered unknown alert type: ${type}`)
    },
  }, {
    items: observable.shallow,
    reset: action.bound,
    show: action.bound,
    hide: action.bound,
  })

  return observable({
    dragItems: [], // observable.shallow?
    draggingFromScript: false,
    loading: true,
    holdLoading: false,
    releaseLoading: false,
    save: {
      status: 'idle', // enum: 'idle', 'success', 'error'
      message: null,
      timestamp: null,
      lastChecked: null,
      timer: null
    },
    alert: alertState,
    // Actions
    reset: function() {
      this.dragItems.clear()
      this.clearSaveState()
      this.alert.reset()
    },
    dragging: function(selections) {
      // Replace dragItems with selections
      this.dragItems.replace(selections)
    },
    dropped: function() {
      // Clone and return the drag items
      const clone = this.dragItems.slice()
      this.dragItems.clear()
      return clone
    },
    setLoading: function(flag) {
      if(flag == true){
        if(this.loading == false){
          this.loading = true;
          this.holdLoading = true
          app.scheduler.wait('loading', 1000, () => {            
            this.holdLoading = false
            if(this.releaseLoading){
              this.loading = false
            }
          })
        }
      }else{ 
        if( this.holdLoading ){
          this.releaseLoading = true
        }else if(!this.holdLoading){
          this.loading = false
          this.shouldEnd = false
        }
      }
    },
    setSaveState: function(status, message = '', timestamp = new Date()) {
      this.save.status = status
      this.save.message = message
      this.save.timestamp = timestamp
      this.save.lastChecked = new Date()
      // Auto-hide/Reset it so it can be used again
      setTimeout(() => {
        this.save.status = 'idle'
      }, 3500) // this time should at least match the animation duration of the CSS animation
    },
    clearSaveState: function() {
      this.save.status = 'idle'
      this.save.message = null
      this.save.timestamp = null
      this.save.lastChecked = null
    },
    startSaveTimer: function() {
      this.save.timer = setInterval(() => {
        this.save.lastChecked = new Date()
      }, 60000)
    },
    stopSaveTimer: function() {
      clearInterval(this.save.timer)
      this.save.timer = null
    },
    hideAlert: function(alert) {
      this.alert.hide(alert)
    },
    showAlert: function(type, message) {
      this.alert.show(type, message)
    },
  }, {
    reset: action.bound,
    dragging: action.bound,
    dropped: action.bound,
    setLoading: action.bound,
    setSaveState: action.bound,
    clearSaveState: action.bound,
    startSaveTimer: action.bound,
    stopSaveTimer: action.bound,
    hideAlert: action.bound,
    showAlert: action.bound,
  })
}
