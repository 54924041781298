import { action } from 'mobx'
import { autorun } from 'mobx'
import { observable } from 'mobx'
import { TASK_STATE } from './task-state.constants'

export function taskStateModel(taskStateStore, alert, session) {
  const bindings = {
    abortCaptureFromTaskQuery: action.bound,
    enqueueCaptureFromEditionVersion: action.bound,
  }

  const state = {
    abortCaptureFromTaskQuery({ id }) {
      taskStateStore.abortTask(id)
    },
    async enqueueCaptureFromEditionVersion({ assetSetPath, editionId, version, status: { checkedOutBy: owner } }, previousVersion) {
      if (owner !== session.username) {
        alert.error({ msg: "Cannot initiate capture for an edition version that isn't checked out to you" })
        return
      }
      const versionStr = `${version.major}.${version.minor}.${version.working}`
      taskStateStore.enqueueTask(editionId, versionStr, assetSetPath, previousVersion)
    },
    get userCaptureQueue() {
      const { pending } = taskStateStore
      return pending.length ?
        pending.map(({ editionName, editionVersion }) => ({ editionName, editionVersion })) :
        []
    },
  }

  const model = observable(state, bindings)

  autorun(() => {
    const { err } = taskStateStore
    if (err) alert.error({ msg: err.message })
  })

  autorun(() => {
    const { changed } = taskStateStore
    if (changed) alertUpdatedTask(changed)
  })

  autorun(() => {
    const { added } = taskStateStore
    if (added) alertNewTask(added)
  })

  return model

  function alertUpdatedTask({ taskStatus, editionName: name, editionVersion: { major, minor, working }, }) {
    const version = `${major}.${minor}.${working}`
    const sticky = true
    let msg = "",
        msgType = ""

    switch(taskStatus) {
      case TASK_STATE.INITIALIZING:
        msgType = 'success'
        msg = `Video capture for edition titled '${name}' at version ${version} is preparing to execute`
        break
      case TASK_STATE.CAPTURING:
        msgType = 'success'
        msg = `Video capture for edition titled '${name}' at version ${version} has begun executing`
        break
      case TASK_STATE.PROCESSING:
        msgType = 'success'
        msg = `Video capture for edition titled '${name}' at version ${version} has begun post-processing; this task cannot be aborted`
        break
      case TASK_STATE.DONE:
        msgType = 'success'
        msg = `Video capture for edition titled '${name}' at version ${version} is complete`
        break
      case TASK_STATE.ERROR:
        msgType = 'error'
        msg = `Video capture for edition titled '${name}' at version ${version} failed. Try again later or open a ticket if failure continues`
        break
      case TASK_STATE.ABORTED:
        msgType = 'warning'
        msg = `Video capture for edition titled '${name}' for version ${version} aborted`
        break
      case TASK_STATE.REQUEUED:
        msgType = 'warning'
        msg = `Video capture for program '${name}' for version ${version} encountered an issue that caused it to be re-enqueued`
        break
      default:
        msgType = 'warning'
        msg = "Encountered invalid state polling edition capture tasks, open a ticket to resolve this issue"
    }
    if (msgType === 'success') alert.success({ msg, sticky })
    else if (msgType === 'warning') alert.warning({ msg, sticky })
    else alert.error({ msg, sticky })
  }

  function alertNewTask({ taskStatus, editionName: name, editionVersion: { major, minor, working }, }) {
    const version = `${major}.${minor}.${working}`
    let msg = "", msgType = ""
    switch(taskStatus) {
      case TASK_STATE.ERROR:
      case TASK_STATE.ABORTED:
      case TASK_STATE.DONE:
        msgType = 'error' 
        msg = `The program capture server encountered an error attempting to record the edition '${name}' at version ${version}, please contact Engineering and open a ticket`
        break
      case TASK_STATE.CAPTURING:
        msgType = 'success'
        msg = `Your capture request for edition '${name} at version ${version} is now recording. You will be alerted when it completes or fails`
        break
      case TASK_STATE.PROCESSING:
        msgType = 'success'
        msg = `Your capture request for edition '${name} at version ${version} is now processing the captured recording. You will be alerted when it completes or fails`
        break
      case TASK_STATE.INITIALIZING:
        msgType = 'success'
        msg = `Your capture request for edition '${name} at version ${version} has commenced. You will be alerted when it completes or fails`
        break
      case TASK_STATE.QUEUED:
        msgType = 'success'
        msg = `Your capture request for edition '${name} at version ${version} has been queued. You will be alerted when it begins`
        break
      default:
        msgType = 'error'
        msg = `The video capture service encountered an invalid state attempting to process your request, please open a ticket to alert engineering`
    }
    if (msgType === 'success') alert.success({ msg })
    else if (msgType === 'warning') alert.warning({ msg })
    else alert.error({ msg })
  }
}

