import { types } from 'mobx-state-tree'

const model = types
  .model('TimelineState', {
    expanded: types.boolean,
    locked: types.boolean,
    hidden: types.boolean,
    scaleLinked: false, // inferred type/default
    properties: types.array(types.string),
  })

export default model
