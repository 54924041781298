export class Activity {
  isIdle = true
  isTracking = false
  mostRecent = 0
  scheduleKey = `idleness-${Math.round(Math.random()) * 1000}`
  scheduler = null
  threshold = 60000

  static fromWindow(window, scheduler, threshold) {
    const tracker = Activity.from(scheduler, threshold)
    window.addEventListener('mousedown', tracker)
    window.addEventListener('keydown', tracker)
    window.addEventListener('mousemove', tracker)
    return tracker
  }
  static from(scheduler, threshold) {
    const tracker = new Activity(scheduler, threshold)
    return tracker
  }

  constructor(scheduler, threshold) {
    this.scheduler = scheduler
    this.threshold = threshold ?? this.threshold
    this.handleEvent = this.handleEvent.bind(this)
    this.onTick = this.onTick.bind(this)
  }
  start() {
    const { scheduler } = this
    const { scheduleKey } = this
    this.mostRecent = scheduler.now()
    scheduler.poll(scheduleKey, 30000, this.onTick)
    this.isIdle = false
    this.isTracking = true
  }
  stop() {
    this.scheduler.cancel(this.scheduleKey)
    this.isIdle = true
    this.isTracking = false
    this.mostRecent = 0
  }
  onTick() {
    const { mostRecent } = this
    const { isTracking } = this
    const { scheduler } = this
    if (!isTracking) return
    const now = scheduler.now()
    const delta = now - mostRecent
    if (delta >= this.threshold) this.isIdle = true
    else return

  }
  handleEvent() {
    const { isIdle } = this
    const { isTracking } = this
    const { scheduler } = this
    if (!isIdle) return
    if (!isTracking) return
    this.isIdle = false
    this.mostRecent = scheduler.now()
  }
}

