import { getSnapshot } from 'mobx-state-tree'
import { walkTree } from '../utils'
import { addKeyframe } from './edition'
import { clone } from './edition'
import { paste as pasteTo } from './edition'
import { parentOf } from './edition'
import { updateResponseMedia } from './edition'
import { writeResponseMedia } from './edition'
import { addVariable } from './edition'
import { addTemplate } from './edition'


const serialize = JSON.stringify
const deserialize = JSON.parse

let pasteCount = 0

const acceptableTypes = [
  'application/x-chapter',
  'application/x-sequence',
  'application/x-decisionnode',
  'application/x-audiorow',
  'application/x-assets',
  'application/x-keyframes'
]

const acceptableCards = ['Viewport', 'Hierarchy', 'Timeline']

const variableGroups = ['runtime', 'patient', 'custom']

const mediaTypes = ['audioMedia', 'videoMedia', 'canvasMedia', 'spriteMedia']

const gatherMediaReferences = (obj) => {
  const refs = []
  function selectMediaFromOptions(opts) {
    for (let i = 0, l = opts.length; i < l; i++) {
      const opt = opts[i]
      if (opt.value) refs.push(opt.value)
    }
  }
  function selectMedia(node) {
    if (node.hasOwnProperty('template')) refs.push(node.template)
    if (node.hasOwnProperty('audioMedia')) refs.push(node.audioMedia)
    if (node.hasOwnProperty('spriteMedia')) refs.push(node.spriteMedia)
    if (node.hasOwnProperty('videoMedia')) refs.push(node.videoMedia)
    if (node.hasOwnProperty('canvasMedia')) refs.push(node.canvasMedia)
    if (node.hasOwnProperty('spriteMediaOptions')) selectMediaFromOptions(node.spriteMediaOptions)
    if (node.hasOwnProperty('canvasMediaOptions')) selectMediaFromOptions(node.canvasMediaOptions)
  }
  function walkSequence(fn, sequence) {
    fn(sequence)
    const stage = sequence.stage
    walkTree(fn, stage, sequence)
    const audioRows = sequence.audioRows
    for (let i = 0, l = audioRows.length; i < l; i++) {
      const row = audioRows[i]
      selectMedia(row)
    }
    const videoRows = sequence.videoRows
    for (let i = 0, l = videoRows.length; i < l; i++) {
      const row = videoRows[i]
      selectMedia(row)
    }
  }
  function walkChapter(fn, chapter) {
    fn(chapter)
    const sequences = chapter.sequences
    for (let i = 0, l = sequences.length; i < l; i++) {
      const sequence = sequences[i]
      walkSequence(fn, sequence)
    }
  }
  if (isAsset(obj)) walkTree(selectMedia, obj)
  if (obj.Type === 'Sequence') walkSequence(selectMedia, obj)
  if (obj.Type === 'Chapter') walkChapter(selectMedia, obj)
  return refs
}

const getTempData = (edition, objs) => {
  const refs = []
  objs.forEach((obj) => {
    Array.prototype.push.apply(refs, gatherMediaReferences(obj))
  })
  return {
    source: edition.objectId,
    variables: getSnapshot(edition.variables),
    audioMedia: getSnapshot(edition.audioMedia).filter((m) => refs.includes(m.objectId)),
    videoMedia: getSnapshot(edition.videoMedia).filter((m) => refs.includes(m.objectId)),
    canvasMedia: getSnapshot(edition.canvasMedia).filter((m) => refs.includes(m.objectId)),
    spriteMedia: getSnapshot(edition.spriteMedia).filter((m) => refs.includes(m.objectId)),
    templateMedia: getSnapshot(edition.templateMedia).filter(t => refs.includes(t.objectId)),
    domain: window.location.hostname
  }
}

const isAsset = (object) => {
  switch (object.Type) {
    case 'Container':
    case 'Sprite':
    case 'Video':
    case 'Text':
    case 'Button':
    case 'Input':
    case 'Checkbox':
    case 'Radio':
    case 'Dropdown':
    case 'Embed':
    case 'Slider':
    case 'List':
    case 'ListItem':
    case 'MenuGroup':
    case 'MenuGroupItem':
    case 'ButtonGroup':
      return true
    default:
      return false
  }
}

const copyObject = (object) => {
  let copy = clone(object)

  // TODO: Do we need detach() here even?
  // Make the copy an orphan
  // if (copy.parent) {
  //   copy.parent = null
  // }
  return copy
}

const copy = (evt, app, route, cut) => {
  const { edition, state } = app
  const {
    ui,
    global: globalState,
    layout: layoutState,
    structure: structureState,
    script: scriptState,
    media: mediaState,
    timeline: timelineState
  } = state
  const { activeCard } = ui

  const clipboardData = evt.clipboardData
  // resets pasteCount
  pasteCount = 0

  if (!activeCard) return
  if (!acceptableCards.includes(activeCard.title)) return

  const isStructure = route.current === 'Structure'
  const isLayout = route.current === 'Layout'

  // Debug variable
  let copied = {
    type: null,
    value: null
  }

  if (isStructure) {
    const selections = structureState.selections.nodes
    evt.preventDefault()
    // Single or Multi object
    if (selections.length) {
      // Get an object map of selection types
      let byType = {}
      for (let i = 0; i < selections.length; i++) {
        let obj = selections[i]
        if (byType.hasOwnProperty(obj.Type)) {
          byType[obj.Type] = [...byType[obj.Type], obj]
        } else {
          byType[obj.Type] = [obj]
        }
      }
      // Can only copy same types
      if (Object.keys(byType).length === 1) {
        let clones = []
        for (let i = 0; i < selections.length; i++) {
          clones.push(copyObject(selections[i]))
        }
        if (clones.length === 0) return
        let payload = {
          objects: clones,
          // Tack on edition variables and media
          temp: getTempData(edition, clones)
        }
        let serialized = serialize(payload)
        clipboardData.setData(`application/x-${clones[0].Type.toLowerCase()}`, serialized)
        clipboardData.setData(`text/plain`, serialized)
        copied.type = `application/x-${clones[0].Type.toLowerCase()}`
        copied.value = serialized
        console.log('Copied!', copied)
        globalState.showAlert('success', `Copied ${clones[0].Type}${clones.length > 1 ? 's' : ''}!`)
        // If cutting delete and clear selections
        if (cut) structureState.selections.deleteSelected()
      } else {
        globalState.showAlert(
          'error',
          `Sorry, you can only ${cut ? 'cut' : 'copy'} content objects of the same type.`
        )
        return
      }
    }
  }

  if (isLayout) {
    const selections = layoutState.selections
    evt.preventDefault()
    // Assets (only if no keyframes are selected)
    if (selections.assets.length && selections.keyframes.length === 0) {
      let clones = []
      for (let i = 0; i < selections.assets.length; i++) {
        const asset = selections.assets[i]
        clones.push(copyObject(asset))
      }
      if (clones.length === 0) return
      let payload = {
        objects: clones,
        // Tack on edition variables and media
        temp: getTempData(edition, clones)
      }
      let serialized = serialize(payload)
      clipboardData.setData(`application/x-assets`, serialized)
      clipboardData.setData(`text/plain`, serialized)
      copied.type = `application/x-assets`
      copied.value = serialized
      console.log('Copied!', copied)
      globalState.showAlert('success', `Copied Asset${clones.length > 1 ? 's' : ''}!`)
    }
    // Keyframes
    if (selections.keyframes.length) {
      let byProp = {
        active: [],
        opacity: [],
        position: [],
        rotation: [],
        scale: [],
        frame: [],
        canvas: []
      }
      for (let i = 0; i < selections.keyframes.length; i++) {
        const keyframe = selections.keyframes[i]
        const asset = parentOf(keyframe) // Keyframe Array is the immediate parent
        if (asset.activeKeyframes.includes(keyframe)) byProp.active.push(keyframe)
        if (asset.opacityKeyframes.includes(keyframe)) byProp.opacity.push(keyframe)
        if (asset.positionKeyframes.includes(keyframe)) byProp.position.push(keyframe)
        if (asset.rotationKeyframes.includes(keyframe)) byProp.rotation.push(keyframe)
        if (asset.scaleKeyframes.includes(keyframe)) byProp.scale.push(keyframe)
        if (asset.frameKeyframes && asset.frameKeyframes.includes(keyframe)) byProp.frame.push(keyframe)
        if (asset.canvasKeyframes && asset.canvasKeyframes.includes(keyframe)) byProp.canvas.push(keyframe)
      }
      let payload = {
        keyframes: byProp,
        // Tack on edition data
        temp: getTempData(edition, [])
      }
      let serialized = serialize(payload)
      clipboardData.setData(`application/x-keyframes`, serialized)
      clipboardData.setData(`text/plain`, serialized)
      copied.type = `application/x-keyframes`
      copied.value = serialized
      console.log('Copied!', copied)
      globalState.showAlert('success', `Copied Keyframe(s)!`)
    }
    // If cutting delete and clear selections
    if (cut) layoutState.selections.deleteSelected()
  }
}

const paste = (evt, app, route) => {
  const { edition, state } = app
  const {
    ui,
    global: globalState,
    layout: layoutState,
    structure: structureState,
    script: scriptState,
    media: mediaState,
    timeline: timelineState
  } = state
  const { activeCard } = ui

  let relinkedFiles = [];
  let linkedFiles = [];  
  const clipboardData = evt.clipboardData

  pasteCount++

  if (!activeCard) return
  if (!acceptableCards.includes(activeCard.title)) return

  const isStructure = route.current === 'Structure'
  const isLayout = route.current === 'Layout'

  let selections
  if (isStructure) selections = structureState.selections.nodes
  if (isLayout) selections = layoutState.selections.assets

  // Check if pasteboard data is coming from AT and match the data type with the item
  let index = null
  for (let i = 0; i < clipboardData.types.length; i++) {
    if (acceptableTypes.includes(clipboardData.types[i])) {
      index = i
      break
    }
  }
  if (index === null) return

  // Deserialize and re-hydrate if pasted data conforms to one of our acceptable types.
  const data = clipboardData.getData(clipboardData.types[index])
  const deserialized = deserialize(data)
  
  const currentDomain = window.location.hostname
  const isSameDomain = deserialized.temp.domain === currentDomain
  const isSameEdition = deserialized.temp.source === edition.objectId

  const pasteOnto = selections.length ? selections[0] : null // First selected item
  const pasteObject = (object) => {
    if (!isSameDomain) {
      globalState.showAlert('warn', `Media was cleared from objects pasted across Environments.`)
    }
    const type = isAsset(object) ? 'Asset' : object.Type

    const objectTypes = {
      // Chapter pasted on Edition
      Chapter: () => {
        for (let i = 0; i < object.decisionNodes.length; i++) {
          object.decisionNodes[i].current.position[0] =
            object.decisionNodes[i].current.position[0] + 250 * pasteCount
          object.decisionNodes[i].current.position[1] =
            object.decisionNodes[i].current.position[1] + 250 * pasteCount
        }
        for (let i = 0; i < object.sequences.length; i++) {
          object.sequences[i].current.position[0] =
            object.sequences[i].current.position[0] + 250 * pasteCount
          object.sequences[i].current.position[1] =
            object.sequences[i].current.position[1] + 250 * pasteCount
        }
        pasteTo(edition, object, isSameDomain)
      },
      // Sequence pasted on Chapter or Sequence
      Sequence: () => {
        object.current.position[0] = object.current.position[0] + 125 * pasteCount
        object.current.position[1] = object.current.position[1] + 125 * pasteCount
        if (pasteOnto && (pasteOnto.Type === 'Chapter' || pasteOnto.Type === 'Sequence')) {
          pasteTo(pasteOnto, object, isSameDomain)
        } else {
          globalState.showAlert(
            'error',
            `Hey you! Paste that sequence on a chapter or another sequence.`
          )
        }
      },
      // DecisionNode pasted on Chapter or Sequence, or Edition
      DecisionNode: () => {
        object.current.position[0] = object.current.position[0] + 50 * pasteCount
        object.current.position[1] = object.current.position[1] + 50 * pasteCount
        if (pasteOnto && (pasteOnto.Type === 'Chapter' || pasteOnto.Type === 'Sequence')) {
          pasteTo(pasteOnto, object, isSameDomain)
        } else {
          pasteTo(edition, object, isSameDomain)
        }
      },
      // TODO: currently, there's no way to do this
      // 'AudioRow': () => {
      //   if (pasteOnto && pasteOnto.Type === 'Sequence') {
      //     pasteOnto.paste(object, isSameDomain)
      //     globalState.showAlert('success', `Audio Row Paste Successful!`)
      //   } else {
      //     globalState.showAlert('error', `Select a Sequence before pasting an Audio Row!`)
      //   }
      // },
      // Asset pasted on Container or Stage
      Asset: () => {
        // In case they want the same behavior on the stage
        /*
        else if (object.position != undefined){
          object.position[0] = object.position[0] + (50 * state.global.pasteCount)
          object.position[1] = object.position[1] + (50 * state.global.pasteCount)
        }
        */
        if (pasteOnto && pasteOnto.Type === 'Container') {
          pasteTo(pasteOnto, object, isSameDomain)
        } else {
          // Add to Stage
          pasteTo(layoutState.player.activeSequence.stage, object, isSameDomain)
        }
      },
      default: () => {
        return
      }
    }
    return (objectTypes[type] || objectTypes['default'])()
  }
  // TODO: keyframe copy/paste needs to be thoroughly vetted again
  const pasteKeyframes = (kfs) => {
    for (let i = 0; i < selections.length; i++) {
      // selections should be selected assets
      const asset = selections[i]
      const frame = layoutState.player.frame
      const properties = Object.keys(kfs) // byProp object
      for (let p = 0; p < properties.length; p++) {
        const property = properties[p]
        const keyframes = kfs[property]
        let delta = 0
        for (let k = 0; k < keyframes.length; k++) {
          const source = keyframes[k]
          const keyframe = clone(source)

          // Sets the first keyframe to whatever frame you're on and offset the rest
          if (k === 0) {
            delta = frame - keyframe.frame
            keyframe.frame = frame
          } else {
            keyframe.frame += delta
          }
          console.log(`Keyframe ${k + 1} on frame ${keyframe.frame}`)
          // TODO: Performance of this could be a little weak. May make more sense to just add the keyframes and then sort them by frame.
          addKeyframe(property, keyframe, asset)
        }
      }
    }
  } 

  // Handle other edition metadata
  // Check if pasted data comes from active edition.
  if (isSameEdition) {
    // clean up temp
    delete deserialized.temp
  } else {
    // Don't bother copying media if we're pasting to a different environment than the source. The media won't be there.
    if (isSameDomain) {
      deserialized.temp.templateMedia.forEach(t => {
        addTemplate(edition, t)
      })

      // Copy over assets (audio, video, canvas, sprite)
      for (let ti = 0; ti < mediaTypes.length; ti++) {
        const mediaType = mediaTypes[ti]
        const tempArray = deserialized.temp[mediaType]
        for (let i = 0; i < tempArray.length; i++) {
          const tempItem = tempArray[i]
          const match = mediaState.isMatch(tempItem);
          const { fileName } = tempItem
            if(match){
              relinkedFiles.push(fileName)
              updateResponseMedia(edition, match, [tempItem])
            }else{
              linkedFiles.push(fileName);
              writeResponseMedia(edition, [tempItem])
            }
          // Make sure it doesn't already exist (The MST object does this on it's own now)
        }
      }
    }
    // Copy over variables (runtime, patient, custom)
    for (let vi = 0; vi < variableGroups.length; vi++) {
      const variableGroup = variableGroups[vi]
      const tempVars = deserialized.temp.variables[variableGroup]
      const tempVarKeys = Object.keys(tempVars)
      const sourceGroup = edition.variables[variableGroup] // Note: this is mobx shallowMap and these are locked into runtime, patient, or custom right now
      for (let i = 0; i < tempVarKeys.length; i++) {
        const tempVarKey = tempVarKeys[i]
        const tempVarValue = tempVars[tempVarKey]
        const sourceGroupKeys = Array.from(sourceGroup.keys())
        // Make sure it doesn't already exist
        if (!sourceGroupKeys.includes(tempVarKey)) {
          addVariable(sourceGroup, tempVarKey, tempVarValue)
        }
      }
    }
    // clean up temp
    delete deserialized.temp
  }

  // Do the actual pasting/inserting
  if (deserialized.hasOwnProperty('objects')) {
    var numObjects = deserialized.objects.length
    if (numObjects) {
      // var pastedType = isAsset(deserialized.objects[0]) ? 'Asset' : deserialized.objects[0].Type // Should only be able to paste objects of the same type
      for (let i = 0; i < numObjects; i++) {
        pasteObject(deserialized.objects[i])
      }
      // globalState.showAlert('success', `Pasted ${numObjects} ${pastedType}${(numObjects > 1) ? 's' : ''}!`)
    }
  }
  // Keyframes are handled differently currently
  if (deserialized.hasOwnProperty('keyframes')) {
    var numKeyframes = deserialized.keyframes.length
    pasteKeyframes(deserialized.keyframes)
    // globalState.showAlert('success', `Pasted ${numKeyframes} Keyframe${(numKeyframes > 1) ? 's' : ''}!`)
  }

  if((relinkedFiles && relinkedFiles.length> 0) || (linkedFiles && linkedFiles.length > 0)){
    mediaState.relinkedFiles = relinkedFiles;
    mediaState.newFiles = linkedFiles;
    mediaState.showUploadModal();

  }
}

export { copy, paste }
