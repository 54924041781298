import { types, getParent, } from 'mobx-state-tree'
import { Vec3 } from 'eplayer-core'
import DecisionNode from './DecisionNode'
import Sequence from './Sequence'

function squared(x) {
  return x * x
}

const ChapterCurrent = types
  .model('ChapterCurrent', {
    emptyPosition: types.array(types.number),
    localMatrix: types.array(types.number),
    worldMatrix: types.array(types.number),
  })
  .views(self => ({
    get chapter() {
      return getParent(self)
    },
    get bounds() {
      const c = self.chapter
      var minX = Number.MAX_SAFE_INTEGER,
        maxX = Number.MIN_SAFE_INTEGER,
        minY = Number.MAX_SAFE_INTEGER,
        maxY = Number.MIN_SAFE_INTEGER,
        numSequences = c.sequences.length,
        numDecisions = c.decisionNodes.length
      for (var i = 0, l = numSequences; i < l; i++) {
        var s = c.sequences[i]
        minX = Math.min(minX, s.current.position[0] - s.current.radius)
        maxX = Math.max(maxX, s.current.position[0] + s.current.radius)
        minY = Math.min(minY, s.current.position[1] - s.current.radius)
        maxY = Math.max(maxY, s.current.position[1] + s.current.radius)
      }
      for (var i = 0, l = numDecisions; i < l; i++) {
        var d = c.decisionNodes[i]
        minX = Math.min(minX, d.current.position[0] - d.current.radius)
        maxX = Math.max(maxX, d.current.position[0] + d.current.radius)
        minY = Math.min(minY, d.current.position[1] - d.current.radius)
        maxY = Math.max(maxY, d.current.position[1] + d.current.radius)
      }
      return {
        ul: Vec3(minX, minY, 0),
        lr: Vec3(maxX, maxY, 0),
      }
    },
    get position() {
      const c = self.chapter
      var bounds = self.bounds,
        minX = bounds.ul[0],
        maxX = bounds.lr[0],
        minY = bounds.ul[1],
        maxY = bounds.lr[1],
        isEmpty = !c.sequences.length && !c.decisionNodes.length
      if (isEmpty) {
        return self.emptyPosition
      } else {
        var dX = maxX - minX
        var dY = maxY - minY
        return Vec3(dX / 2 + minX, dY / 2 + minY, 0)
      }
    },
    get radius() {
      const c = self.chapter
      var circleBuffer = 150 // diameter of sequence nodes
      var bounds = self.bounds,
        minX = bounds.ul[0],
        maxX = bounds.lr[0],
        minY = bounds.ul[1],
        maxY = bounds.lr[1],
        isEmpty = !c.sequences.length && !c.decisionNodes.length
      if (isEmpty) {
        return 200
      } else {
        var rX = (maxX - minX) / 2
        var rY = (maxY - minY) / 2
        return Math.sqrt(squared(rX) + squared(rY)) + circleBuffer
      }
    },
    get width() {
      var c = self.chapter;
      var widthBuffer = 150; // diameter of sequence nodes
      var bounds = self.bounds,
        minX = bounds.ul[0],
        maxX = bounds.lr[0],
        isEmpty = !c.sequences.length && !c.decisionNodes.length;
      if (isEmpty) {
        return 200;
      } else {
        var rX = (maxX - minX);
        return Math.sqrt(squared(rX)) + widthBuffer;
      }
    },
    get height() {
      var c = self.chapter;
      var heightBuffer = 150; // diameter of sequence nodes
      var bounds = self.bounds,
        minY = bounds.ul[1],
        maxY = bounds.lr[1],
        isEmpty = !c.sequences.length && !c.decisionNodes.length;
      if (isEmpty) {
        return 200;
      } else {
        var rY = (maxY - minY);
        return Math.sqrt(squared(rY)) + heightBuffer;
      }
    },
  }))

const ChapterScriptState = types
  .model('ChapterScriptState', {
    expanded: types.boolean,
    showInfo: types.boolean,
    showConnections: types.boolean,
    showAlerts: types.boolean,
  })

const model = types
  .model('Chapter', {
    Type: types.literal('Chapter'),
    objectId: types.identifier,
    sequences: types.late(() => types.array(Sequence)),
    decisionNodes: types.late(() => types.array(DecisionNode)),
    name: types.string,
    title: types.string,
    description: types.string,
    inToC: types.boolean,
    current: ChapterCurrent,
    scriptState: ChapterScriptState,
    shouldCapture: types.boolean,
  })
  .views(self => ({
    get duration() {
      const frameSum =
        this.sequences
        .map(s => Number(s.duration))
        .reduce((a, b) => b ? a + b : a, 0)

      return frameSum / 24
    },
    get durationWithoutRisks() {
      // There is a closed set of possible names for sequences that inform on medical risks to patients in legacy programs
      const notRiskSeq = (sequence) => !/\/ r(end|a|b2|d|e|e2|el|er|f|gh|n|n2|nh|nhb|s|t|v)$/i.test(sequence.name)

      const frameSum =
        this.sequences
        .filter(notRiskSeq)
        .map(s => Number(s.duration))
        .reduce((a, b) => b ? a + b : a, 0)

      return frameSum / 24
    },
  }))

export default model
