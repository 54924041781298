import { types } from 'mobx-state-tree'

export default types.model('RawVideo', {
  Type: types.literal('RawVideo'),
  objectId: types.identifier,
  fileName: types.string,
  fileExt: types.string,
  src: types.string,
  durationMs: types.number,
  dateTime: types.maybeNull(types.string),
  size: types.maybeNull(types.number),
  userName: types.maybeNull(types.string),
})
