import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { EDITIONMANAGER_GRAPHICS } from './constants'
import { Visibility } from '../../visibility'
import './manager-table.scss'

export const CaptureQueueTableHeader = observer(function captureQueueTableHeader({autoHook, children: column, style }) {
  const colAutoHook = `${autoHook}.${column.replace(/\s+/g, "-")}`
  const className = 'manager-table-column'
  const title = `${column} table column`
  const nameClassName = `mananger-table-column__name`

  const vDom =
    <th key={colAutoHook} className={className} title={title} data-qa-hook={colAutoHook} style={style}>
      <strong className={nameClassName}>{column}</strong>
    </th>

  return vDom
})


CaptureQueueTableHeader.propTypes = {}


export const CaptureQueueTableData = observer(function captureQueueTableData({ children, style, testId }) {
  const vDom =
    <td className="manager-table-data" style={style} data-qa-hook={testId}>
      {children}
    </td>
  return vDom
})


export const CaptureQueueTable = observer(class captureQueueTable extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.element).isRequired,
    nextPage: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.element).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pagerRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { nextPage } = this.props
    nextPage();
  }

  componentWillUnmount() {
    const { onUnmount } = this.props
    const { visibility } = this.props
    const pagerEl = this.state.pagerRef.current

    onUnmount()

    if (!visibility) return
    visibility.dispose(pagerEl)
  }

  render() {
    const { pagerRef } = this.state
    const { props } = this
    const { columns } = props
    const { rows } = props

    const tableClassName = "manager-table"
                
    const body = 
                <tbody>
                    {rows}
                </tbody>
      
      const vDom =
        <section className="manager-section manager-row-listing " style={{float:'none'}}  >          
          <table className={tableClassName}>
            <thead>
              <tr>{columns}</tr>
            </thead>
              {body}
          </table>
          <div className="manager-table-pager" ref={pagerRef}></div>
        </section>

      return vDom
  }
})
