import { observable, action, computed, decorate } from 'mobx'
import { updateProp } from '../actions/edition'

function findString(target, str) {
  return target.toUpperCase().indexOf(str.toUpperCase())
}

function highlightString(target, str) {
  // Highlights string regardless of case
  const i = findString(target, str)
  if (i !== -1) {
    return target.replace(
      target.substring(i, i + str.length),
      '<strong>' + target.substring(i, i + str.length) + '</strong>'
    )
  } else {
    return target
  }
}

export default class EditionNavigator {
  constructor(edition, player) {
    this.edition = edition
    this.player = player
    this.searchQuery = ''
  }
  get controlIndex() {
    const { edition } = this
    let ci = []
    if (!edition) return ci
    for (let i = 0, l = edition.chapters.length; i < l; i++) {
      const chapter = edition.chapters[i]
      const sequences = chapter.sequences
      for (let j = 0; j < sequences.length; j++) {
        const s = sequences[j]
        ci.push({
          name: s.name,
          parent: chapter.name,
          objectId: s.objectId
        })
      }
    }
    return ci
  }
  get activeSequence() {
    const { player } = this
    return player.activeSequence
  }
  get activeSequenceIndex() {
    const { controlIndex, activeSequence } = this
    return controlIndex.findIndex((s) => activeSequence ? s.objectId === activeSequence.objectId : false)
  }
  get nextSequence() {
    const { controlIndex, activeSequenceIndex } = this
    let newIndex = activeSequenceIndex + 1 === controlIndex.length ? 0 : activeSequenceIndex + 1
    return controlIndex[newIndex]
  }
  get previousSequence() {
    const { controlIndex, activeSequenceIndex } = this
    let newIndex = activeSequenceIndex === 0 ? controlIndex.length - 1 : activeSequenceIndex - 1
    return controlIndex[newIndex]
  }
  // If not searching
  get editionOutline() {
    const { edition, activeSequence } = this
    let chapterOutline = []
    edition.chapters.forEach((c) => {
      const sequenceList = c.sequences.map((s) => {
        const isActive = activeSequence ? s.objectId === activeSequence.objectId : false
        const sName = s.name ? s.name : 'Untitled Sequence'
        const sDisplayName = isActive ? `<strong>${sName}</strong>` : `${sName}`
        return {
          name: s.name,
          displayName: sDisplayName,
          objectId: s.objectId,
          isActive: isActive,
          setName: (val) => updateProp('name', val, s)
        }
      })
      // Pushes Chapter to the outline, including sequence child objects
      chapterOutline.push({
        name: c.name,
        displayName: c.name ? c.name : 'Untitled Chapter',
        objectId: c.objectId,
        sequences: sequenceList,
        setName: (val) => updateProp('name', val, c)
      })
    })
    return chapterOutline
  }
  // If there is a search term
  get searchResults() {
    const { edition, searchQuery, hasQuery } = this
    let sr = []
    if (hasQuery) {
      // Find Chapters
      edition.chapters.forEach((c) => {
        const cName = c.name
        const cDisplayName = cName ? highlightString(cName, searchQuery) : 'Untitled Chapter'
        let sequenceList = []
        c.sequences.forEach((s) => {
          // Find Sequences
          const sName = s.name
          const sNameIndex = findString(sName, searchQuery)
          if (sNameIndex !== -1) {
            // If found
            const sDisplayName = highlightString(sName, searchQuery)
            sequenceList.push({
              // Outputs sequence values
              name: s.name,
              displayName: sDisplayName,
              objectId: s.objectId,
              setName: (val) => updateProp('name', val, s)
            })
          }
        })
        // Pushes Chapter to the search results index, including sequence child objects
        sr.push({
          name: cName,
          displayName: cDisplayName,
          objectId: c.objectId,
          sequences: sequenceList,
          setName: (val) => updateProp('name', val, c)
        })
      })
    }
    return sr
  }
  get searchResultsCount() {
    const { edition, searchResults } = this
    let numSequences = 0
    // Find Chapters
    searchResults.forEach((c) => {
      numSequences += c.sequences.length
    })
    return numSequences
  }
  get hasQuery() {
    return this.searchQuery.length
  }
  editionIndexSearch(val) {
    // Generates Search Results or no-search display values for use in the Navigator
    this.searchQuery = val
  }
}

decorate(EditionNavigator, {
  searchQuery: observable,
  controlIndex: computed,
  activeSequenceIndex: computed,
  nextSequence: computed,
  previousSequence: computed,
  editionOutline: computed,
  searchResults: computed,
  searchResultsCount: computed,
  hasQuery: computed,
  editionIndexSearch: action.bound,
})
