export class Scheduler {
  constructor(setTimeout, clearTimeout, setInterval, clearInterval, requestAnimationFrame, cancelAnimationFrame, queueMicrotask, performance) {
    this.rAF = requestAnimationFrame
    this.setTimeout = setTimeout
    this.setInterval = setInterval
    this.cancelRAF = cancelAnimationFrame
    this.cancelTimeout = clearTimeout
    this.cancelInterval = clearInterval
    this.queueMicroTask = queueMicrotask
    this.hiRes = performance
    this.rafMap = new Map
    this.timeoutMap = new Map
    this.intervalMap = new Map
  }

  static fromWindow(window) {
    const { setTimeout } = window
    const { clearTimeout } = window
    const { setInterval } = window
    const { clearInterval } = window
    const { requestAnimationFrame } = window
    const { cancelAnimationFrame } = window
    const { queueMicrotask } = window
    const { performance } = window
    return new Scheduler(
      setTimeout.bind(window),
      clearTimeout.bind(window),
      setInterval.bind(window),
      clearInterval.bind(window),
      requestAnimationFrame.bind(window),
      cancelAnimationFrame.bind(window),
      queueMicrotask.bind(window),
      performance)
  }

  cancel(key) {
    if (this.rafMap.has(key)) {
      this.cancelRAF(this.rafMap.get(key))
      this.rafMap.delete(key)
    }
    if (this.timeoutMap.has(key)) {
      this.cancelTimeout(this.timeoutMap.get(key))
      this.timeoutMap.delete(key)
    }
    if (this.intervalMap.has(key)) {
      this.cancelInterval(this.intervalMap.get(key))
      this.intervalMap.delete(key)
    }
  }
  //
  // Because setImmediate and nextTick weren't enough
  nextImmediate(fn, ...args) {
    this.queueMicroTask(function() { args.length ? fn(...args) : fn() })
  }

  nextPaint(key, fn) {
    this.rafMap.set(key, this.rAF(now => { fn(now) }))
  }

  wait(key, ms, fn, ...args) {
    this.timeoutMap.set(key,
      this.setTimeout(function() { args.length ? fn(...args) : fn() }, ms))
  }

  poll(key, ms, fn, ...args) {
    this.intervalMap.set(key,
      this.setInterval(function() { args.length ? fn(...args) : fn() }, ms))
  }

  debounce(key, interval, fn, applyFirstCall = false) {
    const { hiRes } = this
    let start = 0, args = []
    const tick = () => this.nextPaint(key, now => {
      if ((now - start) < interval) {
        return tick()
      }
      start = 0
      fn(...args)
    })
    return function(...nextArgs) {
      args = nextArgs
      if (applyFirstCall) {
        applyFirstCall = false
        fn(...args)
      }
      if (start === 0) {
        start = hiRes.now()
        tick()
      }
    }
  }

  throttle(key, interval, fn) {
    let closed = false
    const { hiRes } = this
    const tick = (start, args) => this.nextPaint(key, now => {
      if ((now - start) < interval) {
        return tick(start, args)
      }
      fn(...args)
      closed = false
    })
    return function(...args) {
      if (closed) return
      else closed = true
      tick(hiRes.now(), args)
    }
  }
  now() {
    return this.hiRes.now()
  }
  has(key) {
    const hasKey =
      this.timeoutMap.has(key) || 
      this.intervalMap.has(key) || 
      this.rafMap.has(key)

    return hasKey
  }
}
