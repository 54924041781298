import { types } from 'mobx-state-tree'
import { Option } from 'eplayer-core'

const model = types
  .model('Expression', {
    Type: types.literal('Expression'),
    objectId: types.identifier,
    // Using a primitive as type is syntactic sugar for introducing a property with a default value.
    // We do this here because legacy textOptions could be created without the 'string' prop.
    string: '',
  })
  .actions(self => {
    self.fn = null
    self.compiled = false
    self.compiledString = ''
    self.compileError = ''
    self.valid = false
    self.validationError = ''
    function afterCreate() {
      Option.Expression.compile(self)
      // TODO: should we just add a reaction to this.string for re-compiling the
      // volatile state instead of having to manually re-compile in certain places?
    }
    return { afterCreate }
  })

export default model
