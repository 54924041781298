import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Modal } from "@common/ui.constants"

const ModalWrapper = (props) => {
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) props.hideModal()
  }

  const onOk = () => {
    props.onOk()
  }

  const okButton = props.showOk ? (
    <button className="modal-button" onClick={onOk} disabled={props.okDisabled}>
      {props.okText}
    </button>
  ) : null

  const modalStyle = {
    width: props.width,
    height: props.height
  }
  const modalSpec = {
    modaltype: props.modaltype
  }
  if (modalSpec.modaltype == 'popup') {
    return (
      <div className="modal-backdrop" onClick={handleBackgroundClick} data-qa-hook={Modal.BACKDROP}>
        <div className="modal-content" style={modalStyle}>
          <header className="modal-header">
            <h1 />
            <button onClick={props.hideModal} data-qa-hook={Modal.CLOSE}>{props.cancelText}</button>
          </header>
          <div className="modal-body popup">{props.children}</div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="modal-backdrop" onClick={handleBackgroundClick}>
        <div className="modal-content" style={modalStyle}>
          <header className="modal-header">
            <h1>{props.title}</h1>
            <button onClick={props.hideModal} data-qa-hook={Modal.HIDE}>{props.cancelText}<span style={ { fontWeight: 700, color: "#b9bbc0"} }>X</span></button>
          </header>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer" style={ {textAlign: props.buttonAlign} }>{okButton}</div>
        </div>
      </div>
    )
  }
}

ModalWrapper.propTypes = {
  // props
  cancelText: PropTypes.string,
  title: PropTypes.string,
  showOk: PropTypes.bool,
  okText: PropTypes.string,
  buttonAlign: PropTypes.string,
  okDisabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]).isRequired,

  // methods
  hideModal: PropTypes.func,
  onOk: PropTypes.func
}

ModalWrapper.defaultProps = {
  cancelText: "Close",
  title: '',
  showOk: true,
  okText: 'OK',
  buttonAlign: "left",
  okDisabled: false,
  width: '75%',
  height: 'auto',
  onOk: () => {}
}

export default observer(ModalWrapper)
