import React from 'react'
import { observable, computed } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import { Asset } from 'eplayer-core'
import Sprite from '../models/Asset/Sprite'
import Video from '../models/Asset/Video'
import Embed from '../models/Asset/Embed'
import { walkTree } from '../utils'
import { ONDROP_WHITELIST } from '../constants'
import NameEditor from '../components/NameEditor'
import { updateProp } from '../actions/edition'
import { toggleHierarchyState } from '../actions/edition'
import { forceTrueHierarchyState } from '../actions/edition'
import { addChildAsset } from '../actions/edition'
import { acceptsDrop } from '../actions/edition'

// const COLLAPSIBLE_TYPES = ['Sprite', 'Container', 'List', 'ListItem']

const LayoutHierarchy = observer(({ sequence, state }) => {
  const stage = sequence.stage
  return (
    <div className="sequence-hierarchy">
      <div className="hierarchy-section-header">
        <strong className="section-title">Stage Assets</strong>
      </div>
      <div className="card-subsection">
        <ol className="tree is-root">
          <Tree key={sequence.objectId} asset={stage} state={state} sequence={sequence} />
        </ol>
      </div>
    </div>
  )
})

class TreeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isEditing = false
    this.isDraggedOver = false
    this.isDraggedBelow = false
  }
  get isStage() {
    return this.props.asset.Type === 'Stage'
  }
  get isLocked() {
    return this.props.asset.timelineState.locked || false
  }
  get isExpanded() {
    return (this.props.asset.children.length && this.props.asset.hierarchyState.expanded) || false
  }
  get isParentExpanded() {
    return this.props.parent ? this.props.parent.hierarchyState.expanded || false : true
  }
  get isNested() {
    return this.props.parent && this.props.parent.Type !== 'Stage'
  }
  get isCollapsible() {
    return this.props.asset.children.length && !this.isStage
  }
  get isSelected() {
    return this.props.state.layout.selections.assets.includes(this.props.asset)
  }
  get isDraggable() {
    return this.isSelected && !this.isLocked
  }
  get isDraggingRoot() {
    return this.props.state.global.dragItems.includes(this.props.asset)
  }
  get isDragging() {
    const { asset } = this.props
    let foundInTree = false
    this.props.state.global.dragItems.forEach((item) => {
      // Other objects are draggable, like Raw Media
      if (item.children) {
        walkTree((n) => {
          if (n === asset) {
            foundInTree = true
          }
        }, item)
      }
    })
    return foundInTree
  }
  onToggleExpand = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { asset } = this.props
    toggleHierarchyState('expanded', asset)
  }
  onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { asset, state } = this.props
    if (this.isLocked) return
    state.layout.selections.clearAllSelections()
    state.layout.selections.setAssetSelection(asset, e.shiftKey)
  }
  onDragStart = (e) => {
    const { asset, state } = this.props
    const { assets: selections } = state.layout.selections
    if (this.isLocked || !this.isSelected) return
    e.dataTransfer.setData('text/panel', 'LayoutHierarchy')
    state.global.dragging(selections)
  }
  onDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.isLocked || this.isDragging) return
    if (acceptsDrop(this.props.asset) === false) return
    this.isDraggedOver = true
  }
  onDragBelow = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.isLocked || this.isDragging) return
    this.isDraggedBelow = true
  }
  onDragLeaveBelow = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.isDraggedBelow = false
  }
  onDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.isDraggedOver = false
    this.isDraggedBelow = false
  }
  onDragEnd = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { state } = this.props
    this.isDraggedOver = false
    state.global.dropped()
  }
  onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { asset, state, parent } = this.props
    const {
      global,
      layout: { player, selections }
    } = state
    if (acceptsDrop(asset) === false) return
    if (this.isLocked || this.isDragging) return
    this.isDraggedOver = false
    const droppedItems = state.global.dropped()
    const droppedFrom = e.dataTransfer.getData('text/panel')
    const isValidDrop = ONDROP_WHITELIST['LayoutHierarchy'].includes(droppedFrom)

    if (!isValidDrop) {
      state.global.showAlert(
        'warn',
        `Cannot drop items from the ${droppedFrom} panel onto the LayoutHierarchy panel.`
      )
      return
    }

    forceTrueHierarchyState('expanded', asset)
    droppedItems.sort((a, b) => (a.zIndex <= b.zIndex ? -1 : 1))
    const isMedia = ['RawHTML', 'RawAudio', 'RawVideo', 'RawSprite'].includes(droppedItems[0].Type)
    const mediaLoadState = isMedia ? droppedItems.filter(m => m.src && player.cache.getAsset(m.src) === undefined) : []
    const shouldLoad = isMedia ? mediaLoadState.length > 0 : false
    for (var i = 0, l = droppedItems.length; i < l; i++) {
      var child = droppedItems[i]
      if (asset === child) continue
      if (isMedia) {
        let loadMedia
        if (shouldLoad) {
          player.cache.addResource(child)
          loadMedia = player.cache.load(child.src)
        } else {
          loadMedia = Promise.resolve()
        }
        return loadMedia.then(() => {
          if ('RawAudio' === child.Type) return
          if ('RawSprite' === child.Type) {
            child = Sprite.create(
              Asset.Sprite.withDefaults({
                name: child.fileName,
                spriteMedia: child,
                width: child.width / child.columnCount,
                height: child.height / child.rowCount
              })
            )
          } else if ('RawVideo' === child.Type) {
            child = Video.create(Asset.Video.withDefaults({ name: child.fileName, videoMedia: child }))
          } else if ('RawHTML' === child.Type) {
            child = Embed.create(Asset.Embed.withDefaults({ name: child.fileName, canvasMedia: child }))
          }

          if (this.isDraggedBelow && !this.isStage) {
            addChildAfter(parent, child, asset)
          } else if (acceptsDrop(asset) === false || parent?.Type === 'ButtonGroup') {
            return
          } else {
            addChildAsset(asset, child)
          }
          state.layout.selections.setAssetSelection(child, false)
          forceTrueHierarchyState('expanded', child)
          this.isDraggedBelow = false
        })
      }

      if (this.isDraggedBelow && !this.isStage) {
        addChildAfter(parent, child, asset)
      } else if (acceptsDrop(asset) === false || parent?.Type === 'ButtonGroup') {
        return 
      } else {
        addChildAsset(asset, child)
      }
      state.layout.selections.setAssetSelection(child, false)
      forceTrueHierarchyState('expanded', child)
    }
    this.isDraggedBelow = false
  }
  onEditClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setEditing(true)
  }
  setEditing = (flag) => {
    this.isEditing = flag
  }
  updateName = (val) => {
    const { asset } = this.props
    updateProp('name', val, asset)
  }
  render() {
    const { asset, state, parent, sequence } = this.props
    const { Type, name, objectId } = asset
    const { assets: selections } = state.layout.selections

    const isLocked = this.isLocked
    const isExpanded = this.isExpanded
    const isParentExpanded = this.isParentExpanded
    const isNested = this.isNested
    const isCollapsible = this.isCollapsible
    const isSelected = this.isSelected
    const isDraggingRoot = this.isDraggingRoot
    const isDragging = this.isDragging
    const isDraggable = this.isDraggable
    const isDraggedBelow = this.isDraggedBelow
    const isDraggedOver = this.isDraggedOver
    const isEditing = this.isEditing

    const nodeType = Type.toLowerCase()
    const icon = Type === 'Video' ? 'film' : nodeType

    const displayName =
      (this.isStage
        ? sequence.name !== ''
          ? 'Stage - ' + sequence.name
          : 'Stage - Untitled Sequence'
        : name) || Type

    let collapsibleButton = null
    if (isCollapsible) {
      collapsibleButton = (
        <div className="collapser">
          <button type="button" className="chevron" onClick={this.onToggleExpand}>
            <i className={`sprite script chevron ${isExpanded ? 'expanded' : 'collapsed'}`} />
          </button>
        </div>
      )
    }

    // Loop through and display children backwards (may just want to use a reverse for loop instead)
    // TODO Refactor into proper view model outside View function, should
    // be a prop
    const children = asset.children
      .slice(0)
      .filter(renderable => "Video" !== renderable.Type)
      .reverse()
      .map((c) => <Tree key={c.objectId} asset={c} state={state} parent={asset} sequence={sequence} />)

    let treeClass = `tree is-nested`
    if (!isParentExpanded) treeClass += ' parent-collapsed'
    if (isCollapsible) treeClass += ' collapsible'
    let itemClass = `tree-item tree-item-${nodeType}`
    itemClass += isExpanded ? ' expanded' : ' collapsed'
    if (isDraggingRoot) itemClass += ' dragging'
    if (isDraggedOver) itemClass += ' over'
    let itemWrapperClass = 'tree-item-inner'
    if (isSelected) itemWrapperClass += ' selected'
    if (isEditing) itemWrapperClass += ' editing'
    // drop below block
    let dropBelow = 'drop-below'
    if (state.global.dragItems.length) dropBelow += ' dragging'
    if (isDraggedBelow) dropBelow += ' over'
    return (
      <li
        className={itemClass}
        draggable={isDraggable}
        onDragStart={this.onDragStart}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDragEnd={this.onDragEnd}
        onDrop={this.onDrop}>
        {collapsibleButton}
        <div className={itemWrapperClass} onClick={this.onClick}>
          <span className={`sprite hierarchy ${isLocked ? 'lock' : icon}`} />
          {isEditing ? (
            <NameEditor
              className="tree-item-input"
              defaultValue={displayName}
              onSave={this.updateName}
              hide={() => this.setEditing(false)}
            />
          ) : (
            <span className="tree-item-title" title={displayName}>
              {displayName}
            </span>
          )}
          {!this.isStage &&
            !isLocked && (
              <div className="actions">
                <button type="button" className="edit" onClick={this.onEditClick}>
                  <i className="icon edit" />
                </button>
              </div>
            )}
        </div>
        {isExpanded && <ol className={treeClass}>{children}</ol>}
        <span
          className={dropBelow}
          onDragOver={this.onDragBelow}
          onDragLeave={this.onDragLeaveBelow}
          onDrop={this.onDrop}
        />
      </li>
    )
  }
}

decorate(TreeComponent, {
  isEditing: observable,
  isDraggedOver: observable,
  isDraggedBelow: observable,
  isStage: computed,
  isLocked: computed,
  isExpanded: computed,
  isParentExpanded: computed,
  isNested: computed,
  isCollapsible: computed,
  isSelected: computed,
  isDraggable: computed,
  isDraggingRoot: computed,
  isDragging: computed,
})

var Tree = observer(TreeComponent)

export default LayoutHierarchy
