import { observable, computed, action, autorun, extendObservable, toJS } from 'mobx'
import { Vec3, Mat4 } from 'eplayer-core'
import { add, subtract, set, negate, copy as copyVec3, transformMat4 } from 'gl-vec3'
import {
  identity,
  invert,
  scale,
  translate,
  translateX,
  translateY,
  rotateX,
  rotateY,
  rotateZ,
  multiply,
  copy as copyMat4
} from 'gl-mat4'
import { STRUCTURE_MOUSE_MODES as MODE, MOUSE_BUTTON } from '../../../constants'
import { structureCollision } from '../../../models/queries'
import { translateNodes } from '../../../actions/edition'
import { addContent } from '../../../actions/edition'

export default function ViewportState(history, camera, selections) {
  return observable({
    camera: camera,
    selections: selections,
    el: null,
    sequenceRef: {},
    size: {
      width: 0,
      height: 0
    },
    position: {
      left: 0,
      top: 0
    },
    mouse: {
      downAt: Vec3(0, 0, 0),
      nowAt: Vec3(0, 0, 0),
      lastAt: Vec3(0, 0, 0),
      get moveDelta() {
        let delta = Vec3(0, 0, 0)
        subtract(delta, this.nowAt, this.lastAt)
        return delta
      },
      get dragDelta() {
        let delta = Vec3(0, 0, 0)
        subtract(delta, this.nowAt, this.downAt)
        return delta
      },
      get worldDownAt() {
        return transformMat4(Vec3(0, 0, 0), this.downAt, camera.inverseViewMatrix)
      },
      get worldNowAt() {
        return transformMat4(Vec3(0, 0, 0), this.nowAt, camera.inverseViewMatrix)
      },
      get worldLastAt() {
        return transformMat4(Vec3(0, 0, 0), this.lastAt, camera.inverseViewMatrix)
      },
      get worldMoveDelta() {
        let delta = Vec3(0, 0, 0)
        subtract(delta, this.worldNowAt, this.worldLastAt)
        return delta
      }
    },
    mode: MODE.INACTIVE,
    // Computeds
    get isDragging() {
      return this.mode === MODE.DRAGGING
    },
    get isActive() {
      return this.mode === MODE.ACTIVE
    },
    get isInactive() {
      return this.mode === MODE.INACTIVE
    },
    get isPanning() {
      return this.mode === MODE.PANNING
    },
    // Actions
    setMode: function(mode) {
      this.mode = mode
    },
    viewportPanning: function() {
      this.mode = MODE.PANNING
    },
    viewportActive: function() {
      this.mode = MODE.ACTIVE
    },
    viewportInactive: function() {
      this.mode = MODE.INACTIVE
    },
    viewportDragging: function() {
      this.mode = MODE.DRAGGING
    },
    viewportRotating: function() {
      this.mode = MODE.ROTATING
    },
    viewportScaling: function() {
      this.mode = MODE.SCALING
    },
    setViewportEl: function(el) {
      this.el = el
    },
    setSequenceRef: function(sequenceId,type, el){
      if(!this.sequenceRef[sequenceId]){
        this.sequenceRef[sequenceId] = {}
      }
        this.sequenceRef[sequenceId][type] = el
    },
    setViewportSize: function(w, h) {
      this.size.width = w
      this.size.height = h
      // sync with camera viewport
      this.camera.resize(w, h)
    },
    setViewportPosition: function(l, t) {
      this.position.left = l
      this.position.top = t
    },
    resize: function() {
      const { el, setViewportSize, setViewportPosition } = this
      // Access necessary DOM props and set state
      if (!el) return
      const w = el.clientWidth
      const h = el.clientHeight
      const bcr = el.getBoundingClientRect()
      setViewportSize(w, h)
      setViewportPosition(bcr.left, bcr.top)
    },
    onDrop: function(evt, edition, nodes) {
      const { el, position, mouse, selections } = this

      if (!el) return

      // dragging doesn't trigger mousemove events
      const x = evt.clientX - position.left
      const y = evt.clientY - position.top
      set(mouse.nowAt, x, y, 0) // set current mouse coords from client

      const target = structureCollision(edition, mouse.worldNowAt)

      // Set Position and add
      addContent(edition, target, nodes, mouse.worldNowAt)
      selections.setSelection(nodes[nodes.length - 1]) // select the last item
    },
    onMouseMove: function(evt, edition) {
      evt.stopPropagation()
      const { position, mouse, selections } = this

      const x = evt.clientX - position.left
      const y = evt.clientY - position.top
      copyVec3(mouse.lastAt, mouse.nowAt) // copy previous mouse coords
      set(mouse.nowAt, x, y, 0) // set current mouse coords from client

      if (this.isDragging) {
        // This is not 'snapshotted' since it'd just generate noise
        history.startGroup()
        translateNodes(mouse.worldMoveDelta, selections.nodes)
      } else if (this.isPanning) {
        camera.pan(evt.nativeEvent.movementX, evt.nativeEvent.movementY)
      }
    },
    onMouseDown: function(evt, edition) {
      const { position, mouse, selections } = this

      set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)
      copyVec3(mouse.downAt, mouse.nowAt)
      
      if (MOUSE_BUTTON.RIGHT === evt.button) {
        this.viewportPanning()
      } else if (MOUSE_BUTTON.LEFT === evt.button) {
        const target = structureCollision(edition, mouse.worldDownAt)
        selections.setSelection(target, evt.shiftKey)
        if (selections.nodes.length) this.setMode(MODE.DRAGGING)
      }
    },
    onMouseUp: function(evt, edition) {
      evt.stopPropagation()
      const { position, mouse, selections } = this

      copyVec3(mouse.lastAt, mouse.nowAt)
      set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)

      if (this.isDragging) {
        set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)
        translateNodes(mouse.worldMoveDelta, selections.nodes)
        // Turn 'snapshotting' back on and set to final destination
        history.stopGroup()
      }

      this.setMode(MODE.ACTIVE)
    },
    onMouseEnter: function(evt) {
      this.setMode(MODE.ACTIVE)
    },
    onMouseLeave: function(evt) {
      this.setMode(MODE.INACTIVE)
    },
    onWheel: function(evt) {
      evt.preventDefault()
      const dX = evt.deltaX
      const dY = evt.deltaY
      this.camera.pan(-dX, -dY)
    },
    zoomIn: function(){
      camera.zoomIn();
      this.moveToSelectionNode()
    },
    zoomOut: function(){
      camera.zoomOut();
      this.moveToSelectionNode()
    },
    moveToSelectionNode: function(){
      if(selections.nodes.length > 0){
        const x =  (selections.nodes[0].current.position[0]/camera.zoom)*-1
        const y =  (selections.nodes[0].current.position[1]/camera.zoom)*-1
         camera.moveTo(x,y)
       }
    }
  }, {
    el: observable.ref,
    sequenceRef: observable,
    setSequenceRef:action.bound,
    setMode: action.bound,
    viewportPanning: action.bound,
    viewportActive: action.bound,
    viewportInactive: action.bound,
    viewportDragging: action.bound,
    viewportRotating: action.bound,
    viewportScaling: action.bound,
    setViewportEl: action.bound,
    setViewportSize: action.bound,
    setViewportPosition: action.bound,
    resize: action.bound,
    onDrop: action.bound,
    onMouseMove: action.bound,
    onMouseDown: action.bound,
    onMouseUp: action.bound,
    onMouseEnter: action.bound,
    onMouseLeave: action.bound,
    onWheel: action.bound,
    zoomIn: action.bound,
    zoomOut:action.bound
  })
}
