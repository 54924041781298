import React from 'react'
import { observer } from 'mobx-react'
import { getDateDay } from '@common/time.utils'
import { getDateTime } from '@common/time.utils'
import { toZonedDateTime } from '@common/time.utils'
import { Captures as TID } from "@common/ui.constants"
import { ManagerAction } from './manager-action'
import { ManagerBreadcrumb } from './manager-breadcrumb'
import { ManagerHeader, HEADER_GRADIENT } from './manager-header'
import { ManagerSearchField } from './manager-search'
import { ManagerTableHeader, ManagerTableData, ManagerTable } from './manager-table'
import { CaptureQueueTable, CaptureQueueTableData, CaptureQueueTableHeader } from './capture-queue-table'


export const RemoteTaskQueue = observer(function remoteTaskQueue({ className: visibleState, onClickBreadcrumb, scheduler, taskSearch, visibility, taskQueue }){

  const isFilterDisabled = taskSearch.isInputDisabled
  const isFilterValAll = taskSearch.isAllUserFilterSelected
  const isFilterValUser = taskSearch.isUserFilterSelected
  const isSearching = taskSearch.isLoading
  const nextPage = taskSearch.nextPage
  const onClickFilterAll = isFilterValAll ? undefined : taskSearch.updateFilterAll
  const onClickFilterUser = isFilterValUser ? undefined : taskSearch.updateFilterUser
  const titleFilterAll = "Show all tasks"
  const titleFilterUser = "Only show tasks you created"

  const rowData = taskSearch.visible;
  const queueRowData = taskQueue.visible;
  const nextQueuePage = taskQueue.nextPage

  const columns  = [
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} key='manager.tasks.column.status' autoHook='manager.tasks.column' style={{ width: 100  }} >
      Status
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} key='manager.tasks.column.author' autoHook='manager.tasks.column' style={{ width: 100 }} >
      Author
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} key='manager.tasks.column.version' autoHook='manager.tasks.column' style={{ width: 50 }} >
      Version
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} key='manager.tasks.column.name' autoHook='manager.tasks.column' style={{ width: 300 }} >
      Name
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} key='manager.tasks.column.requesttime' autoHook='manager.tasks.column' style={{ width: 100 }} >
      Request Time
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} key='manager.tasks.column.completiontime' autoHook='manager.tasks.column' style={{ width: 100 }} >
      Completion Time
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} key='manager.tasks.column.numRetries' autoHook='manager.tasks.column' style={{ width: 100 }} >
      Retries
    </ManagerTableHeader>
  ]

  const rows = rowData.map((data, index) => {
    const created = toZonedDateTime(data.created)
    const settled = toZonedDateTime(data.settled)
    const vDom =
      <tr key={index} className="manager-table-row" data-qa-hook={`item.capture-task.${index}`}>
        <CaptureQueueTableData style={{ width: 100 , paddingLeft: 15 }}>
          {data.status}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 100 }}>
          {data.creator}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 50 }}>
          {`${data.version.major}.${data.version.minor}.${data.version.working}`}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 350 }}>
          {data.name}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 80 }}>
          {getDateDay(created)} - {getDateTime(created)}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 80 }}>
          {getDateDay(settled)} - {getDateTime(settled)}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 80 }}>
          {data.numRetries}
        </CaptureQueueTableData>
      </tr>
    return vDom
  })

  const tableProps = {
    columns,
    displayWithSearch: false,
    isSearching,
    nextPage,
    rows,
    visibility,
    onUnmount: taskSearch.reset,
  }

  const queueColumns  = [
    <CaptureQueueTableHeader  style={{ width: 30, }}  autoHook='manager.tasks.column'   key='queuetable-Position'   >
      Position
    </CaptureQueueTableHeader>,
    <CaptureQueueTableHeader  style={{ width: 50 }}  autoHook='manager.tasks.column'  key='queuetable-Status'   >
      Status
    </CaptureQueueTableHeader>,
    <CaptureQueueTableHeader  style={{ width: 100 }}  autoHook='manager.tasks.column'  key='queuetable-Author'   >
      Author
    </CaptureQueueTableHeader>,
    <CaptureQueueTableHeader  style={{ width: 50 }}  autoHook='manager.tasks.column'  key='queuetable-Version'   >
      Version
    </CaptureQueueTableHeader>,
    <CaptureQueueTableHeader  style={{ width: 300, textOverflow: 'ellipsis' }}  autoHook='manager.tasks.column'  key='queuetable-Name'   >
      Name
    </CaptureQueueTableHeader>,
    <CaptureQueueTableHeader  style={{ width: 80 }}  autoHook='manager.tasks.column'  key='queuetable-RequestTime'   >
      Request Time
    </CaptureQueueTableHeader>,
    <CaptureQueueTableHeader  style={{ width: 80 }}  autoHook='manager.tasks.column'  key='queuetable-Abort'   >
      Abort
    </CaptureQueueTableHeader>,
  ]

   const queueRows = queueRowData.map((data, index) => {
    const created = toZonedDateTime(data.created)
    const settled = toZonedDateTime(data.settled)
    const confirmAbort = data.abort
    const abortEntry = confirmAbort ?
       <button onClick={confirmAbort} className="manager-table-data__abort" data-qa-hook="capture-task.button.abort">ABORT</button> :
       null


    const vDom =
      <tr key={data.id} className="manager-table-row" data-qa-hook={`item.capture-task.${index}`} >
        <CaptureQueueTableData style={{ paddingLeft: 20  }}>
          <strong>{data.position}</strong>
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 50 }} >
          {data.status}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 100 }}>
          {data.creator}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 50 }} >
          {`${data.version.major}.${data.version.minor}.${data.version.working}`}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 300 }} >
          {data.programName}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 100 }}>
          {getDateDay(created)} - {getDateTime(created)}
        </CaptureQueueTableData>
        <CaptureQueueTableData style={{ width: 100 }}>
          {abortEntry}
        </CaptureQueueTableData>
      </tr>
    return vDom
  })

  const queueProps = {
    columns: queueColumns,
    nextPage: nextQueuePage,
    rows: queueRows,
    visibility,
    onUnmount: taskQueue.reset,
  }

  const vDom =
    <editiondetails-wrapper class={visibleState}>
      <editiondetails-container>
        <ManagerBreadcrumb onClick={onClickBreadcrumb} testId={TID.BACK}/>

        <ManagerHeader title="Video Captures - Current Active Queue" gradient={HEADER_GRADIENT.LIGHTBLUE_BLUE}></ManagerHeader>

        <CaptureQueueTable {...queueProps} ></CaptureQueueTable>

        <ManagerHeader title="Video Capture History" gradient={HEADER_GRADIENT.LIGHTBLUE_BLUE} style={{paddingTop:100}}></ManagerHeader>

        <ManagerSearchField scheduler={scheduler} searchValue={taskSearch.updateSearch} onResetSearch={taskSearch.resetSearch} onUpdateSearch={taskSearch.updateSearch}>
          <ManagerAction selected={isFilterValAll} onClick={onClickFilterAll} disabled={isFilterDisabled} title={titleFilterAll}>
            All Captures
          </ManagerAction>
          <ManagerAction selected={isFilterValUser} onClick={onClickFilterUser} disabled={isFilterDisabled} title={titleFilterUser}>
            Your Captures
          </ManagerAction>
        </ManagerSearchField>

        <ManagerTable {...tableProps}></ManagerTable>

      </editiondetails-container>
    </editiondetails-wrapper>

  return vDom
})

