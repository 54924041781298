import React from 'react'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'
import { getTimeStamp, noFirstSpace } from '../../utils'
import NewEditionModal from '../../components/modals/NewEdition'
import WarningModal from '../../components/modals/Warning'
import debounce from 'debounce'
import { version as jellyfishVersion } from '../../../../package.json'
import { Manager } from "@common/ui.constants"

// CheckedOutListItem const
// Generates a row in the list of editions currently checked out to the user from observables
const CheckedOutListItem = observer(({ ed, index, onClick, testId }) => {
  const timestamp = getTimeStamp(ed.lastUpdated)
  const date = timestamp[0]
  const time = timestamp[1]
  const localsave = ed.versionedToLocal ? true : false
  return (
    <li data-qa-hook={testId}>
      <span className="edition-haslocal" title="Has a personal version save" data-qa-hook={Manager.CHECKOUT_LATEST}>
        {localsave ? '*' : ''}
      </span>
      <span className="edition-name" title={ed.name} onClick={onClick} data-qa-hook={Manager.CHECKOUT_NAME}>
        {ed.name}
      </span>
      <span className="edition-version" title={time} data-qa-hook={Manager.CHECKOUT_DATE}>
        {date}
      </span>
    </li>
  )
})

// EditionListItem const
// Generates a row in the list of locally saved editions from observables
const EditionListItem = observer(({ ed, onClick, discardEdition, testId }) => {
  const displayName = ed.name || 'Untitled Edition'
  const timestamp = getTimeStamp(ed.lastUpdated)
  const date = timestamp[0]
  const time = timestamp[1]
  return (
    <li title={displayName} className={`single-edition`} data-qa-hook={Manager.DRAFT}>
      <span className="edition-name" title={displayName} onClick={onClick} data-qa-hook={Manager.DRAFT_NAME}>
        {displayName}
      </span>
      <span onClick={discardEdition} className="edition-discard" title="Discard Edition" data-qa-hook={Manager.DRAFT_REMOVE}>
        <i className="icon remove" />
      </span>
      <span className="edition-lastsave" title={time} data-qa-hook={Manager.DRAFT_DATE}>
        {date}
      </span>
    </li>
  )
})

// EditionSearchListItem
// Generates a row in the list of search results on the Edition manager homepage from observables
const EditionSearchListItem = observer(({ ed, onClick, testId }) => {
  const timestamp = getTimeStamp(ed.lastUpdated)
  const date = timestamp[0]
  const time = timestamp[1]
  const isCheckedOut = ed.status.status === 'CHECKED_OUT'
  const isCheckedIn = ed.status.status === 'CHECKED_IN'
  const isArchived = ed.status.status === 'ARCHIVED'
  let editionStatusString
  if (isCheckedIn) editionStatusString = 'Open'
  if (isArchived) editionStatusString = 'Archived'
  if (isCheckedOut) editionStatusString = 'Checked Out'
  let editionStatusClass
  if (isCheckedIn) editionStatusClass = ''
  if (isArchived) editionStatusClass = 'archived'
  if (isCheckedOut) editionStatusClass = 'checked-out'
  let editionCheckedOutBy
  if (isCheckedOut) editionCheckedOutBy = `by ${ed.status.checkedOutBy}`
  return (
    <tr onClick={onClick} data-qa-hook={testId}>
      <td title={ed.name} data-qa-hook={Manager.SEARCH_RESULT_NAME}>
        <span className="edition-title">{ed.name}</span>
      </td>
      <td className={editionStatusClass} title={editionCheckedOutBy} data-qa-hook={Manager.SEARCH_RESULT_STATUS}>
        {editionStatusString}
      </td>
      <td title={`at ${time}`} data-qa-hook={Manager.SEARCH_SORT_UPDATED}>{date}</td>
      <td title="" data-qa-hook={Manager.SEARCH_RESULT_UPDATED_BY}>{ed.lastUpdatedBy}</td>
      <td title={ed.version.comment} data-qa-hook={Manager.SEARCH_RESULT_COMMENT}>{ed.version.comment}</td>
    </tr>
  )
})

// --------------------------
// Begin Homepage Propgration
// --------------------------
class HomepageComponent extends React.Component {
 
  openNewEditionModal = () => {
    this.showNewEditionModal = true
  }
  closeNewEditionModal = () => {
    this.showNewEditionModal = false
  }
  openDiscardModal = (edition) => {
    this.editionToDiscard = edition
    this.showDiscardModal = true
  }
  closeDiscardModal = () => {
    this.showDiscardModal = false
  }
  openConversion = () => {
    const { route } = this.props.app
    route.goTo('Conversion')
  }

  openScriptExport = () => {
    const { route } = this.props.app
    route.goTo('ScriptExport')
  }
  constructor(props) {
    super(props)
    this.showNewEditionModal = false
    this.showDiscardModal = false
    this.editionToDiscard = null
  }

  render() {
    const { className } = this.props
    const { TID } = this.props
    const { app } = this.props
    const { session } = this.props

    const editionManager = app.state.editionmanager
    const fileList = editionManager.personalFileList

    // UI-related toggles
    const isRecentExpanded = editionManager.recentExpanded
    const isShownClass = className
    const isSearching = editionManager.search.isSearching
    const isSearchLoaded = editionManager.search.isLoaded
 

    // Behavior controls
    const searchOutput = (e) => {
      editionManager.search.setSearch(noFirstSpace(e.target.value))
      // Sets half second delay on search when typing to avoid API calls every keypress
      const searchCountdown = debounce(function(searchValue) {
        if (searchValue === editionManager.search.value) editionManager.getEditionSearch()
      }, 500)
      searchCountdown(e.target.value)
    }
    const moreResults = () => {
      editionManager.getMoreSearchResults()
    }
    const sortByName = () => {
      editionManager.search.toggleSearchFilter('name')
      editionManager.getEditionSearch()
    }
    const sortByStatus = () => {
      editionManager.search.toggleSearchFilter('status')
      editionManager.getEditionSearch()
    }
    const sortByUpdated = () => {
      editionManager.search.toggleSearchFilter('lastUpdated')
      editionManager.getEditionSearch()
    }
    const clearSearchForm = () => {
      editionManager.search.resetSearch()
      editionManager.getEditionSearch()
    }
    const showLegacyLoader = (e) => {
      e.stopPropagation()
      editionManager.showLegacyLoader()
    }

    // Generates the list of items checked out to the user
    const checkedOutList = editionManager.currentlyCheckedOut.map((ed, index) => {
      return (
        <CheckedOutListItem
          key={ed.editionId}
          testId={Manager.CHECKOUT}
          index={index}
          ed={ed}
          onClick={() => editionManager.showEditionDetails(ed.editionId)}
        />
      )
    })

    // Generates the list of locally saved editions for the current user
    const editionList = fileList.filteredEditions.map((ed) => {
      return (
        <EditionListItem
          key={ed.objectId}
          testId={Manager.DRAFT}
          ed={ed}
          onClick={() => app.loadEdition(ed.objectId)}
          discardEdition={() => this.openDiscardModal(ed)}
        />
      )
    })

    // Generates the list of matching results for a given search
    const editionSearchList = editionManager.search.results.map((ed) => {
      return (
        <EditionSearchListItem
          key={ed.editionId}
          testId={Manager.SEARCH_RESULT}
          ed={ed}
          onClick={() => editionManager.showEditionDetails(ed.editionId)}
        />
      )
    })

    const getNewEditionModal = () => {
      // New Version Modal
      if (this.showNewEditionModal) {
        return <NewEditionModal cancel={this.closeNewEditionModal} app={app} />
      } else {
        return null
      }
    }
    const getDiscardEditionModal = () => {
      const discardThis = () => {
        editionManager.discardEdition(this.editionToDiscard)
        this.closeDiscardModal()
      }

      if (this.showDiscardModal) {
        return (
          <WarningModal
            cancel={this.closeDiscardModal}
            continue={discardThis}
            warning={`This will discard your personal edition - ${
              this.editionToDiscard.name
            }. This cannot be undone.`}
          />
        )
      } else {
        return null
      }
    }
    return (
      <homepage-wrapper class={isShownClass}>
        <homepage-container id="homepage-container" data-qa-hook={Manager.PAGE}>
          {getNewEditionModal()}
          {getDiscardEditionModal()}
          <header>
            <div className="logo">
              <span className="version" data-qa-hook={Manager.VERSION}>{jellyfishVersion}</span>
            </div>
            <div className="user-control">
              <span className="link-container" onClick={this.openScriptExport}>
                <i className="bts bt-download" />
                <span className="link-name" data-qa-hook={Manager.TRANSCRIPTS}>Script Export </span>
                <span className="link-separator">|</span>
              </span>
              <span
                className="link-container"
                onClick={() => {
                  editionManager.showTaskQueue()
                }}>
                <i className="bts bt-camera" />
                <span className="link-name" data-qa-hook={Manager.CAPTURES}>Video Captures</span>
                <span className="link-separator">|</span>
              </span>
              <span
                className="link-container"
                onClick={() => {
                  editionManager.showAdvisorDetails()
                }}>
                <i className="bts bt-link" />
                <span className="link-name" data-qa-hook={Manager.LINKS}>Advisor Links</span>
                <span className="link-separator">|</span>
              </span>
              Hello, <strong className="username" data-qa-hook={Manager.USER}>{session.username}!</strong>
            </div>
          </header>
          <section
            className={isRecentExpanded ? `recent-editions expanded` : `recent-editions collapsed`}
            id="recent-editions"
            >
            <div className="recent-edition-toggle" onClick={editionManager.toggleRecentExpand} data-qa-hook={Manager.RECENT}>
              <span>Your Recent Editions</span>
              <i className="chevron-small" />
            </div>
            <div className="recent-edition-wrapper">
              <div className="recent-checked-out">
                <h5>Checked Out to You</h5>
                <ul>{checkedOutList}</ul>
              </div>
              <div className="recent-local-saves">
                <h5>
                  <button className="view-all" onClick={showLegacyLoader} data-qa-hook={Manager.DRAFTS}>
                    View All Personal Editions
                  </button>
                  Your Personal Editions <span>(Never Versioned)</span>
                </h5>
                <ul>{editionList}</ul>
              </div>
            </div>
          </section>
          <section className="edition-search">
            <div className="search-bar">
              <input
                className="search-bar-input"
                id="search-bar-input"
                type="text"
                placeholder="Search..."
                data-qa-hook={Manager.SEARCH}
                value={editionManager.search.value}
                onChange={searchOutput}
              />
              <button className="search-clear" onClick={clearSearchForm} data-qa-hook={Manager.CLEAR}/>
            </div>
            <div className="new-edition-options" data-qa-hook="menu.new-edition">
              <button className="selector">
                Create New Edition <i className="chevron-xtrasmall" data-qa-hook="button.createNewEdition"/>
              </button>
              <button className="create-new-edition" onClick={this.openNewEditionModal} data-qa-hook="menuoption.startNew">
                Start New
              </button>
              <button className="create-new-edition" onClick={this.openConversion} data-qa-hook="button.convertScript">
                Convert Script
               </button>
             </div>
          </section>
          <section
            className={isSearching ? `search-results-loading` : `search-results-loading hide`}>
          </section>
          <section className={isSearchLoaded ? `search-results` : `search-results hide`}>
            <table className="editionsearch">
              <thead>
                <tr>
                  <th
                    className={editionManager.search.filtername ? 'filter active' : 'filter'}
                    onClick={sortByName}
                    data-qa-hook={Manager.SEARCH_SORT_NAME}>
                    <strong>Edition Name</strong>
                    <i
                      className={
                        editionManager.search.filterdir
                          ? 'chevron-xtrasmall desc'
                          : 'chevron-xtrasmall asc'
                      }
                    />
                  </th>
                  <th
                    className={editionManager.search.filterstatus ? 'filter active' : 'filter'}
                    onClick={sortByStatus}
                    data-qa-hook={Manager.SEARCH_SORT_STATUS}>
                    <strong>Status</strong>
                    <i
                      className={
                        editionManager.search.filterdir
                          ? 'chevron-xtrasmall desc'
                          : 'chevron-xtrasmall asc'
                      }
                    />
                  </th>
                  <th
                    className={editionManager.search.filterlastupdated ? 'filter active' : 'filter'}
                    onClick={sortByUpdated}
                    data-qa-hook={Manager.SEARCH_SORT_UPDATED}>
                    <strong>Updated</strong>
                    <i
                      className={
                        editionManager.search.filterdir
                          ? 'chevron-xtrasmall desc'
                          : 'chevron-xtrasmall asc'
                      }
                    />
                  </th>
                  <th>
                    <strong>Last Author</strong>
                  </th>
                  <th>
                    <strong>Version Notes</strong>
                  </th>
                </tr>
              </thead>
            </table>
            <div className="search-results-body overflow">
              <table className="search-results-table editionsearch">
                <tbody>{editionSearchList}</tbody>
              </table>
              <div
                className="search-load-more"
                ref={editionManager.setSearchMoreEl}
                id="search-load-more"
                onClick={moreResults}>
                <em>{editionManager.search.recordsdisplay}</em>
              </div>
            </div>
          </section>
        </homepage-container>
      </homepage-wrapper>
    )
  }
}

decorate(HomepageComponent, {
  showNewEditionModal: observable,
  showDiscardModal: observable,
  editionToDiscard: observable,
})
const Homepage = observer(HomepageComponent)

export default Homepage
