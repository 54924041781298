import { types } from 'mobx-state-tree'
import DecisionNode from './DecisionNode'
import Action from '../Action'
import AudioRow from '../AudioRow'
import VideoRow from '../VideoRow'
import Stage from '../Asset/Stage'
import Statement from '../Statement'
import { strCompare } from '../utils'

const SequenceCurrent = types.model('SequenceCurrent', {
  position: types.array(types.number),
  radius: types.number,
  localMatrix: types.array(types.number),
  worldMatrix: types.array(types.number),
})

const SequenceScriptState = types
  .model('SequenceScriptState', {
    expanded: types.boolean,
    showInfo: types.boolean,
    showConnections: types.boolean,
    showAlerts: types.boolean,
  })

const model = types
  .model('Sequence', {
    Type: types.literal('Sequence'),
    objectId: types.identifier,
    name: types.string,
    description: types.string,
    next: types.late(() =>
      types.maybeNull(types.reference(types.union(DecisionNode, model)))
    ),
    duration: types.union(types.number, types.string),
    actions: types.array(Action),
    audioRows: types.array(AudioRow),
    videoRows: types.array(VideoRow),
    stage: Stage,
    onEnter: types.maybeNull(Statement),
    onExit: types.maybeNull(Statement),
    current: SequenceCurrent,
    scriptState: SequenceScriptState,
    isMenu: types.boolean,
    isProgramCompletion: types.boolean,
    shouldCapture: types.boolean,
  })
  .views(self => ({
    get sortedActions() {
      return self.actions.sort(function(a, b) {
        return a.frame > b.frame || strCompare(a.name, b.name)
      })
    },
  }))

export default model
