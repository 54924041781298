import { action } from 'mobx'
import { autorun } from 'mobx'
import { observable } from 'mobx'
import { TASK_STATE } from './task-state.constants'
import { TASK_POLL_INTERVAL } from './task-state.constants'
import Edition from './models/BaseContent/Edition'

export function taskStateStore(schedule, storage, queueCapture, queryCapture, abortCapture, readEditionVersion, captionsFromEdition, captionsFromChapter) {
  const TASK_STORAGE_KEY = 'pendingCaptures'
  const TASK_POLL_KEY = 'pollTask'

  const bindings = {
    err: observable.ref,
    added: observable.ref,
    changed: observable.ref,
    reset: action.bound,
    abortTask: action.bound,
    enqueueTask: action.bound,
    queryTaskState: action.bound,
  }

  const init = storage.has(TASK_STORAGE_KEY) ?
    storage.get(TASK_STORAGE_KEY, 'array') :
    []

  const state = {
    err: null,
    added: null,
    changed: null,
    pending: init,
    async abortTask(taskId) {
      const { pending } = this
      try {
        await abortCapture(taskId)
        const aborted = pending.find(taskState => taskState.taskId === taskId)
        aborted.taskStatus = TASK_STATE.ABORTED
        pending.remove(aborted)
        this.changed = aborted
      } catch (err) {
        this.err = err
      }
    }, 
    async enqueueTask(editionId, editionVersion, editionAssetPath, previousVersion) {
      const { pending } = this
      try {
        const latest = false
        const meta = await readEditionVersion(editionId, editionVersion, latest)
        const edition = Edition.create(meta.version.content)
        const editionTTML = captionsFromEdition(edition, 'en')
        const chapterTTMLs = edition.chapters.slice(1)
          .map(c => captionsFromChapter(c, 'en'))
        const captions = [...chapterTTMLs, editionTTML ]
        const taskState = await queueCapture(editionId, editionVersion, editionAssetPath, previousVersion, captions)
        const { taskStatus } = taskState
        switch (taskStatus) {
          case TASK_STATE.QUEUED:
          case TASK_STATE.INITIALIZING:
          case TASK_STATE.CAPTURING:
          case TASK_STATE.PROCESSING:
            pending.push(taskState)
            this.added = taskState
            return
          default:
            this.err = new Error('Encountered invalid state while attempting to queue edition version capture')
        }
      } catch (err) {
        this.err = new Error(`Encountered unexpected error attempting to enqueue capture task:\n${err.message}\n File a ticket if this continues`)
      }
    },
    async queryTaskState() {
      const { pending } = this
      if (!pending.length) return
      try {
        const [taskState,] = pending
        const { taskId } = taskState
        const next = await queryCapture(taskId)
        switch (next.taskStatus) {
          case TASK_STATE.DONE:
          case TASK_STATE.ERROR:
          case TASK_STATE.ABORTED:
            taskState.taskStatus = next.taskStatus
            pending.remove(taskState)
            break
          case TASK_STATE.INITIALIZING:
          case TASK_STATE.CAPTURING:
          case TASK_STATE.PROCESSING:
            taskState.taskStatus = next.taskStatus
            break
          case TASK_STATE.REQUEUED:
            if (taskState.taskStatus === TASK_STATE.REQUEUED) return
            else taskState.taskStatus = next.taskStatus
            break
          case TASK_STATE.QUEUED:
            return
          default:
            this.err = new Error('Encountered invalid task state while polling for current state of edition capture - file an issue to address')
            return
        }
        this.changed = taskState
      } catch (err) {
        this.err = err
      }
    },
    reset() {
      this.err = null
      this.added = null
      this.changed = null
      this.pending.clear()
    },
  }

  const store = observable(state, bindings)

  schedule.poll(TASK_POLL_KEY, TASK_POLL_INTERVAL, () => store.queryTaskState())

  autorun(() => {
    const { pending } = store
    const { length } = pending
    if (length) storage.set(TASK_STORAGE_KEY, 'array', pending.toJSON())
    else storage.set(TASK_STORAGE_KEY, 'array', [])
  })

  return store
}

