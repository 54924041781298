import { types } from 'mobx-state-tree'
import Vec3 from './Vec3'

const model = types
  .model('Keyframe', {
    Type: types.literal('Keyframe'),
    objectId: types.identifier,
    fn: types.string,
    frame: types.number,
    value: types.union(types.number, types.boolean, Vec3),
  })

export default model
