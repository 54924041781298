import React from 'react'
import './manager-breadcrumb.scss'

export function ManagerBreadcrumb({ onClick, testId }) {
  const vDom =
    <div className="manager-breadcrumb" >
      <span className="home" data-qa-hook={testId} onClick={onClick}>
        ❮ Home
      </span>
    </div>
  return vDom
}
