import { types } from 'mobx-state-tree'
import Statement from './Statement'

const model = types
  .model('Action', {
    Type: types.literal('Action'),
    objectId: types.identifier,
    name: types.string,
    frame: types.number,
    code: Statement,
  })

export default model
