import React from 'react'
import PropTypes from 'prop-types'
import { observable, observe, action } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import { utils } from 'eplayer-core'
import Input from '../components/Input'
import ModalWrapper from "../containers/ModalWrapper"
import { Pending } from "../components/Pending"
import { updateProp } from '../actions/edition'
import { removeMedia } from '../actions/edition'

const TYPE_TO_SORTED_EDITION_MEDIA = {
  RawSprite: 'sortedSprites',
  RawAudio: 'sortedAudio',
  RawVideo: 'sortedVideo',
  RawHTML: 'sortedCanvases'
}

function initializeFileInput(multiple, accept) {
  const input = document.createElement('input')
  input.type = 'file'
  input.multiple = multiple ? true : undefined // boolean attribute
  input.accept = accept || '.jpg,.png,.svg,.mp3,.mp4,.js'
  return input
}

function getFileExtension(fileName) {
  return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || ''
}

const MediaPanel = observer(class MediaPanel extends React.Component {
  deselect = (e) => {
    e.stopPropagation()
    const { mediaState } = this.props
    mediaState.deselectAllMedia()
  }
  mediaAdder = (files) => {
    const { mediaState } = this.props
    mediaState.add(...Array.from(files))
  }
  mediaUpdater = (media, files) => {
    const { mediaState } = this.props
    mediaState.update(media, files[0])
  }
  mediaRemover = (media) => {
    const { mediaState, edition } = this.props
    mediaState.deselectMedia(media)
    removeMedia(edition, media)
  }
  mediaSelector = (item, e) => {
    const { mediaState, edition } = this.props
    const { selections, selectMedia, deselectMedia, batchSelectMedia } = mediaState
    const isSelected = selections.includes(item)
    const currentMediaId = item.objectId
    const currentMediaType = item.Type
    // TODO: this could probably still be cleaned up some...
    if (e.altKey) {
      if (isSelected) deselectMedia(item)
      else batchSelectMedia(item)
    } else if (e.shiftKey) {
      if (selections.length > 0) {
        var prevMediaId = 0
        // Filter by selected type
        var selectedMedia = selections.filter(function(x) {
          return x.Type === currentMediaType
        })
        if (selectedMedia.length > 0) {
          // First item if filtering by same type
          prevMediaId = selectedMedia[0].objectId
        }
        // Flatten by type and find last position (i.e. in case item 1 and 3 is selected)
        if (
          selections
            .map(function(x) {
              return x.Type
            })
            .lastIndexOf(currentMediaType)
        ) {
          // Last item of same type in selections
          prevMediaId = selections[selections.length - 1].objectId
        }
        // Grab the right type of media to search
        var editionMedia = edition[TYPE_TO_SORTED_EDITION_MEDIA[currentMediaType]]
        // Find indices of the bounds of selection by flattening by ID
        var prevMediaPos = editionMedia
          .map(function(x) {
            return x.objectId
          })
          .indexOf(prevMediaId)
        var currentMediaPos = editionMedia
          .map(function(x) {
            return x.objectId
          })
          .indexOf(currentMediaId)
        if (currentMediaPos > prevMediaPos) {
          for (var i = prevMediaPos + 1; i <= currentMediaPos; i++) {
            if (!selections.find((obj) => obj['objectId'] === editionMedia[i].objectId)) {
              batchSelectMedia(editionMedia[i])
            }
          }
        } else {
          for (var i = prevMediaPos; i >= currentMediaPos; i--) {
            if (!selections.find((obj) => obj['objectId'] === editionMedia[i].objectId)) {
              batchSelectMedia(editionMedia[i])
            }
          }
        }
      }
    } else {
      selectMedia(item)
    }
  }
  onDragStart = (e) => {
    const { dragging } = this.props.globalState
    const { selections } = this.props.mediaState
    e.dataTransfer.setData('text/panel', 'Media')
    dragging(selections)
  }
  render() {
    const { mediaState, edition } = this.props
    const { previewTemplateOnClick } = this.props
    const {
      add,
      uploading,
      pending,
      failed,
      selections,
    } = mediaState
    const numSelections = selections.length
    const hasMultiSelect = numSelections > 1
    const ErrorList = failed.length ? <FailedUploadList mediaState={mediaState} /> : null
    const PendingList =
      uploading && pending.length ? <PendingUploadList mediaState={mediaState} /> : null
    return (
      <div className="media-card">
        <div className="media-preview">
          <MediaPreview mediaState={mediaState} templateOnClick={previewTemplateOnClick} mediaUpdater={this.mediaAdder} />
          { hasMultiSelect ?
            <div className="multi-select">
              <div className="selected">
                Files <span class="count">{numSelections}</span>
              </div> 
              <div className="remove">
                <button type="button" title="Delete selected files" onClick={mediaState.confirmBatchDelete}>
                  <i className="sprite raw-media remove" />
                </button>
              </div>
              <div className="deselect">
                <button onClick={mediaState.deselectAllMedia} title="Deselect files">x</button>
              </div>
            </div> :
            <div className="multi-select"></div>
          }
          <div className="actions">
            <i className="sprite raw-media media-svg" />
            <SearchMedia mediaState={mediaState} />
            <AddMedia mediaState={mediaState} mediaAdder={this.mediaAdder} />
          </div>
        </div>
        <div className="media-lists" onClick={this.deselect} onDragStart={this.onDragStart}>
          {ErrorList}
          {PendingList}
          <MediaList title="Templates" media={edition.sortedAssetTemplate} mediaState={mediaState}  mediaRemover={this.mediaRemover} mediaSelector={this.mediaSelector} expandedProp="assetsExpanded"/>
          <MediaList
            title="Sprites"
            media={edition.sortedSprites}
            expandedProp="spritesExpanded"
            mediaState={mediaState}
            mediaUpdater={this.mediaUpdater}
            mediaRemover={this.mediaRemover}
            mediaSelector={this.mediaSelector}
          />
          <MediaList
            title="Audio"
            media={edition.sortedAudio}
            expandedProp="audioExpanded"
            mediaState={mediaState}
            mediaUpdater={this.mediaUpdater}
            mediaRemover={this.mediaRemover}
            mediaSelector={this.mediaSelector}
          />
          <MediaList
            title="HTML Animations"
            media={edition.sortedCanvases}
            expandedProp="htmlAnimationsExpanded"
            mediaState={mediaState}
            mediaUpdater={this.mediaUpdater}
            mediaRemover={this.mediaRemover}
            mediaSelector={this.mediaSelector}
          />
          <MediaList
            title="Video"
            media={edition.sortedVideo}
            expandedProp="videoExpanded"
            mediaState={mediaState}
            mediaUpdater={this.mediaUpdater}
            mediaRemover={this.mediaRemover}
            mediaSelector={this.mediaSelector}
          />
        </div>
      </div>
    )
  }
})

export default MediaPanel

export const PendingUploadList = observer(({ mediaState }) => {
  const { pending } = mediaState
  const items = pending.map((file, i) => {
    const fileName = file.name
    const type = getFileExtension(fileName)
    const iconType = `media-${type.toLowerCase()}`
    return (
      <div className="media-list-item" key={i}>
        <i className={`sprite raw-media ${iconType}`} />
        <div className={`media-name`}>{fileName}</div>
        <div className="media-loading">
          <Pending /> 
        </div>
      </div>
    )
  })
  return (
    <div>
      <h4 className="card-subsection-title">Pending</h4>
      <div className={`card-subsection media-list expanded`}>{items}</div>
    </div>
  )
})

export const FailedUploadList = observer(({ mediaState }) => {
  const clearFailedMedia = () => {
    mediaState.clearFailed()
  }
  const { failed } = mediaState
  const items = failed.map((file, i) => {
    const fileName = file.name
    const type = getFileExtension(fileName)
    const iconType = `media-${type.toLowerCase()}`
    return (
      <div className="media-list-item" key={i}>
        <i className={`sprite raw-media ${iconType}`} />
        <div className={`media-name`} style={{ color: '#f54c7f' }} title={file.error}>
          {fileName}
        </div>
      </div>
    )
  })
  return (
    <div>
      <h4 className="card-subsection-title">
        Errored
        <div className="actions">
          <button type="button" className="remove" onClick={clearFailedMedia}>
            <i className="icon remove" />
          </button>
        </div>
      </h4>
      <div className={`card-subsection media-list expanded`}>{items}</div>
    </div>
  )
})

export const MediaList = observer(
  ({ title, media, expandedProp, mediaState, mediaUpdater, mediaRemover, mediaSelector }) => {
    const { searchQuery, uploading } = mediaState
    const toggle = () => {
      mediaState.toggleSection(expandedProp)
    }
    let filteredMedia = []
    if (searchQuery.length && media.length) {
      filteredMedia = media.filter((m) => {
        const name = m.fileName || m.name
        return name.toLowerCase().includes(searchQuery.toLowerCase())
      })
    } else {
      filteredMedia = media
    }
    const items = filteredMedia.map((item) => {
      return (
        <MediaItem
          key={item.objectId}
          m={item}
          title={title}
          mediaState={mediaState}
          mediaUpdater={mediaUpdater}
          mediaRemover={mediaRemover}
          mediaSelector={mediaSelector}
        />
      )
    })
    const isExpanded = mediaState[expandedProp]
    return (
      <div data-qa-hook={`mediaList-${title}`}>
        <h4 className="card-subsection-title" onClick={toggle}>
          {title}
          <div className="actions">
            <button data-qa-hook={`mediaList-${title}.toggle`} type="button" className={`chevron ${isExpanded ? '' : 'collapsed'}`}>
              <i className="sprite script chevron" />
            </button>
          </div>
        </h4>
        <div
          className={`card-subsection media-list ${isExpanded ? 'expanded' : 'collapsed'} ${
            items.length ? '' : 'no-items'
          } ${uploading ? 'uploading-in-progress' : ''}`}>
          {items.length ? items : <p className="empty">No {title}</p>}
        </div>
      </div>
    )
  }
)

export const MediaItem = observer(class MediaItem extends React.Component {
  remove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { m, mediaRemover } = this.props
    if (window.confirm(`Are you sure you want to remove ${m.fileName}?`)) {
      mediaRemover(m)
    }
  }
  select = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { m, mediaSelector } = this.props
    mediaSelector(m, e)
  }
  render() {
    const { m, mediaState, mediaUpdater, title } = this.props
    const { selections } = mediaState

    const name = m.fileName || m.name
    const ext = m.fileExt || "JPG"
    const iconType = `media-${ext.toLowerCase()}`
    const isSelected = selections.includes(m)
    const isDraggable = isSelected ? true : false

    return (
      <div
        data-qa-hook={`mediaList-${title}-item.${m.fileName}`}
        className={`media-list-item ${isSelected ? 'selected' : ''}`}
        onClick={this.select}
        draggable={isDraggable}>
        <i className={`sprite raw-media ${iconType} ${isSelected ? 'selected' : ''}`} />
        <div className={`media-name ${isSelected ? 'selected' : ''}`}>{name}</div>
        { mediaUpdater ?
          <div className="media-update">
            <UpdateMedia media={m} mediaUpdater={mediaUpdater} />
          </div>
          :
          null
        }
        <div className="media-remove">
          <button type="button" onClick={this.remove}>
            <i className="sprite raw-media remove" />
          </button>
        </div>
      </div>
    )
  }
})

export const MediaPreview = observer(class MediaPreview extends React.Component {
  render() {
    const { mediaState, mediaUpdater } = this.props
    const { templateOnClick } = this.props
    const { selections } = mediaState
    const media = selections.length ? selections[0] : null

    let vDom

    if (!media) {
      vDom = 
        <div className="preview">
          <div className="body">Preview</div>
          <div className="meta" />
        </div>
    } else if ('RawSprite' === media.Type) {
      vDom =
        <SpritePreview media={media} mediaHost={mediaState.mediaHost} mediaUpdater={mediaUpdater} />
    } else if ('RawAudio' === media.Type) {
      vDom =
        <AudioPreview media={media} mediaHost={mediaState.mediaHost} mediaUpdater={mediaUpdater} />
    } else if ('RawVideo' === media.Type) {
      vDom =
        <VideoPreview media={media} mediaHost={mediaState.mediaHost} mediaUpdater={mediaUpdater} />
    } else if ('RawHTML' === media.Type) {
      vDom =
        <CanvasPreview media={media} mediaHost={mediaState.mediaHost} mediaUpdater={mediaUpdater} />
    } else if (media.Type.match("Template")) {
      vDom =
        <TemplatePreview media={media} onClick={templateOnClick}>
        </TemplatePreview>
    }

    return vDom
  }
})

export const TemplatePreview = observer(class TemplatePreview extends React.Component {
  render() {
    const onClick = (id) => {
      this.props.onClick(id)
    }
    const linksVDom =
      this.props.media
      .links()
      .map(([text, id]) =>
        <div className="form-row" onClick={() => onClick(id)}>
          <div className="fill ref">
            {text}
          </div>
        </div>
      )

    const vDom =
      <div className="preview">
        <div className="body">Preview</div>
        <div className="meta">
          <div className="meta-form">
            <strong>References</strong>
            {linksVDom}
          </div>
        </div>
      </div>

    return vDom
  }
})

export const SpritePreview = observer(class SpritePreview extends React.Component {
  render() {
    const { media, mediaHost, mediaUpdater } = this.props
    const updateFileName = (val) => updateProp('fileName', val, media)
    const updateFrameCount = (val) => updateProp('frameCount', Number(val), media)
    const updateRowCount = (val) => updateProp('rowCount', Number(val), media)
    const updateColumnCount = (val) => updateProp('columnCount', Number(val), media)
    const props = {
      className: 'body',
      style: {
        backgroundImage: `url(${mediaHost}${media.src})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }
    }
    return (
      <div className="preview">
        <div {...props} />
        <div className="meta">
          <div className="meta-form">
            <div className="form-row">
              <div className="key fit">Name</div>
              <div className="value fill">
                <Input type="text" value={media.fileName} onSave={updateFileName} />
              </div>
            </div>
            <div className="form-row">
              <div className="key fit">Frames</div>
              <div className="value fill">
                <Input type="text" value={media.frameCount} onSave={updateFrameCount} />
              </div>
            </div>
            <div className="form-row">
              <div className="key fit">Rows</div>
              <div className="value fill">
                <Input type="text" value={media.rowCount} onSave={updateRowCount} />
              </div>
            </div>
            <div className="form-row">
              <div className="key fit">Columns</div>
              <div className="value fill">
                <Input type="text" value={media.columnCount} onSave={updateColumnCount} />
              </div>
            </div>
          </div>
          <UpdateMedia media={media} mediaUpdater={mediaUpdater} />
        </div>
      </div>
    )
  }
})

class AudioPreviewComponent extends React.Component {
  toggleAudio = (e) => {
    this.playing = !this.playing
  }
  constructor(props) {
    super(props)
    this.audioEl = null
    this.playing = false
    this.playStateDisposer = null
  }
  componentDidMount() {
    // Setup an observer for the play state to handle the audio DOM
    this.playStateDisposer = observe(this, 'playing', (change) => {
      if (change.newValue === true) {
        this.audioEl.play()
        this.audioEl.addEventListener('ended', this.toggleAudio)
      } else {
        this.audioEl.pause()
        this.audioEl.currentTime = 0
      }
    })
  }
  componentWillUnmount() {
    // Dispose observer
    this.playStateDisposer()
  }
  render() {
    const { media, mediaHost, mediaUpdater } = this.props
    const updateFileName = (val) => updateProp('fileName', val, media)
    const updateDurationMs = (val) => updateProp('durationMs', Number(parseFloat(val) * 1000, media))
    const isPlaying = this.playing
    const props = {
      src: `${mediaHost}${media.src}`,
      controls: false,
      autoPlay: false,
      preload: 'auto',
      ref: (el) => (this.audioEl = el),
      style: {
        display: 'none'
      }
    }
    return (
      <div className="preview">
        <div className="body">
          <audio {...props} />
          <button
            type="button"
            className={`audio-control ${isPlaying ? 'playing' : 'paused'}`}
            onClick={this.toggleAudio}>
            <i className={`sprite media ${isPlaying ? 'play' : 'pause'}`} />
          </button>
        </div>
        <div className="meta">
          <div className="meta-form">
            <div className="form-row">
              <div className="key fit">Name</div>
              <div className="value fill">
                <Input type="text" value={media.fileName} onSave={updateFileName} />
              </div>
            </div>
            <div className="form-row">
              <div className="key fit">Duration</div>
              <div className="value fill">
                <Input
                  type="text"
                  value={(media.durationMs / 1000).toFixed(1) + 's'}
                  onSave={updateDurationMs}
                />
              </div>
            </div>
          </div>
          <UpdateMedia media={media} mediaUpdater={mediaUpdater} />
        </div>
      </div>
    )
  }
}

decorate(AudioPreviewComponent, {
  audioEl: observable.ref,
  playing: observable,
  toggleAudio: action,
})

const AudioPreview = observer(AudioPreviewComponent)

export const VideoPreview = observer(class VideoPreview extends React.Component {
  render() {
    const { media, mediaHost, mediaUpdater } = this.props
    const props = {
      src: `${mediaHost}${media.src}`,
      autoPlay: false,
      controls: true,
      style: {
        width: '100%',
        height: '100%'
      }
    }
    const updateFileName = (val) => updateProp('fileName', val, media)
    return (
      <div className="preview">
        <div className="body">
          <video {...props} />
        </div>
        <div className="meta">
          <div className="meta-form">
            <div className="form-row">
              <div className="key fit">Name</div>
              <div className="value fill">
                <Input type="text" value={media.fileName} onSave={updateFileName} />
              </div>
            </div>
          </div>
          <UpdateMedia media={media} mediaUpdater={mediaUpdater} />
        </div>
      </div>
    )
  }
})

class CanvasPreviewComponent extends React.Component {
  updateLoading = (val) => {
    this.loading = val
  }
  updateDimensions = (w, h) => {
    this.canvasWidth = parseInt(w)
    this.canvasHeight = parseInt(h)
  }
  updatePreview = (val) => {
    this.previewFrame = parseInt(val)
  }
  updateFileName = (val) => {
    const { media } = this.props
    updateProp('fileName', val, media)
  }
  constructor(props) {
    super(props)
    this.el = null
    this.previewBodyEl = null
    this.loading = true
    this.previewFrame = 0
    this.exportRoot = null
    this.stage = null
    this.canvasWidth = 0
    this.canvasHeight = 0
  }
  loadAnimation(src, animationName) {
    window.lib = {}
    utils.loadScript(src, () => {
      // lib will be a global variable at this point
      if (lib) this.setupAnimation(lib, animationName)
    })
  }
  setupAnimation(lib, animationName) {
    const unadjusted = animationName
    const preffixed = `_${animationName}`
    const suffixed = `${animationName}_Canvas`
    const suffixedAndPrefixed = `_${animationName}_Canvas`
    if (lib) {
      if (lib[preffixed]) animationName = preffixed
      else if (lib[suffixed]) animationName = suffixed
      else if (lib[suffixedAndPrefixed]) animationName = suffixedAndPrefixed
      else if (lib[unadjusted]) animationName = unadjusted
    }
    if (createjs && lib && lib[animationName]) {
      var canvas = this.el
      this.exportRoot = new lib[animationName]()
      this.stage = new createjs.Stage(canvas)
      this.stage.enableDOMEvents(false)
      // This will kill the createjs Ticker altogether - helping performance, but
      // also prevents child movie clips from ticking (i.e. the Ambulance sirens in 2thebasics)
      //this.stage.tickEnabled = false
      this.stage.addChild(this.exportRoot)
      this.sizeAnimation(lib.properties.width, lib.properties.height)
      this.updatePreview(0)
      this.updateLoading(false)
    } else {
      console.warn('[ERROR] Could not find canvas lib property: ' + animationName)
    }
  }
  sizeAnimation(animationWidth, animationHeight) {
    const { media } = this.props
    if (!this.previewBodyEl) return
    //Code to support hidpi screens and responsive scaling.
    var iw = this.previewBodyEl.clientWidth,
      ih = this.previewBodyEl.clientHeight
    var pRatio = window.devicePixelRatio,
      xRatio = iw / animationWidth,
      yRatio = ih / animationHeight,
      sRatio = 1
    sRatio = Math.min(xRatio, yRatio)
    //Bitmapped canvas size
    var canvasWidth = animationWidth * pRatio * sRatio
    var canvasHeight = animationHeight * pRatio * sRatio
    this.stage.scaleX = pRatio * sRatio
    this.stage.scaleY = pRatio * sRatio
    this.updateDimensions(canvasWidth, canvasHeight)
  }
  componentDidMount() {
    const { media, mediaHost } = this.props
    if (media.src) {
      let animationName = media.fileName.replace(/(.js)|(-)+/g, '')
      this.loadAnimation(`${mediaHost}${media.src}`, animationName)
    }
  }
  componentWillReceiveProps(nextProps) {
    const { media: nextMedia, mediaHost } = nextProps
    if (nextMedia.src && nextMedia.src !== this.props.media.src) {
      let animationName = nextProps.media.fileName.replace(/(.js)|(-)+/g, '')
      this.loadAnimation(`${mediaHost}${nextProps.media.src}`, animationName)
    }
  }
  render() {
    const { media, mediaUpdater } = this.props
    const { exportRoot, stage } = this
    const previewFrame = this.previewFrame
    const isLoading = this.loading
    const canvasWidth = this.canvasWidth
    const canvasHeight = this.canvasHeight

    const fileName = media.fileName
    const totalFrames = media.totalFrames

    let canvasProps = {
      //ref: 'canvas',
      ref: (el) => (this.el = el),
      width: canvasWidth,
      height: canvasHeight,
      style: {}
    }

    if (!isLoading && exportRoot && stage) {
      exportRoot.gotoAndStop(previewFrame)
      stage.update()
    }

    return (
      <div className="preview">
        <div ref={(el) => (this.previewBodyEl = el)} className="body">
          {isLoading ? <i className="icon loading" /> : null}
          <canvas {...canvasProps} />
        </div>
        <div className="meta">
          <div className="meta-form">
            <div className="form-row">
              <div className="key fit">Name</div>
              <div className="value fill">
                <Input type="text" value={fileName} onSave={this.updateFileName} />
              </div>
            </div>
            <div className="form-row">
              <div className="key fit">Frame</div>
              <div className="value fill">
                <Input
                  type="text"
                  value={previewFrame}
                  placeholder={0}
                  onSave={this.updatePreview}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="value fill">
                <input
                  id="scrubber"
                  type="range"
                  min="0"
                  max={totalFrames}
                  value={previewFrame}
                  step="1"
                  style={{ width: '100%' }}
                  onChange={(e) => this.updatePreview(e.target.value)}
                />
              </div>
            </div>
          </div>
          <UpdateMedia media={media} mediaUpdater={mediaUpdater} />
        </div>
      </div>
    )
  }
}

decorate(CanvasPreviewComponent, {
  el: observable.ref,
  previewBodyEl: observable.ref,
  loading: observable,
  previewFrame: observable,
  exportRoot: observable.ref,
  stage: observable.ref,
  canvasWidth: observable,
  canvasHeight: observable,
  updateLoading: action.bound,
  updateDimensions: action.bound,
  updatePreview: action.bound,
})

const CanvasPreview = observer(CanvasPreviewComponent)

export const UpdateMedia = observer(class UpdateMedia extends React.Component {
  accept(media) {
    switch (media.fileExt) {
      case 'jpg':
      case 'png':
      case 'svg':
        return '.jpg,.png,.svg'
      case 'mp3':
        return '.mp3'
      case 'mp4':
        return '.mp4'
      case 'js':
        return '.js'
    }
  }
  upload(e) {
    const { target: { files } } = e
    const { media, mediaUpdater } = this.props
    mediaUpdater(media, files)
    this.input = initializeFileInput(false, this.accept(media))
    this.input.onchange = this.upload.bind(this)
  }
  componentDidMount() {
    const { media } = this.props
    this.input = initializeFileInput(false, this.accept(media))
    this.input.onchange = this.upload.bind(this)
  }
  render() {
    const onClick = (e) => this.input.click()
    return (
      <button type="button" className="link" onClick={onClick} title="Relink...">
        <i className="sprite raw-media link" />
      </button>
    )
  }
})

export const AddMedia = observer(class AddMedia extends React.Component {
  componentDidMount() {
    this.input = initializeFileInput(true)
    this.input.onchange = this.upload.bind(this)
  }
  upload(e) {
    const { mediaAdder } = this.props
    mediaAdder(e.target.files)
    this.input = initializeFileInput(true)
    this.input.onchange = this.upload.bind(this)
  }
  render() {
    const onClick = (e) => this.input.click() // trigger a click on the invisible input
    return (
      <button type="button" className="add-media" onClick={onClick} title="Add Media">
        <i className="sprite media add" />
      </button>
    )
  }
})

export const SearchMedia = observer(class SearchMedia extends React.Component {
  onChange = (val) => {
    const { mediaState } = this.props
    mediaState.doSearch(val)
  }
  render() {
    const { mediaState } = this.props
    return (
      <Input
        type="text"
        className="search-media"
        value={mediaState.searchQuery}
        onSave={this.onChange}
        placeholder="Search"
      />
    )
  }
})

export class BatchDeleteModal extends React.Component {
  render () {
    const { mediaState } = this.props
    const { dialog } = mediaState
    const { showBatchDelete } = mediaState

    if (!showBatchDelete) return null

    const { selections } = mediaState
    const { batchDelete, denyBatchDelete } = mediaState
    const numToDelete = selections.length

    return (
      <div className="file-upload-popup">
        <ModalWrapper title="" hideModal={denyBatchDelete} buttonAlign="right" okText="Yes" cancelText="Cancel" onOk={batchDelete} width="700px" height="240px">
          <div className="modal-header-container" style={ {textAlign: "center"} }>
            <label>{`Are you sure that you want to delete`}<span style={ {color: "#fff", fontWeight: 900} }> {numToDelete}</span> {`files?`}</label> 
          </div>
        </ModalWrapper>
      </div>
    )

  }
}

export const UploadFileModal = observer(class UploadFileModal extends React.Component {
  render() {
    const { mediaState } = this.props;

    const count = mediaState.newFiles.length + mediaState.relinkedFiles.length;

    return (
      <div className="file-upload-popup">
        <ModalWrapper
          title="Upload Confirmation"
          hideModal={this.props.cancel}
          width="700px"
          height="auto"
          modaltype="popup"
        >
          <div className="modal-header-container">
            <label> File Upload: {count} files</label>

            {mediaState.newFiles.length > 0 && (
              <div className="alert alert-success">
                Uploading{" "}
                <span className="count">{mediaState.newFiles.length}</span> new
                files. Happy Designing!
              </div>
            )}
            {mediaState.relinkedFiles.length > 0 && (
              <div className="alert alert-warning">
                <div className="alert-message">
                  {"We have found "}<span className="count">{mediaState.relinkedFiles.length}</span>{" repeated file names, which have been relinked:"}
                </div>
                <div className="separator" />
                <div className="file-selection-list">
                  <div className="file-selection-rows">
                    {mediaState.relinkedFiles.map((name, index) => {
                      return (
                        <div
                          className="file-selection-row"
                          key={"file-row-" + index}
                        >
                          <div className="file-name">{name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className="buttons">
              <button className="right-option" onClick={this.props.cancel}>
                Ok
              </button>
            </div>
          </div>
        </ModalWrapper>
      </div>
    );
  }
})

export const NewTemplateModal = observer(class NewTemplateModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      isValid: false
    }
  }
  render() {
    const { onCancel } = this
    const { onInput } = this
    const { onSubmit } = this
    const { clearValue } = this
    const { value } = this.state
    const { isValid } = this.state

    const isSubmitDisabled = !isValid
    const isCancelDisabled = true

    const vDom =
      <div>
        <ModalWrapper
          title="Name New Template"
          hideModal={onCancel}
          width="700px"
          height="auto"
          modaltype="popup">
          <div className="newedition-wrap">
            <label>Name New Template</label>
            <div className="edition-name-block">
              <input
                type="text"
                maxLength={128}
                className="edition-name"
                placeholder="Enter a name for the template"
                onChange={onInput}
                value={value}
                data-qa-hook="modalInput.templateName"
              />
              <input type="button" className="clear-name" onClick={clearValue} data-qa-hook="modalButton.clearName"/>
            </div>
            <label className="validation">
              <span className={isValid ? `hidden` : ``}>
                Name is required. Maximum of 128 characters.
              </span>
            </label>
            <div className="buttons">
              <button
                className="left-option"
                onClick={onSubmit}
                disabled={isSubmitDisabled ? true : false}
                data-qa-hook="modalButton.createTemplate">
                Create Template
              </button>
              <button
                className="right-option"
                onClick={onCancel}
                disabled={isCancelDisabled ? true : false}
                data-qa-hook="modalButton.cancel">
                Cancel
              </button>
            </div>
          </div>
        </ModalWrapper>
      </div>

    return vDom
  }

  onCancel = () => {
    this.props.onCancel()
  }

  onSubmit = () => {
    const { value } = this.state

    this.props.onSubmit(value)
  }

  onInput = e => {
    const { onInput } = this.props

    const newValue = e.target.value

    if (onInput(newValue)) {
      this.setState({ value: newValue, isValid: true })
    } else {
      this.setState({ value: newValue, isValid: false })
    }
  }
})

NewTemplateModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export class UpdateTemplateModal extends React.Component {
  render() {
    const { onCancel } = this
    const { onSubmit } = this

    const vDom =
      <div>
        <ModalWrapper
          title="Name New Template"
          hideModal={onCancel}
          width="700px"
          height="auto"
          modaltype="popup">
          <div className="newedition-wrap">
            <label>Are you sure you'd like to globally update this template?</label>
            <div className="buttons">
              <button
                className="left-option"
                onClick={onSubmit}
                data-qa-hook="modalButton.updateTemplate">
                Update Template
              </button>
              <button
                className="right-option"
                onClick={onCancel}
                data-qa-hook="modalButton.cancel">
                Cancel
              </button>
            </div>
          </div>
        </ModalWrapper>
      </div>

    return vDom
  }

  onCancel = () => {
    this.props.onCancel()
  }
  onSubmit = () => {
    this.props.onSubmit()
  }
}

UpdateTemplateModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export class TemplateUpdatesModal extends React.Component {
  render() {
    const { onDone } = this.props
    const { children } = this.props

    const count = React.Children.count(children)

    const vDom =
      <div className="file-upload-popup">
        <ModalWrapper
          title="Upload Confirmation"
          hideModal={onDone}
          width="700px"
          height="auto"
          modaltype="popup"
        >
          <div className="modal-header-container">
            <label>{count} Templates Updated</label>
              <div className="alert alert-warning">
                <div className="file-selection-list">
                  <div className="file-selection-rows">
                    {children}
                  </div>
                </div>
              </div>
            <div className="buttons">
              <button data-qa-hook={`modalButton.ok`} className="right-option" onClick={onDone}>
                OK
              </button>
            </div>
          </div>
        </ModalWrapper>
      </div>

    return vDom
  }
}

TemplateUpdatesModal.propTypes = {
  onDone:  PropTypes.func.isRequired
}
