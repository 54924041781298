import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import { ONDROP_WHITELIST } from '../constants'
import { moveContent } from '../actions/edition'
import { setContentParent } from '../actions/edition'

const StructureHierarchy = observer(({ edition, state, route, variables }) => {
  // array of classes for what node types are being dragged
  const dragTypes = !state.global.draggingFromScript
    ? state.global.dragItems.map((item) => 'dragging-' + item.Type.toLowerCase())
    : []
  return (
    <div className={['structure-hierarchy'].concat(dragTypes).join(' ')}>
      <div className="card-subsection">
        <ol className="tree is-root">
          <Tree
            key={edition.objectId}
            node={edition}
            state={state}
            variables={variables}
            route={route}
          />
        </ol>
      </div>
    </div>
  )
})

class TreeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isDraggedOver = false
  }
  getChildren = (type) => {
    const { node, ...rest } = this.props
    const contentTypes = {
      Edition: () => {
        let children = []
        for (var i = 0, l = node.chapters.length; i < l; i++) {
          var c = node.chapters[i]
          children.push(<Tree key={c.objectId} node={c} {...rest} />)
        }
        for (var i = 0, l = node.decisionNodes.length; i < l; i++) {
          var d = node.decisionNodes[i]
          children.push(<Tree key={d.objectId} node={d} {...rest} />)
        }
        return children
      },
      Chapter: () => {
        let children = []
        for (var i = 0, l = node.sequences.length; i < l; i++) {
          var s = node.sequences[i]
          children.push(<Tree key={s.objectId} node={s} {...rest} />)
        }
        for (var i = 0, l = node.decisionNodes.length; i < l; i++) {
          var d = node.decisionNodes[i]
          children.push(<Tree key={d.objectId} node={d} {...rest} />)
        }
        return children
      },
      default: () => {
        return []
      }
    }
    return (contentTypes[type] || contentTypes['default'])()
  }
  onClick = (e) => {
    const { node, state } = this.props
    e.preventDefault()
    state.structure.selections.setSelection(node, e.shiftKey)
  }
  getOnDoubleClick = (type) => {
    const { node, state, variables, route } = this.props
    const contentTypes = {
      Sequence: (e) => {
        e.preventDefault()
        e.stopPropagation()
        state.layout.setup(variables, node)
        route.goTo('Layout')
      },
      default: (e) => {
        e.preventDefault()
        e.stopPropagation()
      }
    }
    return contentTypes[type] || contentTypes['default']
  }
  onDragStart = (e) => {
    const { state } = this.props
    const { nodes: selections } = state.structure.selections
    e.dataTransfer.setData('text/panel', 'StructureHierarchy')
    state.global.draggingFromScript = false
    state.global.dragging(selections)
  }
  onDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.isDraggedOver = true
  }
  onDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.isDraggedOver = false
  }
  onDragEnd = (e) => {
    const { state } = this.props
    e.preventDefault()
    e.stopPropagation()
    this.isDraggedOver = false
    state.global.dropped()
  }
  getOnDrop = (type) => {
    const { node, state } = this.props
    const contentTypes = {
      Edition: (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.isDraggedOver = false
        const droppedItems = state.global.dropped()
        const droppedFrom = e.dataTransfer.getData('text/panel')
        const isValidDrop = ONDROP_WHITELIST['StructureHierarchy'].includes(droppedFrom)
        if (!isValidDrop) {
          state.global.showAlert(
            'warn',
            `Cannot drop items from the ${droppedFrom} panel onto the StructureHierarchy panel.`
          )
          return
        }
        for (var i = 0, l = droppedItems.length; i < l; i++) {
          var child = droppedItems[i]
          if (node === child) continue
          if ('Sequence' === child.Type) continue
          else if ('DecisionNode' === child.Type) setContentParent(node, child)
          else if ('Chapter' === child.Type) setContentParent(node, child)
        }
      },
      Chapter: (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.isDraggedOver = false
        const droppedItems = state.global.dropped()
        const droppedFrom = e.dataTransfer.getData('text/panel')
        const isValidDrop = ONDROP_WHITELIST['StructureHierarchy'].includes(droppedFrom)
        if (!isValidDrop) {
          state.global.showAlert(
            'warn',
            `Cannot drop items from the ${droppedFrom} panel onto the StructureHierarchy panel.`
          )
          return
        }
        for (var i = 0, l = droppedItems.length; i < l; i++) {
          var child = droppedItems[i]
          if (node === child) continue
          if ('Edition' === child.Type) continue
          else if ('DecisionNode' === child.Type) setContentParent(node, child)
          else if ('Chapter' === child.Type) moveContent(node, child)
          else if ('Sequence' === child.Type) setContentParent(node, child)
        }
      },
      Sequence: (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.isDraggedOver = false
        const droppedItems = state.global.dropped()
        const droppedFrom = e.dataTransfer.getData('text/panel')
        const isValidDrop = ONDROP_WHITELIST['StructureHierarchy'].includes(droppedFrom)
        if (!isValidDrop) {
          state.global.showAlert(
            'warn',
            `Cannot drop items from the ${droppedFrom} panel onto the StructureHierarchy panel.`
          )
          return
        }
        for (var i = 0, l = droppedItems.length; i < l; i++) {
          var child = droppedItems[i]
          if (node === child) continue
          if ('Edition' === child.Type) continue
          else if ('DecisionNode' === child.Type) continue
          else if ('Chapter' === child.Type) continue
          else if ('Sequence' === child.Type) moveContent(node, child)
        }
      },
      DecisionNode: (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.isDraggedOver = false
        const droppedItems = state.global.dropped()
        const droppedFrom = e.dataTransfer.getData('text/panel')
        const isValidDrop = ONDROP_WHITELIST['StructureHierarchy'].includes(droppedFrom)
        if (!isValidDrop) {
          state.global.showAlert(
            'warn',
            `Cannot drop items from the ${droppedFrom} panel onto the StructureHierarchy panel.`
          )
          return
        }
        for (var i = 0, l = droppedItems.length; i < l; i++) {
          var child = droppedItems[i]
          if (node === child) continue
          if ('Edition' === child.Type) continue
          else if ('DecisionNode' === child.Type) moveContent(node, child)
          else if ('Chapter' === child.Type) continue
          else if ('Sequence' === child.Type) continue
        }
      },
      default: () => {}
    }
    return contentTypes[type] || contentTypes['default']
  }
  componentDidMount() {
    const { state } = this.props
    const { ui } = state
    window.requestAnimationFrame(() => {
      // TODO This raw DOM Element doesn't track when objectId of owner changes,
      // e.g. duplicate edition
      const el = ui.scrollControl.hierarchySelectedEl
      el && el.scrollIntoView({ inline: 'center' })
    })
  }
  render() {
    const { node, state } = this.props
    const { Type, name, objectId } = node
    const { ui } = state
    const { nodes: selections } = state.structure.selections

    const isSelected = selections.includes(node) || (selections.length === 0 && Type === 'Edition')
    const isDragging = state.global.draggingFromScript
      ? false
      : state.global.dragItems.includes(node)
    const isDraggable = isSelected && Type !== 'Edition'
    const isDraggedOver = this.isDraggedOver

    const nodeType = Type.toLowerCase()
    const displayName = name || Type
    const escapedName = displayName.toLowerCase().replace(/\s+/g,'_')
    
    let itemClass = `tree-item tree-item-${nodeType}`
    if (isDragging) itemClass += ' dragging'
    if (isDraggedOver) itemClass += ' over'
    let itemWrapperClass = 'tree-item-inner'
    if (isSelected) itemWrapperClass += ' selected'
    
    return (
        <li
          className={itemClass}
          draggable={isDraggable}
          onDragStart={this.onDragStart}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDragEnd={this.onDragEnd}
          onDrop={this.getOnDrop(Type)}
          ref={isSelected ? ui.scrollControl.setHierarchySelectedEl : null}>
          <span className="drop-target" />
          <div
            className={itemWrapperClass}
            onClick={this.onClick}
            onDoubleClick={this.getOnDoubleClick(Type)}
            data-qa-hook={`hierarchy.${nodeType}.entry.${escapedName}`}>
              <span className={`sprite hierarchy ${nodeType}`} data-qa-hook={`hierarchy.${nodeType}.icon`}/>
              <span className="tree-item-title" title={displayName}data-qa-hook={`hierarchy.${nodeType}.name`}>{displayName}</span>
          </div>
          <ol className="tree">
            {this.getChildren(Type)}
          </ol>
        </li>
    )
  }
}

decorate(TreeComponent, {
  isDraggedOver: observable,
})

var Tree = observer(TreeComponent)

export default StructureHierarchy
