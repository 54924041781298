import { types } from 'mobx-state-tree'
import { Fill, Layerable, Rectangle, Stroke, Transformable, Visible, UI } from './shared/props'
import assetViews from './shared/views'

const AssetType = 'MenuGroup'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    labeledBy: types.string,
    children: types.late(() => types.array(require('./MenuGroupItem').default)),
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)

export default model
