import { CONFIG_KEYS } from "./constants"

const { CAPTURE_TASK_POLL_MS } = CONFIG_KEYS

const url = new URL(window.location)
const params = url.searchParams

const origin = ''

const capturePollMs =
  params.has(CAPTURE_TASK_POLL_MS) &&
  Number(params.get(CAPTURE_TASK_POLL_MS)) &&
  Number.isSafeInteger(Number(params.get(CAPTURE_TASK_POLL_MS))) &&
  Number(params.get(CAPTURE_TASK_POLL_MS)) > 1000 ?
    Number(params.get(CAPTURE_TASK_POLL_MS)) :
    (5 * 60) * 1000 


export default {
  API: {
    editions: {
      list: origin + '/authoringtool/editions/byuser',
      alllist: origin + '/authoringtool/editions',
      readversioned: function(id, version) {
        return `${origin}/authoringtool/editions/${id}/${version}`
      },
      readlatest: function(id) {
        return `${origin}/authoringtool/editions/latest/${id}`
      },
      save: origin + '/authoringtool/editions/savelocal',
      // @deprecated
      saveForPreview: origin + '/authoringtool/editions/saveforpreview',
      savemajorversion: function(id) {
        return origin + '/authoringtool/editions/createmajorversion/' + id
      },
      saveminorversion: function(id) {
        return origin + '/authoringtool/editions/createminorversion/' + id
      },
      saveworkingversion: function(id) {
        return origin + '/authoringtool/editions/createworkingversion/' + id
      },
      metadata: origin + '/authoringtool/metadata',
      configdata: origin + '/authoringtool/configdata',
      discard: function(id) {
        return origin + '/authoringtool/editions/discard/' + id
      },
      saveShareLink: origin + '/authoringtool/advisorlinks/save',
      getAdvisorLinks: origin + '/authoringtool/advisorlinks/all',
      getAdvisorLinksByUser: origin + '/authoringtool/advisorlinks/allbyuser',
      getAdvisorActiveLinks: origin + '/authoringtool/advisorlinks/active',
      getAdvisorActiveLinksByUser: origin + '/authoringtool/advisorlinks/activebyuser',
      saveLinkExpiration: origin + '/authoringtool/advisorlinks/expirelink',
      promote: function(id, version, newType) {
        return origin + '/authoringtool/editions/promoteedition/' + id + '/' + version + '/' + newType
      }
    },
    advisor: {
      url: origin + '/authoringtool/advisorlinks/linkurl'
    },
    // @deprecated
    modules: {
      list: origin + '/authoringtool/modules',
      read: function(id) {
        return origin + '/authoringtool/modules/' + id
      },
      save: origin + '/authoringtool/modules/save'
    },
    media: {
      upload: origin + '/authoringtool/media_upload',
      host: origin + '/authoringtool/media/assetmanager/url'
    },
    login: {
      user: origin + '/authoringtool/login',
      redirectURL:  origin + '/authoringtool/login/redirect?redirectUrl=' + encodeURIComponent(window.location)
    },
    // @deprecated
    transcript: {
      html: origin + '/authoringtool/transcript/html',
      docx: origin + '/authoringtool/transcript/docx'
    },
    unversioned: {
      list: ''
    },
    versioned: {
      list: origin + '/authoringtool/editions/all',
      checkin: function(id) {
        return origin + '/authoringtool/editions/ci/' + id
      },
      checkout: function(id) {
        return origin + '/authoringtool/editions/co/' + id
      }
    },
    checkedout: {
      list: origin + '/authoringtool/editions/coeditions'
    },
    versionhistory: {
      list: origin + '/authoringtool/editions/history/',
      summary: origin + '/authoringtool/editions/summary/'
    },
    refcontroller: {
      assetsets: origin + '/authoringtool/references/assetsets'
    },
    templates: {
      list: `${origin}/authoringtool/template`,
      persist: `${origin}/authoringtool/template`,
      permissions:  `${origin}/authoringtool/template/permission`
    },
    capture: {
      edition: (id, ver, code) => `${origin}/authoringtool/capture/editions/${id}/version/${ver}/${code}`,
      task: (id) => `${origin}/authoringtool/capture/tasks/${id}`,
      tasks: (order, sort, pageSize, pageNumber, user, search) => {
        let base = `${origin}/authoringtool/capture/tasks?order=${order}&dir=${sort}&size=${pageSize}&page=${pageNumber}`
        if (user && search) return base += `&username=${user}&s=${encodeURIComponent(search)}`
        else if (user && !search) return base += `&username=${user}`
        else if (!user && search) return base += `&s=${encodeURIComponent(search)}`
        else return base
      },
      queued: (order, sort, pageSize, pageNumber) => {
        return `${origin}/authoringtool/capture/queue?order=${order}&dir=${sort}&size=${pageSize}&page=${pageNumber}`
      },
      canCapture: (id, assetSetPath) =>
        `${origin}/authoringtool/capture/editions/${id}/${assetSetPath}`
    },
  },
  pollIntervals: {
    captureTask: capturePollMs,
  }
}
