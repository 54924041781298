import React from 'react'
import {
  compare,
  contains,
  intersect,
  expressionTextFrom,
  inputTextFrom,
  unescapeString
} from '../utils'
import Input from '../components/Input'
import TextArea from '../components/TextArea'
import Select from 'react-select'
import ColorPicker from '../components/ColorPicker'
import { observer } from 'mobx-react'
import { walkTree } from '../utils'
import { MAX_SEQUENCE_DURATION } from "../models/constants";
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { TEMPLATE_PROPS_EXCLUDE } from '../constants'
import { findByObjectId as findMedia } from '../typed.utils'
import { updateTemplatedModalProp } from '../actions/edition'
import { updateTemplatedProp } from '../actions/edition'
import { updateTemplatedModalColor } from '../actions/edition'
import { updateColor } from '../actions/edition'
import { updateProp } from '../actions/edition'
import { updateTemplatedExpression } from '../actions/edition'
import { removeChildAsset } from '../actions/edition'
import { addMenuGroupItem } from '../actions/edition'
import { addButtonGroupItem } from '../actions/edition'
import { removeButtonGroupItem } from '../actions/edition'
import { addMediaOption } from '../actions/edition'
import { removeMediaOption } from '../actions/edition'
import { addTextOption } from '../actions/edition'
import { removeTextOption } from '../actions/edition'
import { setSequenceDuration } from '../actions/edition'
import { updateOptionValue } from '../actions/edition'
import { updateOptionCondition } from '../actions/edition'
import { addListItem } from '../actions/edition'
import { updateDefaultText } from '../actions/edition'
import { updateOptionText } from '../actions/edition'

const isArray = Array.isArray

const isFormControl = (object) => {
  switch (object.Type) {
    case 'Input':
    case 'Checkbox':
    case 'Radio':
    case 'Dropdown':
    case 'Slider':
      return true
    default:
      return false
  }
}

const isInput = (asset) => {
  switch (asset.Type) {
    case "Button": case "Checkbox": case "Dropdown": case "Input": case "Radio": case "Slider":
      return true
    default:
      return false
  }
}
const FONT_FAMILY_OPTIONS = [
  { value: 'Roboto Condensed, sans-serif', label: 'Roboto Condensed' },
  { value: 'sans-serif', label: 'sans-serif' },
  { value: 'serif', label: 'serif' }
]

const FONT_STYLE_OPTIONS = [
  { value: 'normal', label: 'Normal' },
  { value: 'italic', label: 'Italic' },
  { value: 'oblique', label: 'Oblique' }
]

const TEXT_ALIGN_OPTIONS = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Center' },
  { value: 'right', label: 'Right' },
  { value: 'justify', label: 'Justify' }
]

const TEXT_DECORATION_OPTIONS = [
  { value: 'underline', label: 'U' },
  { value: 'linethrough', label: '$' },
  { value: 'sub', label: 'x' },
  { value: 'sup', label: 'x' }
]

const BORDER_STYLE_OPTIONS = [
  { value: 'none', label: 'None' },
  { value: 'solid', label: 'Solid' },
  { value: 'dashed', label: 'Dashed' },
  { value: 'dotted', label: 'Dotted' },
  { value: 'double', label: 'Double' },
  { value: 'groove', label: 'Groove' },
  { value: 'ridge', label: 'Ridge' },
  { value: 'inset', label: 'Inset' },
  { value: 'outset', label: 'Outset' }
]

const LIST_STYLE_OPTIONS = [
  { value: 'none', label: 'None' },
  { value: 'disc', label: '\u2022 Bullet' },
  { value: 'circle', label: '\u25cb Circle' },
  { value: 'square', label: '\u25a0 Square' },
  { value: 'decimal', label: '1, 2, 3...' },
  { value: 'decimal-leading-zero', label: '01, 02, 03...' },
  { value: 'lower-roman', label: 'i, ii, iii...' },
  { value: 'upper-roman', label: 'I, II, III...' },
  { value: 'lower-alpha', label: 'a, b, c...' },
  { value: 'upper-alpha', label: 'A, B, C...' }
]

const LIST_STYLE_POSITION_OPTIONS = [
  { value: 'outside', label: 'Outside' },
  { value: 'inside', label: 'Inside' }
]

const SLIDER_THEME_OPTIONS = [
  { value: 'default', label: 'Default' },
  { value: 'theme_A', label: 'Theme_A' },
  { value: 'theme_B', label: 'Theme_B' },
  { value: 'theme_C', label: 'Theme_C' }
]

// Note this is populated at runtime based on the stage assets
let formAssetOptions = []
let labeledByOptions = []

// Note this also determines display order of components
const PROPERTIES = [
  'template',
  'width',
  'height',
  'zIndex',
  'tabIndex',
  'text',
  'title',
  'spriteMedia',
  'videoMedia',
  'canvasMedia',
  'value',
  'placeholder',
  'min',
  'max',
  'step',
  'checked',
  'disabled',
  'sliderTheme',
  'htmlFor',
  'labeledBy',
  'fontFamily',
  'fontStyle',
  'fontSize',
  'letterSpacing',
  'fontColor',
  'fontWeight',
  'textDecoration',
  'textShadowColor',
  'textShadowX',
  'textShadowY',
  'textShadowBlur',
  'textShadowSpread',
  'textAlign',
  'lineHeight',
  'listStylePosition',
  'listStyleType',
  'backgroundColor',
  'boxShadowColor',
  'boxShadowX',
  'boxShadowY',
  'boxShadowBlur',
  'boxShadowSpread',
  'borderColor',
  'borderThickness',
  'borderRadius',
  'borderStyle'
]

const FORM_PROPS = [
  'title',
  'value',
  'placeholder',
  'min',
  'max',
  'step',
  'checked',
  'disabled',
  'sliderTheme',
  'htmlFor',
  'labeledBy',
]

const CHARACTER_PROPS = [
  'fontFamily',
  'fontStyle',
  'fontSize',
  'letterSpacing',
  'fontColor',
  'fontWeight',
  'textDecoration',
  'textShadowColor',
  'textShadowX',
  'textShadowY',
  'textShadowBlur',
  'textShadowSpread'
]

const PARAGRAPH_PROPS = ['textAlign', 'lineHeight', 'listStylePosition', 'listStyleType']

const BORDER_PROPS = ['borderColor', 'borderThickness', 'borderRadius', 'borderStyle']

const BACKGROUND_PROPS = [
  'backgroundColor',
  'boxShadowColor',
  'boxShadowX',
  'boxShadowY',
  'boxShadowBlur',
  'boxShadowSpread'
]

const OPTION_PROPS = ['text', 'spriteMedia', 'canvasMedia']

const OPTION_HEADERS = {
  spriteMedia: 'Sprite Media',
  videoMedia: 'Video Media',
  canvasMedia: 'Animation Media',
  text: 'Text'
}

const BASE_PROPS = ['width', 'height', 'zIndex', 'tabIndex' ]

const PSEUDO_PROPS = [
  ...BASE_PROPS,
  ...BACKGROUND_PROPS,
  ...BORDER_PROPS,
  ...CHARACTER_PROPS,
  ...PARAGRAPH_PROPS,
  "template"
]

// should these be wrapped as actions since they handle batch updates?
const setNumber = (mode = "normal", prop, assets, val) => {
  let parsed = parseInt(val)
  if (!isNaN(parsed)) {
    assets.forEach((asset) => {
      if ("normal" === mode) updateTemplatedProp(prop, parsed, asset)
      else updateTemplatedModalProp(mode, prop, parsed, asset)
    })
  }
}

const setText = (mode = "normal", prop, assets, val) => {
  assets.forEach((asset) => {
    updateTemplatedProp(prop, val, asset)
  })
}

const setColor = (mode = "normal", prop, assets, val) => {
  const rgba = [val.rgb.r, val.rgb.g, val.rgb.b, val.rgb.a]
  assets.forEach((asset) => {
    updateTemplatedModalColor(mode, prop, rgba, asset)
  })
}

const setDropdown = (mode = "normal", prop, assets, option) => {
  const val = option ? option.value : ''
  assets.forEach((asset) => {
    if ("normal" === mode) updateTemplatedProp(prop, val, asset)
    else                   updateTemplatedModalProp(mode, prop, val, asset)
  })
}

const setExpression = (mode = "normal", prop, assets, val) => {
  assets.forEach((asset) => {
    updateTemplatedExpression(prop, val, asset)
  })
}

const setButtonGroup = (mode = "normal", prop, assets, val) => {
  assets.forEach((asset) => {
    if ("normal" === mode) updateTemplatedProp(prop, val, asset)
    else                   updateTemplatedModalProp(mode, prop, val, asset)
  })
}

const setTabIndex = (mode = "normal", prop, assets, val) => {
  let parsed = parseInt(val) || null
  assets.forEach((asset) => {
    if ("normal" === mode) updateTemplatedProp(prop, parsed, asset)
    else                   updateTemplatedModalProp(mode, prop, parsed, asset)
  })
}

const NumberField = observer(({ autoName, prop, label, action, value, min, max, step, placeholder }) => {
  return (
    <div className={`form-row number-field ${prop}`}>
      <div className="key">{label}</div>
      <div className="value">
        <Input
          type="number"
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          value={value}
          title={value}
          onSave={action}
          disableOnBlur={false}
          autoName={autoName}
        />
      </div>
    </div>
  )
})

const TextField = observer(({ autoName, prop, label, action, value, placeholder }) => {
  return (
    <div className={`form-row text-field ${prop}`}>
      <div className="key">{label}</div>
      <div className="value">
        <Input
          type="text"
          placeholder={placeholder}
          value={value || ''}
          title={value || ''}
          onSave={action}
          disableOnBlur={false}
          autoName={autoName}
        />
      </div>
    </div>
  )
})

const ColorField = observer(({ prop, label, action, value, state }) => {
  const rgba = value
    ? `rgba(${value[0]}, ${value[1]}, ${value[2]}, ${value[3]})`
    : 'rgba(255, 255, 255, 1)'
  return (
    <div className={`form-row color-field ${prop}`}>
      <div className="key fit">{label}</div>
      <div className="value fill">
        <ColorPicker color={rgba} onChange={action} state={state} />
      </div>
    </div>
  )
})

const DropdownField = observer(({ prop, label, action, value, options, clearable, autoName }) => {
  const dataAttrs = { "data-qa-hook": autoName }
  return (
    <div className={`form-row dropdown-field ${prop}`}>
      <div className="key fit">{label}</div>
      <div className="value fill" title={value}>
        <Select value={value} options={options} onChange={action} clearable={clearable} inputProps={dataAttrs}/>
      </div>
    </div>
  )
})

const ExpressionField = observer(({ prop, label, action, value, autoName }) => {
  return (
    <div className={`form-row expression-field ${prop}`}>
      <div className="key">{label}</div>
      <div className="value">
        <TextArea
          placeholder={`Expression`}
          value={value ? value.string : ''}
          title={value ? value.string : ''}
          onSave={action}
          autoName={autoName}
        />
      </div>
    </div>
  )
})

const TextAlignButtonField = observer(({ prop, label, action, value, options, autoName }) => {
  const buttonOptions = options.map((option) => (
    <li key={option.value} className={`${option.value == value ? 'active' : ''} ${option.value}`}>
      <button type="button" onClick={action.bind(null, option.value)} data-qa-hook={`${autoName}.${option.value}`}>
        <span className={`sprite hierarchy ${option.value}`} />
      </button>
    </li>
  ))
  return (
    <div className={`form-row button-group ${prop}`}>
      <div className="key fit">{label}</div>
      <div className="value fill">
        <ul className="text-align" data-qa-hook={autoName}>{buttonOptions}</ul>
      </div>
    </div>
  )
})

const ListField = observer(({ listAsset: list }) => {
  const { children } = list
  const items = children.map(({ objectId, text: { value: { string } } }) =>
    [objectId, inputTextFrom(string)])
  const field =
    <TextItemField
      addChildFn={() => addListItem(list)}
      addLabel="New List Item"
      autoName="field.items.list"
      items={items}
      removeByIdFn={(id) => {
        const listItem = children.find(li => id === li.objectId)
        if (listItem == null) return
        else removeChildAsset(list, listItem)
      }}
      setValueByIdFn={(id, next) => {
        const child = children.find(li => id === li.objectId)
        if (child == null) return
        const text = child.text
        const expr = expressionTextFrom(next)
        if (expr === text.value.string) return
        else updateDefaultText(child, expr)
      }}
    />
  return field
})

const MenuGroupItemField = ({ index, item, mediaList, setText, setFlaggedCond, setFlaggedVal, setVisitedCond, setVisitedVal, }) => {
  return (
    <div className="menu-group-item">
      <p className="menu-group-item-name" title={item.name || 'Untitled Menu Group Item'}>
        <span className="sprite details button" style={{ marginRight: '5px', verticalAlign: 'bottom' }}/>
        {item.name || 'Untitled Menu Group Item'}
      </p>
      <TextOptionDefault
        key={item.objectId}
        text={item.text}
        valSetter={setText}
        autoName={`menu-group-item-${index}.text`}
      />
      <MediaOption
        key={item.visitedMedia.objectId}
        items={mediaList}
        option={item.visitedMedia}
        expSetter={setVisitedCond}
        valSetter={setVisitedVal}
        label={"Visited"}
        autoName={`menu-group-item-${index}.visited`}>
      </MediaOption>
      <MediaOption
        key={item.flaggedMedia.objectId}
        items={mediaList}
        option={item.flaggedMedia}
        expSetter={setFlaggedCond}
        valSetter={setFlaggedVal}
        label={"Flagged"}
        autoName={`menu-group-item-${index}.flagged`}>
      </MediaOption>
    </div>
  )
}

const MenuGroupItems = observer(({ mediaList, menuItems }) => {
  const menuItemEnd = menuItems.length
  const mediaListEnd = MediaList.length

  const selectableMedia = mediaList.map(raw => ({ value: raw.objectId, label: raw.fileName }))

  const menuItemFields = menuItems.map((item, i) => {
    const setDefaultText = (next) => {
      const nextVal = expressionTextFrom(next)
      nextVal === item.text.string ? void 0 : updateDefaultText(item, nextVal)
    }
    const setFlaggedCondition = next => {
      updateOptionCondition(item.flaggedMedia, next)
    }
    const setFlaggedMedia = e => {
      const mediaId = e.value
      updateOptionValue(item.flaggedMedia, mediaId ? findMedia(mediaList, mediaId) : e)
    }
    const setVisitedCondition = next => {
      updateOptionCondition(item.visitedMedia, next)
    }
    const setVisitedMedia = e => {
      const mediaId = e.value
      updateOptionValue(item.visitedMedia, mediaId ? findMedia(mediaList, mediaId) : e)
    }
    const itemField =
      <MenuGroupItemField
        key={item.objectId}
        item={item}
        index={i}
        mediaList={selectableMedia}
        setFlaggedCond={setFlaggedCondition}
        setFlaggedVal={setFlaggedMedia}
        setVisitedCond={setVisitedCondition}
        setVisitedVal={setVisitedMedia}
        setText={setDefaultText}
      />

      return itemField
  })
  return (<React.Fragment>{menuItemFields}</React.Fragment>)
})

const MenuGroupField = observer(({ mediaList, menuGroup }) => {
  const menuItems = menuGroup.children

  const onClick = () => {
    addMenuGroupItem(menuGroup)
  }
  const menuGroupItems =
    <MenuGroupItems mediaList={mediaList} menuItems={menuItems} />

  return (
    <div className="media-options">
      <button type="button" className="add-connection no-pad spacer" onClick={onClick}>
        <i className="icon add-circle"/>New Menu Group Item
      </button>
      {menuGroupItems}
    </div>
  )
})


// Extension of NumberField
const ZIndexField = observer(({ prop, label, value, placeholder, autoName }) => {
  return (
    <div className={`form-row number-field ${prop}`}>
      <div className="key">{label}</div>
      <div className="value">
        <Input type="number" placeholder={placeholder} value={value} disabled={true} autoName={autoName}/>
      </div>
    </div>
  )
})

const MediaOptions = observer(({ mediaList, asset, autoName }) => {
  const { objectId, Type } = asset
  const type = Type === 'Embed' ? 'canvas' : Type.toLowerCase()
  const defaultProp = type + 'Media'
  const optionsProp = type + 'MediaOptions'
  const defaultVal = asset[defaultProp]
  const mediaOptions = asset[optionsProp]

  const setDefault = (e) => updateTemplatedProp(defaultProp, e ? findMedia(mediaList, e.value) : e, asset)
  const newOption = (e) => {
    e.stopPropagation()
    addMediaOption(asset, type)
  }

  // Map mediaList for Dropdown options
  const items = mediaList.map((m) => ({ value: m.objectId, label: m.fileName }))

  const options = mediaOptions.map((option, i) => {
    const removeMsg = `Are you sure you want to remove the option pointing to ${(option.value &&
      option.value.fileName) ||
      '...er, nothing at the moment'}?`
    const expSetter = (next) => updateOptionCondition(option, next)
    const valSetter = (e) => updateOptionValue(option, e ? findMedia(mediaList, e.value) : e)
    const remove = (e) => {
      e.stopPropagation()
      if (window.confirm(removeMsg)) {
        removeMediaOption(asset, option)
      }
    }
    return (
      <MediaOption
        key={option.condition.objectId}
        index={i}
        items={items}
        option={option}
        expSetter={expSetter}
        valSetter={valSetter}
        remover={remove}
        autoName={`${autoName}.${i}`}
      />
    )
  })
  options.push(
    <MediaOptionDefault
      key={objectId}
      index={options.length + 1}
      items={items}
      value={defaultVal}
      valSetter={setDefault}
      autoName={`${autoName}.default`}
    />
  )
  return (
    <div className="media-options">
      <button type="button" className="add-connection no-pad spacer" onClick={newOption} data-qa-hook={`${autoName}.new`}>
        <i className="icon add-circle" />Option
      </button>
      {options}
    </div>
  )
})

const MediaOptionDefault = observer(({ index, items, value, valSetter, label, autoName }) => {
  const dataAttrs = { "data-qa-hook": autoName }
  return (
    <div className="connection flex-row">
      {index  ? <div className="index fit">{index || '1'}</div> : null }
      <div className="statement else fill">
      {index && index > 1 ?
        <div className="form-row flex-start">
          <div className="key">Else</div>
          <div className="value" />
        </div>
        : null}
        <div className="form-row center">
          <div className="key">{ label ?? "Display" }</div>
          <div className="value" title={value ? value.fileName : null}>
            <Select
              options={items}
              value={value ? value.objectId : null}
              onChange={valSetter}
              clearable={true}
              autoName={autoName}
              inputProps={dataAttrs}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

const MediaOption = observer(({ index, items, option, expSetter, valSetter, remover, autoName, label }) => {
  const dataAttrs = { "data-qa-hook": `${autoName}.value` }
  return (
    <div className="connection flex-row">
      { index ? <div className="index fit">{index}</div> : null }
      <div className="statement fill">
      {remover ?
        <button className="remove-connection" type="button" onClick={remover}>
          <i className="icon remove" />
        </button> :
         null
      }
        <div className="form-row flex-start">
          <div className="key">If</div>
          <div className="value">
            <TextArea placeholder="Condition" value={option.condition.string} onSave={expSetter} autoName={`${autoName}.condition`}/>
          </div>
        </div>
        <div className="form-row center">
          <div className="key">{label ?? "Display"}</div>
          <div className="value" title={option.value ? option.value.fileName : null}>
            <Select
              options={items}
              value={option.value ? option.value.objectId : null}
              onChange={valSetter}
              clearable={true}
              inputProps={dataAttrs}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

const TextOptions = observer(({ asset, autoName }) => {
  const { objectId, text, textOptions } = asset
  const setDefaultVal = (next) => {
    const nextVal = expressionTextFrom(next)
    nextVal === text.string ? void 0 : updateDefaultText(asset, nextVal)
  }
  const newOption = (e) => {
    e.stopPropagation()
    addTextOption(asset)
  }
  const options = textOptions.map((option, i) => {
    const removeMsg = `Are you sure you want to remove the option for this text: ${(option.condition &&
      option.condition.string) ||
      'No text entered'}?`
    const expSetter = (next) => updateOptionCondition(option, next)
    const valSetter = (next) => updateOptionText(option, expressionTextFrom(next))
    const remove = () => {
      if (window.confirm(removeMsg)) removeTextOption(asset, option)
    }
    return (
      <TextOption
        key={option.condition.objectId}
        index={i}
        option={option}
        expSetter={expSetter}
        valSetter={valSetter}
        remover={remove}
        autoName={`${autoName}.${i}`}
      />
    )
  })
  // Add the default option
  options.push(
    <TextOptionDefault
      key={objectId}
      index={options.length + 1}
      text={text}
      valSetter={setDefaultVal}
      autoName={`${autoName}.default`}
    />
  )
  return (
    <div className="options-type">
      <div className="text-options">
        {options}
        <button type="button" className="add-connection no-pad spacer" onClick={newOption} data-qa-hook={`${autoName}.new`}>
          <i className="icon add-circle" />Add Conditional Option
        </button>
      </div>
    </div>
  )
})

const TextOption = observer(({ index, option, expSetter, valSetter, remover, autoName }) => {
  const text = option.value
  return (
    <div className="connection flex-row">
      {index ? <div className="index fit">{index + 1}</div> : null }
      <div className="statement fill">
        <button className="remove-connection" type="button" onClick={remover}>
          <strong>Remove</strong> <i className="icon remove" />
        </button>
        <div className="form-row flex-start">
          <div className="key">If</div>
          <div className="value">
            <TextArea
              placeholder="Condition"
              value={option.condition.string}
              title={option.condition.string}
              onSave={expSetter}
              autoName={`${autoName}.condition`}
            />
          </div>
        </div>
        <div className="form-row center">
          <div className="key">Then display</div>
          <div className="value">
            <TextArea
              placeholder="Text"
              value={text && inputTextFrom(text.value.string || '')}
              title={text && unescapeString(text.value.string || '')}
              onSave={valSetter}
              autoName={`${autoName}.value.visible`}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

const TextOptionDefault = observer(({ index, text, valSetter, autoName }) => {
  return (
    <div className="connection flex-row">
      { index ? <div className="index fit">{'D'}</div> : null }
      <div className="statement else fill">
        {index && index > 1 ? (
          <div className="form-row flex-start">
            <div className="key">Else default to</div>
            <div className="value" />
          </div>
        ) : null}
        <div className="form-row center">
          <div className="key">Display</div>
          <div className="value">
            <TextArea
              placeholder="Text"
              value={text && inputTextFrom(text.value.string || '')}
              title={text && unescapeString(text.value.string || '')}
              onSave={valSetter}
              autoName={`${autoName}.visible`}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

const TemplateOptions = observer(({ templateList, isAdmin, template, target, onNew, onUpdate, onChange, autoName }) => {
  const options = templateList.map(t =>
    ({ value: t.objectId, label: t.name }))
  const selectedOpt = options.find(({ value }) =>
    template && template.objectId === value) || undefined
  const hasTemplate = template ?
    true :
    false
  const saveLabel = hasTemplate ?
    "Update" :
    "Create"
  const saveFn = hasTemplate ?
    onUpdate :
    onNew
  const assignFn = (opt) => 
    onChange(opt && opt.value, target)
  const dataAttrs = {
    "data-qa-hook": `${autoName}.select`
  }
  const adminPanel = isAdmin ?
    <React.Fragment>
      <button type="button" data-qa-hook={`${autoName}.update`} onClick={saveFn} style={{ border: "1px solid red", marginBottom: "1em", width: "50%" }}>{saveLabel}</button>
      <button type="button" data-qa-hook={`${autoName}.saveAs`} onClick={onNew} style={{ border: "1px solid green", width: "50%" }}>Save As...</button>
    </React.Fragment>
    :
    null

  const vDom =
    <div className="media-options template-options">
      <div className={`form-row dropdown-field template`}>
        <div className="key fit">Template</div>
        <div className="value fill">
          <Select
            options={options}
            value={selectedOpt}
            onChange={assignFn}
            clearable={true}
            searchable={true}
            placeholder="Click here to select template"
            noResultsText="No Matching Template Found"
            inputProps={dataAttrs}   
            clearValueText="Remove Template"
          />
        </div>
      </div>
      {adminPanel}
    </div>

  return vDom
})

const TextItemField = observer(({ addChildFn, addLabel, autoName, items, removeByIdFn, setValueByIdFn }) => {
  const onClickAdd = addChildFn instanceof Function ?
    e => { e.stopPropagation(); addChildFn() } :
    null
  const addMember = onClickAdd instanceof Function ?
    <button type="button" className="add-connection no-pad spacer" onClick={onClickAdd} data-qa-hook={`${autoName}.add`}>
      <i className="icon add-circle"/>
      {addLabel}
    </button> :
    null

  const members = items.map(([id, value], idx) => {
    const testId = `${autoName}.item.${idx}`
    const input = 
      <div className="connection flex-row" data-qa-hook={testId}>
        <div className="index fit">
          {idx + 1}
        </div>
        <div className="statement fill">
          <button className="remove-connection" type="button" onClick={() => removeByIdFn(id)} data-qa-hook={`${testId}.remove`}>
            <strong>Remove</strong> <i className="icon remove" />
          </button>
          <div className="form-row center" style={{ color: '#fff' }}>
              <TextArea
                autoName={`${testId}.input`}
                onSave={(nextVal) => setValueByIdFn(id, nextVal)}
                placeholder="Button Text"
                title={value && unescapeString(value)}
                value={value && inputTextFrom(value)}
              />
          </div>
        </div>
      </div>

    return input
  })

  const field =
    <div className="options-type" data-qa-hook={autoName}>
      <div className="text-options">
        {addMember}
        {members}
      </div>
    </div>

    return field
})

const ButtonGroupField = observer(({ buttonGroup }) => {
  const { children } = buttonGroup
  const canAddBtn = children.length < 3
  const addButton = canAddBtn ? () => addButtonGroupItem(buttonGroup) : undefined
  const childValues = children.map(({ objectId, text: { value: { string } } }) =>
    [ objectId, string ])
  const field =
    <TextItemField
      addChildFn={addButton}
      addLabel="Add Button"
      autoName="field.items.buttonGroup"
      items={childValues}
      removeByIdFn={(id) => {
        const button = children.find(btn => id === btn.objectId)
        if (button == null) return
        else removeButtonGroupItem(buttonGroup, button)
      }}
      setValueByIdFn={(id, next) => {
        const child = children.find(btn => id === btn.objectId)
        if (child == null) return
        const text = child.text
        const expr = expressionTextFrom(next)
        if (expr === text.value.string) return
        else updateDefaultText(child, expr)
      }}
    />
  return field
})

const SequenceDetails = observer(({ sequence }) => {
  const updateName = (val) => updateProp('name', val, sequence)
  const updateDuration = (val) => {
    let newDuration = parseInt(val)
    setSequenceDuration(sequence, newDuration)
  }

  return (
    <div className="details sequence">
      <h3 className="card-title form-row">
        <span className="sprite details sequence" />
        <Input
          placeholder="Sequence Name"
          type="text"
          value={sequence.name}
          title={sequence.name}
          onSave={updateName}
        />
      </h3>
      <div className="card-subsection">
        <div className="form-row">
          <div className="fit key">Duration</div>
          <div className="fill value">
            <Input
              type="number"
              name="durationControl"
              comparative={MAX_SEQUENCE_DURATION}
              min={1}
              placeholder="1"
              value={sequence.duration}
              onSave={updateDuration}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

const VIEW_MAP = {
  backgroundColor: {
    autoName: `layoutDetails.background.color`,
    component: ColorField,
    label: 'Color',
    action: setColor
  },
  boxShadowX: {
    autoName: `layoutDetails.background.boxShadowX`,
    component: NumberField,
    label: 'X',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  boxShadowY: {
    autoName: `layoutDetails.background.boxShadowY`,
    component: NumberField,
    label: 'Y',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  boxShadowBlur: {
    autoName: `layoutDetails.background.boxShadowBlur`,
    component: NumberField,
    label: 'Blur',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  boxShadowSpread: {
    autoName: `layoutDetails.background.boxShadowSpread`,
    component: NumberField,
    label: 'Spread',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  boxShadowColor: {
    autoName: `layoutDetails.background.boxShadowColor`,
    component: ColorField,
    label: 'Shadow',
    action: setColor
  },
  borderColor: {
    autoName: `layoutDetails.border.borderColor`,
    component: ColorField,
    label: 'Color',
    action: setColor
  },
  borderRadius: {
    autoName: `layoutDetails.border.borderRadius`,
    component: NumberField,
    label: 'Radius',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 0
  },
  borderStyle: {
    autoName: `layoutDetails.border.borderStyle`,
    component: DropdownField,
    label: 'Style',
    action: setDropdown,
    options: BORDER_STYLE_OPTIONS,
    clearable: false
  },
  borderThickness: {
    autoName: `layoutDetails.border.borderThickness`,
    component: NumberField,
    label: 'Width',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 0
  },
  canvasMedia: {
    autoName: `layoutDetails.option.canvas`,
    component: MediaOptions
  },
  checked: {
    autoName: `layoutDetails.form.checked`,
    component: ExpressionField,
    label: 'Checked If',
    action: setExpression,
    placeholder: 'Expression'
  },
  disabled: {
    autoName: `layoutDetails.form.disabled`,
    component: ExpressionField,
    label: 'Disabled If',
    action: setExpression,
    placeholder: 'Expression'
  },
  fontColor: {
    autoName: `layoutDetails.character.fontColor`,
    component: ColorField,
    label: 'Color',
    action: setColor
  },
  fontFamily: {
    autoName: `layoutDetails.character.fontFamily`,
    component: DropdownField,
    label: 'Font',
    action: setDropdown,
    options: FONT_FAMILY_OPTIONS,
    clearable: false
  },
  fontSize: {
    autoName: `layoutDetails.character.fontSize`,
    component: NumberField,
    label: 'Size',
    action: setNumber,
    min: 1,
    max: 'none',
    step: 1,
    placeholder: ''
  },
  fontStyle: {
    autoName: `layoutDetails.form.fontStyle`,
    component: DropdownField,
    label: 'Style',
    action: setDropdown,
    options: FONT_STYLE_OPTIONS,
    clearable: false
  },
  fontWeight: {
    autoName: `layoutDetails.character.fontWeight`,
    component: NumberField,
    label: 'Weight',
    action: setNumber,
    min: 100,
    max: 900,
    step: 100,
    placeholder: ''
  },
  textShadowColor: {
    autoName: `layoutDetails.character.textShadowColor`,
    component: ColorField,
    label: 'Shadow',
    action: setColor
  },
  textShadowX: {
    autoName: `layoutDetails.character.textShadowX`,
    component: NumberField,
    label: 'X',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  textShadowY: {
    autoName: `layoutDetails.character.textShadowY`,
    component: NumberField,
    label: 'Y',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  textShadowBlur: {
    autoName: `layoutDetails.character.textShadowBlur`,
    component: NumberField,
    label: 'Blur',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  textShadowSpread: {
    autoName: `layoutDetails.character.textShadowSpread`,
    component: NumberField,
    label: 'Spread',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: '0'
  },
  height: {
    autoName: `layoutDetails.rectangle.height`,
    component: NumberField,
    label: 'Height',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 1
  },
  htmlFor: {
    autoName: `layoutDetails.form.htmlFor`,
    component: DropdownField,
    label: 'Label For',
    action: setDropdown,
    options: formAssetOptions,
    clearable: true
  },
  listStylePosition: {
    autoName: `layoutDetails.paragraph.listStylePosition`,
    component: DropdownField,
    label: 'List Style Position',
    action: setDropdown,
    options: LIST_STYLE_POSITION_OPTIONS,
    clearable: false
  },
  listStyleType: {
    autoName: `layoutDetails.paragraph.listStyleType`,
    component: DropdownField,
    label: 'List Style Type',
    action: setDropdown,
    options: LIST_STYLE_OPTIONS,
    clearable: false
  },
  max: {
    autoName: `layoutDetails.form.max`,
    component: NumberField,
    label: 'Max',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 1
  },
  min: {
    autoName: `layoutDetails.form.min`,
    component: NumberField,
    label: 'Min',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 1
  },
  placeholder: {
    autoName: `layoutDetails.form.placeholder`,
    component: TextField,
    label: 'Placeholder',
    action: setText,
    placeholder: ''
  },
  sliderTheme: {
    autoName: `layoutDetails.form.sliderStyle`,
    component: DropdownField,
    label: 'Theme',
    action: setDropdown,
    options: SLIDER_THEME_OPTIONS,
    clearable: false
  },
  spriteMedia: {
    autoName: `layoutDetails.option.sprite`,
    component: MediaOptions
  },
  step: {
    autoName: `layoutDetails.form.step`,
    component: NumberField,
    label: 'Step',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 1
  },
  tabIndex: {
    autoName: `layoutDetails.rectangle.tabIndex`,
    component: TextField,
    label: 'Tab Index',
    action: setTabIndex,
    placeholder: 'None'
  },
  text: {
    autoName: `layoutDetails.option.text`,
    component: TextOptions
  },
  textAlign: {
    autoName: `layoutDetails.paragraph.textAlign`,
    component: TextAlignButtonField,
    label: 'Text Align',
    action: setButtonGroup,
    options: TEXT_ALIGN_OPTIONS
  },
  lineHeight: {
    autoName: `layoutDetails.paragraph.lineHeight`,
    component: NumberField,
    label: 'Line Spacing',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 1
  },
  letterSpacing: {
    autoName: `layoutDetails.character.letterSpacing`,
    component: NumberField,
    label: 'Letter Spacing',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 1
  },
  title: {
    autoName: `layoutDetails.form.title`,
    component: TextField,
    label: 'Tooltip',
    action: setText,
    placeholder: 'None'
  },
  value: {
    autoName: `layoutDetails.form.value`,
    component: ExpressionField,
    label: 'Value',
    action: setExpression,
    placeholder: 'Expression'
  },
  videoMedia: {
    autoName: `layoutDetails.option.video`,
    component: MediaOptions
  },
  width: {
    autoName: `layoutDetails.rectangle.width`,
    component: NumberField,
    label: 'Width',
    action: setNumber,
    min: 0,
    max: 'none',
    step: 1,
    placeholder: 1
  },
  template: {
    component: TemplateOptions
  },
  labeledBy: {
    autoName: `layoutDetails.form.labeledBy`,
    component: DropdownField,
    label: "Labeled by",
    action: setDropdown,
    options: labeledByOptions,
    clearable: true,
  },
  /*,
  'zIndex': {
    component: ZIndexField,
    label: 'Z',
    action: setNumber,
    placeholder: 0
  }*/
}

const DetailsSection = observer(({ title, expanded: isExpanded, toggle, components, state }) => {
  if (!components.length) return null
  return (
    <div>
      {title && title.length ? (
        <h4 className="card-subsection-title">
          {title}
          <div className="actions">
            <button
              type="button"
              className={`chevron ${isExpanded ? '' : 'collapsed'}`}
              onClick={toggle}>
              <i className="sprite script chevron" />
            </button>
          </div>
        </h4>
      ) : null}
      <div className={`card-subsection ${isExpanded ? 'expanded' : 'collapsed'}`}>{components}</div>
    </div>
  )
})

class LayoutDetailsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.styleState = "normal"
  }
  toggleOptions = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('option')
  }
  toggleForm = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('form')
  }
  toggleCharacter = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('character')
  }
  toggleParagraph = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('paragraph')
  }
  toggleBackground = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('background')
  }
  toggleBorder = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('border')
  }
  toggleChildren = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('children')
  }
  toggleTemplate = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('template')
  }
  toggleBase = () => {
    const { state } = this.props
    const { details } = state.layout
    details.expanded.toggleSection('base')
  }
  setStyleState = (styleState) => {
    // Some inline input validation
    styleState =
      "normal" === styleState ? styleState :
      "hover" === styleState ? styleState :
      "active" === styleState ? styleState :
      "disabled" === styleState ? styleState :
      this.styleState
    
    this.styleState = styleState
  }
  render() {
    const { styleState, props } = this
    const { state, sequence } = props
    const { selections, details } = state.layout
    const media = state.media
    const assets = selections.assets

    const isSingular = assets.length === 1
    const firstAsset = assets.length ? assets[0] : null
    const isInputSelected = assets.reduce((allAreInputs, asset) => allAreInputs ? isInput(firstAsset) : false, true)
    const canEditPseudoState = isInputSelected && "normal" !== styleState

    // Return SequenceDetails if no Assets selected
    if (assets.length === 0) return <SequenceDetails sequence={sequence} media={media} />

    // Grab a list of Form control assets in the Sequence for HtmlFor
    formAssetOptions.length = 0 // clear this out each render
    labeledByOptions.length = 0
    if (isSingular) {
      if (firstAsset.Type === 'Container') {
        walkTree((n) => {
          if (isFormControl(n)) formAssetOptions.push({ value: n.objectId, label: n.name }) },
          sequence.stage)
      }

      if ((firstAsset.Type === 'MenuGroup' || firstAsset.Type === 'ButtonGroup')) {
        walkTree(a => {
          const canLabelGroup = a.Type === 'Title' || a.Type === 'Paragraph'
          if (canLabelGroup) labeledByOptions.push({ value: a.objectId, label: a.name }) },
          sequence.stage)
      }
    }


    // Arrays to hold components
    let baseComponents = []
    let formComponents = []
    let characterComponents = []
    let paragraphComponents = []
    let backgroundComponents = []
    let borderComponents = []
    let templateComponents = []
    let childrenComponents = []
    let optionComponents = [] // Holds Options for Text, SpriteMedia, VideoMedia, CanvasMedia
    let optionHeader = ''

    // Loop through editable/shareable props and create a value map
    let valueMap = {}
    // Merge editable/shareable props into array
    intersect(PROPERTIES, assets.reduce((props, asset) => {
      let keys
      const aType = asset.Type
      // Maybe defining iterators would be cleaner
      if (aType === 'ButtonGroup' || aType === 'MenuGroup') {
        keys = Object.keys(asset).filter(prop => prop.match(/height|width|tabIndex/) ? false : true)
      } else {
        keys = Object.keys(asset)
      }
      return props.length ? intersect(props, keys) : keys
    }, []))
    // Sort props in our predefined order
    .sort((a, b) => PROPERTIES.indexOf(a) - PROPERTIES.indexOf(b))
    .forEach((prop) => {
      if (canEditPseudoState && !contains(PSEUDO_PROPS, prop)) {
        return
      }

      const isBase = BASE_PROPS.includes(prop)
      const isOption = OPTION_PROPS.includes(prop)
      const isForm = FORM_PROPS.includes(prop)
      const isCharacter = CHARACTER_PROPS.includes(prop)
      const isParagraph = PARAGRAPH_PROPS.includes(prop)
      const isBackground = BACKGROUND_PROPS.includes(prop)
      const isBorder = BORDER_PROPS.includes(prop)
      const isHeight = 'height' === prop
      const isTemplate = 'template' === prop
      let isTextHeight = false

      // Merge multiple asset prop values
      assets.map(a =>
        canEditPseudoState ?
          a.template ?
            a.template.properties.states[styleState] :
            a.states[styleState] :
          a.template ?
            TEMPLATE_PROPS_EXCLUDE[prop] ?
              a :
              a.template.properties :
            a
      ).forEach((asset) => {
        if (isOption) return // Just ignore option values
        var currValue = asset[prop]
        isTextHeight =
          isHeight &&
          ('Text' === asset.Type ||
            'ListItem' === asset.Type ||
              'Title' === asset.Type)

        if (isArray(currValue)) {
          if (!compare(valueMap[prop], currValue)) valueMap[prop] = currValue
        } else {
          if (valueMap[prop] !== currValue) valueMap[prop] = currValue
        }
      })

      // Skip height for Text/ListItem assets
      if (isTextHeight) return
      // Special case Option props
      if (isOption) {
        if (!isSingular) return
        const mediaList = this.props[prop] // retrieve the media list if present
        const { component, ...componentProps } = VIEW_MAP[prop]
        const Component = React.createElement(
          component,
          { key: prop, mediaList: mediaList, asset: firstAsset, state: state, ...componentProps },
          null
        )
        optionHeader = OPTION_HEADERS[prop]
        optionComponents.push(Component)
      } else if (isTemplate) {
        if (!isSingular) return
        templateComponents.push(<TemplateOptions autoName= "layoutDetails.template" templateList={media.assetTemplateList} onChange={media.assignTemplate} onNew={media.dialogNewTemplate} onUpdate={media.dialogUpdateTemplate} isAdmin={media.isTemplateUserPrivledged()} template={firstAsset.template} target={firstAsset} />)
      } else {
        // Setup our reusable components with the correct data
        const value = valueMap[prop]
        if (!VIEW_MAP.hasOwnProperty(prop)) {
          console.warn(`Asset prop "${prop}" was skipped`)
          return
        }
        const { component, action, ...componentProps } = VIEW_MAP[prop]
        const boundAction = action.bind(null, canEditPseudoState ? styleState : "normal", prop, assets)
        const Component = React.createElement(
          component,
          {
            key: prop,
            prop: `${styleState}/${prop}`,
            value: value,
            action: boundAction,
            state: state,
            ...componentProps
          },
          null
        )
        if (isBase) {
          baseComponents.push(Component)
          return
        }
        if (isForm) {
          formComponents.push(Component)
          return
        }
        if (isCharacter) {
          characterComponents.push(Component)
          return
        }
        if (isParagraph) {
          paragraphComponents.push(Component)
          return
        }
        if (isBackground) {
          backgroundComponents.push(Component)
          return
        }
        if (isBorder) {
          borderComponents.push(Component)
          return
        }
        if (isTemplate) {
          templateComponents.push(Component)
        }
      }
    })

    const updateName = (val) => updateProp('name', val, firstAsset)

    // Add List Items to lists through the details panel
    if (isSingular && firstAsset.Type === 'List') {
      childrenComponents.push(<ListField key={firstAsset.objectId} listAsset={firstAsset} />)
    }
    if (isSingular && firstAsset.Type === 'ButtonGroup') {
      childrenComponents.push(<ButtonGroupField key={`details-buttongroup-${firstAsset.objectId}`} buttonGroup={firstAsset} />)
    }

    if (isSingular && firstAsset.Type === 'MenuGroup') {
      childrenComponents.push(<MenuGroupField key={`details-menugroup-${firstAsset.objectId}`} mediaList={this.props.spriteMedia} menuGroup={firstAsset} />)
    }

    return (
      <div className="details sequence">
        <h3 className="card-title form-row">
          {isSingular ? <span className={`sprite details ${firstAsset.Type.toLowerCase()}`} /> : ''}
          {isSingular ? (
            <Input
              type="text"
              placeholder={firstAsset.Type === 'Stage' ? `Stage` : `Untitled ${firstAsset.Type}`}
              disabled={firstAsset.Type === 'Stage' ? true : false}
              value={firstAsset.name}
              onSave={updateName}
              autoName="layoutDetails.name"
            />
          ) : (
            'Selected Assets'
          )}
        </h3>
        { isSingular ? <DetailsSection title="Template" expanded={details.expanded.template} components={templateComponents} toggle={this.toggleTemplate}/> : null }
        <DetailsSection title="Rectangle" components={baseComponents} expanded={details.expanded.base} toggle={this.toggleBase}/>
        { isInputSelected ? (
          <div className="card-subsection pseudostate-select">
            <button data-qa-hook="layoutDetails.state.normal" className={"normal" === styleState ? "selected" : ""} onClick={() => this.setStyleState("normal")}>Normal</button>
            <button data-qa-hook="layoutDetails.state.hover" className={"hover" === styleState ? "selected" : ""} onClick={() => this.setStyleState("hover")}>Hover</button>
            <button data-qa-hook="layoutDetails.state.active"className={"active" === styleState ? "selected" : ""} onClick={() => this.setStyleState("active")}>Pressed</button>
            <button data-qa-hook="layoutDetails.state.disabled"className={"disabled" === styleState ? "selected" : ""} onClick={() => this.setStyleState("disabled")}>Disabled</button>
          </div>) :
          null }
        <DetailsSection
          title={optionHeader}
          expanded={details.expanded.option}
          toggle={this.toggleOptions}
          components={optionComponents}
        />
        <DetailsSection
          title="Forms"
          expanded={details.expanded.form}
          toggle={this.toggleForm}
          components={formComponents}
        />
        <DetailsSection
          title="Character"
          expanded={details.expanded.character}
          toggle={this.toggleCharacter}
          components={characterComponents}
          state={state}
        />
        <DetailsSection
          title="Paragraph"
          expanded={details.expanded.paragraph}
          toggle={this.toggleParagraph}
          components={paragraphComponents}
          state={state}
        />
        <DetailsSection
          title="Background"
          expanded={details.expanded.background}
          toggle={this.toggleBackground}
          components={backgroundComponents}
          state={state}
        />
        <DetailsSection
          title="Border"
          expanded={details.expanded.border}
          toggle={this.toggleBorder}
          components={borderComponents}
          state={state}
        />
        <DetailsSection
          title="List Items"
          expanded={details.expanded.children}
          toggle={this.toggleChildren}
          components={childrenComponents}
        />
        {/*<DetailsSection title="Leftovers" expanded={ true } toggle={ () => null } components={ leftoverComponents } />*/}
      </div>
    )
  }
}

decorate(LayoutDetailsComponent, {
  styleState: observable,
})

const LayoutDetails = observer(LayoutDetailsComponent)

export default LayoutDetails
