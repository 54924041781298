import React from 'react'
import PropTypes from 'prop-types'
import { enumOf } from '../../utils'
import './manager-section.scss'
import './manager-header.scss'

// TODO Consolidate w/ editionmanager-editiondetails-header-colorbars.scss
export const HEADER_GRADIENT = enumOf(
  "GREEN_BLUE",
  "RED_BLUE",
  "LIGHTBLUE_BLUE",
  "GOLD_BLUE",
)

const HEADER_GRADIENT_CLASS = {
  GREEN_BLUE: "manager-header__colorbar--greenblue",
  RED_BLUE: "manager-header__colorbar--redblue",
  LIGHTBLUE_BLUE: "manager-header__colorbar--lightblueblue",
  GOLD_BLUE: "manager-header__colorbar--goldblue",
}

ManagerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  gradient: PropTypes.oneOf(Object.keys(HEADER_GRADIENT)).isRequired,
  style: PropTypes.object,
}

export function ManagerHeader({ children, title, gradient, style }) {
  const vDom =
    <header className="manager-section manager-header" style={style} >
      <div className="manager-header__title" data-qa-hook="title.capture-queue">
        {title}
      </div>
      <div className="manager-header__actions">
        {children}
      </div>
      <div className={`edition-colorbar ${HEADER_GRADIENT_CLASS[gradient]}`}>
      </div>
    </header>
  return vDom
}

