import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import ContentEditable from '../../components/Editable'
import ScriptMenu from './Menu'
import Sequence from './Sequence'
import { contains } from '../../utils'
import { ONDROP_WHITELIST } from '../../constants'
import { updateProp } from '../../actions/edition'
import { duplicate } from '../../actions/edition'
import { toggleProp } from '../../actions/edition'
import { insertSequenceInChapter } from '../../actions/edition'
import { moveSequence } from '../../actions/edition'
import { duplicateChapter } from '../../actions/edition'
import { moveChapter } from '../../actions/edition'
import { removeChapter } from '../../actions/edition'
import { insertChapter } from '../../actions/edition'
import { parentOf } from '../../actions/edition'

class ChapterComponent extends React.Component {
  editableEnter = (e) => {
    this.hoverOver = true
  }
  onDragStart = (e) => {
    const { state, chapter } = this.props
    const { global: globalState } = state
    e.stopPropagation()
    e.dataTransfer.setData('text/chapter', chapter.objectId)
    e.dataTransfer.setData('text/panel', 'Script')
    globalState.draggingFromScript = true
    globalState.dragging([chapter])
  }
  onDragOver = (e) => {
    e.stopPropagation()
    let isSequence = contains(e.dataTransfer.types, 'text/sequence')
    let isChapter = contains(e.dataTransfer.types, 'text/chapter')
    if (isSequence || isChapter) {
      e.preventDefault()
      this.dragOver = true
    }
  }
  onDragLeave = (e) => {
    this.dragOver = false
  }
  onDrop = (e) => {
    const { state, chapter, index } = this.props
    const { global: globalState } = state
    const sequences = chapter.sequences
    const edition = parentOf(chapter)
    e.stopPropagation()
    this.dragOver = false
    let isSequence = contains(e.dataTransfer.types, 'text/sequence')
    let isChapter = contains(e.dataTransfer.types, 'text/chapter')
    const droppedItems = globalState.dropped()
    const droppedFrom = e.dataTransfer.getData('text/panel')
    const isValidDrop = ONDROP_WHITELIST['Script'].includes(droppedFrom)
    if (!isValidDrop) return
    if (isSequence) {
      var s = droppedItems[0]
      moveSequence(chapter, s, sequences.length)
    }
    if (isChapter) {
      var c = droppedItems[0]
      moveChapter(edition, c, index)
    }
  }
  onClick = (e) => {
    const { state, chapter } = this.props
    const scriptState = state.script
    e.stopPropagation()
    scriptState.activeElement = chapter.objectId
  }
  editableOnKeyDown = (e) => {
    const { state, scriptObjects, chapter } = this.props
    const scriptState = state.script
    const isTab = e.keyCode === 9
    const isEnter = e.keyCode === 13
    const isShift = e.shiftKey
    // Tab
    if (isTab) {
      e.preventDefault()
      e.stopPropagation()
      const index = scriptObjects.indexOf(chapter.objectId)
      const mod = isShift ? -1 : 1
      scriptState.activeElement = scriptObjects[index + mod]
    }
    // Enter
    if (isEnter) {
      e.preventDefault()
      e.stopPropagation()
      if (!isShift) {
        const index = scriptObjects.indexOf(chapter.objectId)
        scriptState.activeElement = scriptObjects[index + 1]
      }
    }
  }
  editableOnBlur = (val) => {
    const { state, chapter } = this.props
    const scriptState = state.script
    this.updateName(val)
    scriptState.activeElement =
      scriptState.activeElement === chapter.objectId ? null : scriptState.activeElement
    this.hoverOver = false
  }
  updateName = (val) => {
    const { chapter } = this.props
    updateProp('name', val, chapter)
  }
  toggleExpand = () => {
    const { chapter } = this.props
    toggleProp('expanded', chapter.scriptState)
  }
  constructor(props) {
    super(props)
    this.dragOver = false
    this.hoverOver = false
  }
  render() {
    const { state, scriptObjects, chapter } = this.props
    const { structure: structureState, script: scriptState, ui } = state
    const { selections } = structureState

    const isSelected = selections.nodes.includes(chapter) ? true : false
    const isChildSelected = chapter.sequences.find(seq => selections.nodes.includes(seq)) ? true : false
    const hasFocus = scriptState.activeElement === chapter.objectId
    const expanded = chapter.scriptState.expanded
    const showOnlySelected = scriptState.showOnlySelected
    const isVisible = showOnlySelected ? (isSelected || isChildSelected) ? true : false : true

    const selectedClassName = isSelected ? ' selected' : ''
    const dragZoneClassName = this.dragOver ? ' over' : ''
    const focusClassName = hasFocus ? ' focus' : ''
    const visibleClassName = isVisible ? '' : ' only-selected'

    const className =
      `script-object chapter${visibleClassName}${selectedClassName}${dragZoneClassName}${focusClassName}`

    const clearSelected = () => {
      selections.clearSelection()
    }

    const isSelectedCheck = (el) => {
      isSelected ? ui.scrollControl.setScriptPanelSelectedEl(el) : null
    }

    const nameProps = {
      ref: 'editable',
      disabled: !this.hoverOver,
      html: chapter.name || '',
      placeholder: 'Chapter',
      onSave: this.updateName,
      onKeyDown: this.editableOnKeyDown,
      onBlur: this.editableOnBlur,
      onMouseEnter: this.editableEnter
    }

    const props = {
      className: className.trim(),
      draggable: true,
      onDragStart: this.onDragStart,
      onDragOver: this.onDragOver,
      onDrop: this.onDrop,
      onDragLeave: this.onDragLeave,
      onClick: this.onClick
    }

    const sequenceEls =
      chapter.sequences.map((sequence, i) => (
          <Sequence
            key={sequence.objectId}
            state={state}
            scriptObjects={scriptObjects}
            sequence={sequence}
            isParentSelected={ isSelected }
            showOnlySelected={ showOnlySelected }
            index={i}
          />
        ))

    return (
      <div {...props} id={chapter.objectId} ref={isSelectedCheck}>
        <div className="inner">
          <div className="flex">
            <div className="input">
              <i className="sprite widget chapter-script" title="Chapter"></i><ContentEditable {...nameProps} />
            </div>
          </div>
          <div className="actions">
              <button
                type="button"
                className={`chevron ${expanded ? '' : 'collapsed'}`}
                onClick={this.toggleExpand}>
                <i className="sprite script chevron" />
              </button>
            </div>
          <ChapterMenu object={chapter} clearSelected={clearSelected}/>
        </div>
        <div className={`sequence-list`}>{ expanded ? sequenceEls : null }</div>
      </div>
    )
  }
}

const ChapterMenu = observer(({ object: chapter, clearSelected }) => {
  const edition = parentOf(chapter) 
  const addSequence = (e) => {
    e.stopPropagation()
    insertSequenceInChapter(chapter)
  }
  const insertBefore = (e) => {
    e.stopPropagation()
    let position = edition.chapters.indexOf(chapter)
    insertChapter(edition, position)
  }
  const insertAfter = (e) => {
    e.stopPropagation()
    let position = edition.chapters.indexOf(chapter) + 1
    insertChapter(edition, position)
  }
  const remove = (e) => {
    e.stopPropagation()
    clearSelected()
    removeChapter(edition, chapter)
  }
  const duplicateChapterCb = () => duplicateChapter(chapter)
  return (
    <ScriptMenu>
      <ol>
        <li onMouseUp={addSequence}>Insert Sequence</li>
        <li className="divider" />
        <li onMouseUp={insertBefore}>Add Chapter Above</li>
        <li onMouseUp={insertAfter}>Add Chapter Below</li>
        <li className="divider" />
        <li onMouseUp={duplicateChapterCb}>Duplicate Chapter</li>
        <li className="divider" />
        <li onMouseUp={remove}>Remove Chapter</li>
      </ol>
    </ScriptMenu>
  )
})

decorate(ChapterComponent, {
  dragOver: observable,
  hoverOver: observable,
})

const Chapter = observer(ChapterComponent)

export default Chapter
