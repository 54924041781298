import { toJS } from "mobx"
import { types, getRoot } from 'mobx-state-tree'
import { Typography as TextUtils } from 'eplayer-core'
import { ListItemTemplate } from '../Template'
import { Layerable, Rectangle, Transformable, Typography, Visible, UI } from './shared/props'
import RawText from '../RawText'
import TextOption from '../TextOption'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'ListItem'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    template: types.maybeNull(types.reference(ListItemTemplate)),
    // TextOptions
    text: RawText,
    textOptions: types.array(TextOption),
    ...Layerable,
    ...Typography,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI
  })
  .views(assetViews)
  .actions(self => {
    function afterCreate() {
      const a = self
      const txt = a.text
      // [ 1, 1 ] are the default RawText dimensions for existing content yet to be
      // migrated to model version 1.5.1
      const shouldComputeMetrics =
        txt.width === 1 &&
        txt.height === 1

      if (!shouldComputeMetrics) {
        return
      }

      const e = getRoot(a)
      const vars = toJS(e.variables)

      const opts = a.textOptions
      const optsLen = opts.length

      // Can call Expression.fn as leaf nodes in state tree are instantiated and expressions compiled already
      // Passing Expr.compiledString creates edge cases at EOL that result in extra newlines owing to the extra
      // quotes
      const [ dfltWidth, dfltHeight ] = TextUtils.textDimensions(createjs, a, a.text.value.fn(vars))
      a.text.width = dfltWidth
      a.text.height = dfltHeight

      for (let i = 0, opt, optRawTx, optWidth, optHeight; i < optsLen; i++) {
        opt = opts[i];
        optRawTx = opt.value;

        [ optWidth, optHeight ] = TextUtils.textDimensions(createjs, a, optRawTx.value.fn(vars));

        optRawTx.width = optWidth;
        optRawTx.height = optHeight;
      }
    }
    return { afterCreate }
  })

export default model
