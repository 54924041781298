import { types } from 'mobx-state-tree'
import Expression from '../Expression'
import { DropdownTemplate } from '../Template'
import { Fill, Layerable, Rectangle, Stroke, Transformable, Typography, Visible, UI, Stateful } from './shared/props'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'Dropdown'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    placeholder: types.string,
    options: types.array(types.string),
    value: Expression,
    disabled: Expression,
    template: types.maybeNull(types.reference(DropdownTemplate)),
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Typography,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
    ...Stateful
  })
  .views(assetViews)

export default model
