import { types } from 'mobx-state-tree'
import Expandable from '../../../Expandable'

const model = types.model('DetailsState', {
  properties: Expandable,
  media: Expandable,
  keyframes: Expandable,
})

export default model
