import { types } from 'mobx-state-tree'
import Expandable from './Expandable'
import RawVideo from './Media/RawVideo'

// TODO: is this even used any more?
  const VideoRowCurrent = types.model('VideoRowCurrent', {
    active: types.boolean,
    progress: types.number,
  })

const VideoRowTimelineState = types
  .model('VideoRowTimelineState', {
    muted: types.boolean,
    showText: types.boolean,
    locked: types.boolean,
  })

const VideoRowDetailsState = types.model('VideoRowDetailsState', {
  properties: Expandable,
  media: Expandable,
})

const model = types
  .model('VideoRow', {
    Type: types.literal('VideoRow'),
    objectId: types.identifier,
    name: types.string,
    // MediaOptions
    videoMedia: types.maybeNull(types.reference(RawVideo)),
    startTime: types.number,
    current: VideoRowCurrent,
    timelineState: VideoRowTimelineState,
    detailsState: VideoRowDetailsState,
  })

export default model

