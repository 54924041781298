import { getParentOfType, getRoot } from 'mobx-state-tree'
import { types } from 'mobx-state-tree'
import { setProp } from './utils'
import { setVec } from './utils'
import { Rectangle } from './Asset/shared/props'
import { Layerable } from './Asset/shared/props'
import { Fill } from './Asset/shared/props'
import { Stroke } from './Asset/shared/props'
import { Shadow } from './Asset/shared/props'
import { Typography } from './Asset/shared/props'
import { Stateful } from './Asset/shared/props'
import { LimitedStateful } from './Asset/shared/props'
import { InputStateful } from './Asset/shared/props'
import Sequence from './BaseContent/Sequence'
import {isObservableArray} from 'mobx'
import { TEMPLATE_PROPS_EXCLUDE } from '../constants'
import { updateProp } from '../actions/edition'
import { updateModalProp } from '../actions/edition'
import { removeMedia } from '../actions/edition'

// Don't want to write to object unless we want to also
// be responsible for the distributed problems that'll arise
const references = new WeakMap

const BASE_PROPS = {
  name: true,
  objectId: true,
  updatedAt: true,
  updatedBy: true,
  properties: true,
}

const actions = self => {
  const baseActions = {
    link(target) {
      const refs = references.get(self) || new Set

      const hasRef = refs.has(target)

      if (!hasRef) {
        refs.add(target)
        references.set(self, refs)
      }

      updateProp("template", self, target)

      self.merge(target)

      return true
    },

    linkOrphan(target) {
      const template = target.template
      const templateId = template && template.objectId || ""

      if (!template) {
        return false
      }

      if (templateId !== self.objectId) {
        return false
      }

      return self.link(target)
    }
    ,

    unlink(target) {
      const refs = references.get(self) || new Set
      const hasRef = refs.has(target)

      if (target.template !== self) {
        return false
      }

      updateProp("template", null, target)

      if (hasRef) {
        refs.delete(target)

        if (refs.size === 0) {
          const e = getRoot(self)
          removeMedia(e, self)
        } else {
          references.set(self, refs)
        }
      }

      return true
    },

    remove() {
      const refs = references.get(self) || new Set

      refs.forEach(ref => self.unlink(ref))
      refs.clear()
      references.set(self, null)
      references.delete(self)
    },

    links() {
      const refs = references.get(self)
      const links = []

      if (!refs || (0 === refs.size)) return links

      for (const ref of refs.values()) {
        const sequence = getParentOfType(ref, Sequence)
        const locationName = sequence.name || "Untitled"
        const locationId = sequence.objectId
        const refName = ref.name

        links.push([refName, locationId, locationName])
      }

      return links
    },

    merge(target) {
      const { properties: props } = self
      Object.keys(props).forEach(key => {
        if ("states" !== key) {
          if (TEMPLATE_PROPS_EXCLUDE[key]) return

          const value = props[key]

          if (isObservableArray(value)) {
            setVec(key, value, target)
          } else {
            updateProp(key, value, target)
          }
        }
      })

      const { states } = props

      if (states) {
        Object.keys(states).forEach(name => {
          const stateProps = states[name]

          Object.keys(stateProps).forEach(key => {
            const value = stateProps[key]

            if (isObservableArray(value)) {
              setVec(key, value, stateProps)
            } else {
              updateModalProp(name, key, value, target)
            }
          })
        })
      }
    },
    refs() {
      return references.get(self)
    },
  }

  const typeActions = {
    updateProp(prop, value) {
      if (BASE_PROPS[prop]){
        return setProp(prop, value, self)
      } else {
        const refs = references.get(self) || new Set
        setProp(prop, value, self.properties)

        refs.forEach(ref => {
          updateProp(prop, value, ref)
        })
      }
    },
    updateModalProp(mode, prop, value) {
      const modeMap = self.properties.states[mode]
      const refs = references.get(self) || new Set

      setProp(prop, value, modeMap)

      refs.forEach(ref => {
        ref.updateModalProp(mode, prop, value, false)
      })
    },

    updateVec(prop, value) {
      const refs = references.get(self) || new Set

      setVec(prop, value, self.properties)

      refs.forEach(ref => {
        ref.updateVec(prop, value, false)
      })
    },

    updateModalVec(mode, prop, value) {
      const modeMap = self.properties.states[mode]
      const refs = references.get(self) || new Set

      setVec(prop, value, modeMap)

      refs.forEach(ref => {
        ref.updateModalVec(mode, prop, value, false)
      })
    },

    updateExpression(prop, value) {
      const refs = references.get(self) || new Set

      self.properties[prop].string = value

      refs.forEach(ref => {
        ref.updateExpression(prop, value, false)
      })
    },

    updateFromProps(source) {
      const { properties: props } = source  
      const { updatedAt } = source  
      const { updatedBy } = source  

      Object.keys(props).forEach(key => {
        if ("states" !== key) {
          const value = props[key]

          if (Array.isArray(value)) {
            setVec(key, value, self.properties)
          } else {
            setProp(key, value, self.properties)
          }
        }
      })

      const { states } = props

      if (states) {
        Object.keys(states).forEach(name => {
          const state = self.properties.states[name]
          const stateProps = states[name]

          Object.keys(stateProps).forEach(key => {
            if ("states" !== key) {
              const value = stateProps[key]

              if (Array.isArray(value)) {
                setVec(key, value, state)
              } else {
                setProp(key, value, state)
              }
            }
          })
        })
      }

      updateProp("updatedAt", updatedAt, self)
      updateProp("updatedBy", updatedBy, self)
    }
  }

  return { ...baseActions, ...typeActions }
}

const BaseTemplate = {
  name: types.string,
  objectId: types.identifier,
  updatedAt: types.number,
  updatedBy: types.string,
}

export const ButtonTemplate = types.model("ButtonTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Typography,
      ...Rectangle,
      ...Shadow,
      ...Stateful,
    }),
    Type: types.literal("ButtonTemplate"),
  })
  .actions(actions)

export const CheckboxTemplate = types.model("CheckboxTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Rectangle,
      ...LimitedStateful,
    }),
    Type: types.literal("CheckboxTemplate"),
  })
  .actions(actions)

export const ContainerTemplate = types.model("ContainerTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Rectangle,
    }),
    Type: types.literal("ContainerTemplate"),
  })
  .actions(actions)

export const DropdownTemplate = types.model("DropdownTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Typography,
      ...Rectangle,
      ...Stateful,
    }),
    Type: types.literal("DropdownTemplate"),
  })
  .actions(actions)

export const EmbedTemplate = types.model("EmbedTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Rectangle,
    }),
    Type: types.literal("EmbedTemplate"),
  })
  .actions(actions)

export const InputTemplate = types.model("InputTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Typography,
      ...Rectangle,
      ...InputStateful,
    }),
    Type: types.literal("InputTemplate"),
  })
  .actions(actions)

export const ListTemplate = types.model("ListTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Rectangle,
    }),
    Type: types.literal("ListTemplate"),
  })
  .actions(actions)

export const ListItemTemplate = types.model("ListItemTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Typography,
      ...Rectangle,
    }),
    Type: types.literal("ListItemTemplate"),
  })
  .actions(actions)

export const RadioTemplate = types.model("RadioTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Rectangle,
      ...LimitedStateful,
    }),
    Type: types.literal("RadioTemplate"),
  })
  .actions(actions)

export const SliderTemplate = types.model("SliderTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
      ...Layerable,
      ...Fill,
      ...Stroke,
      ...Rectangle,
      ...LimitedStateful,
    }),
    Type: types.literal("SliderTemplate"),
  })
  .actions(actions)

export const SpriteTemplate = types.model("SpriteTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
    ...Layerable,
    ...Stroke,
    ...Rectangle,
    }),
    Type: types.literal("SpriteTemplate"),
  })
  .actions(actions)

export const TextTemplate = types.model("TextTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
    ...Layerable,
    ...Typography,
    ...Rectangle,
    }),
    Type: types.literal("TextTemplate"),
  })
  .actions(actions)

export const ParagraphTemplate = types.model("ParagraphTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
    ...Layerable,
    ...Typography,
    ...Rectangle,
    }),
    Type: types.literal("ParagraphTemplate"),
  })
  .actions(actions)

export const TitleTemplate = types.model("TitleTemplate",
  {
    ...BaseTemplate,
    properties: types.model({
    ...Layerable,
    ...Typography,
    ...Rectangle,
    }),
    Type: types.literal("TitleTemplate"),
  })
  .actions(actions)

export const ButtonGroupTemplate = types.model("ButtonGroup", 
  {
    ...BaseTemplate,
    properties: types.model({
      ...Fill,
      ...Layerable,
      ...Rectangle,
      ...Stroke,
    }),
    Type: types.literal("ButtonGroupTemplate"),
  })
  .actions(actions)
