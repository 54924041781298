import { types } from 'mobx-state-tree'
import { Fill } from './shared/props'
import { Layerable } from './shared/props'
import { Rectangle } from './shared/props'
import { Stroke } from './shared/props'
import { Transformable } from './shared/props'
import { Visible } from './shared/props'
import { UI } from './shared/props'
import assetViews from './shared/views'

const AssetType = 'ButtonGroup'

export default types
  .model(AssetType, {
    Type: types.literal(AssetType),
    objectId: types.identifier,
    name: types.string,
    children: types.late(() => types.array(require('./Button').default)),
    gutterSize: types.integer,
    labeledBy: types.string,
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)
