/**
* First-Level Namespace: 
* app = globally-accessible elements
* * = any view scope
*
* Second-Level Namespaces:
* view = route or distinct full page layout
* region = block partition of view
* link = text element that provides navigation via click
* click = element with primary click behavior with non-navigation fx
* input = field element that accepts key input with no breaks
* edit = field element that accepts key input with breaks
* entries = sequential listing, entries within may be interactive
* entry = sequence member, may be interactive
* data = generated text representing a value
* modal = general floating ui with specific content
* select = select field element
* option = menu or select member input
* alert = live, ephemeral region that whose behavior does not require user interaction
* dialog = ephemeral region which requires user behavior to perform or dismiss a continuation
*
* All other top-level namespaces encountered considered legacy and should be
* eventually migrated
*
* Second-Level Namespace: indicates hierarchical placement
*
* Third-Level Namespace: indicates intent/role
*
  LATEST -> namespace.purpose.component
*/
export const Alert = Object.freeze({
  OK: 'alert.success', // TODO  'app.alert.success'
  WARN: 'alert.warn', // TODO  'app.alert.warn'
  ERR: 'alert.error', // TODO  'app.alert.error'
})

// TODO Apply to modals
export const Modal = Object.freeze({
  BACKDROP: "modalBackdrop.click", // TODO app.click.modalBackdrop
  CLOSE: "button.closePopup", // TODO app.click.closePopup
  HIDE: "modalButton.hideModal", // TODO app.click.hideModal
})

export const Loading = Object.freeze({
  SPINNER: "app.loading",
})

export const Manager = Object.freeze({
  PAGE: "view.manager",
  VERSION: "manager.data.version",
  TRANSCRIPTS: "button.scriptExport", // TODO manager.link.transcripts
  CAPTURES: "button.taskQueue", // TODO manager.link.captures
  LINKS : "button.advisorLinks", // TODO manager.link.publicLinks
  USER: "manager.data.username",
  RECENT: "button.expandRecentEditions", // TODO manager.click.toggleRecent
  CHECKOUT: "manager.entry.checkout",
  CHECKOUT_LATEST: "manager.data.checkoutHasDraft",
  CHECKOUT_NAME: "manager.data.checkoutEdition",
  CHECKOUT_DATE: "manager.data.checkoutDate",
  DRAFTS: "button.viewAllPersonalEditions", // TODO manager.nav.drafts
  DRAFT: "manager.entry.draft",
  DRAFT_NAME: "manager.data.draftName",
  DRAFT_DATE: "manager.data.draftUpdatedAt",
  DRAFT_REMOVE: "manager.click.deleteDraft",
  SEARCH: "input.searchBar", // TODO manager.input.search
  CLEAR: "manager.click.clearSearch",
  NEW: "menu.new-edition", // TODO manager.hover.newEdition
  CREATE: "menuoption.startNew", // TODO deprecated/removed
  CONVERT: "button.convertScript", // TODO manager.click.convert
  SEARCH_SORT_NAME: "manager.click.sortSearchByName",
  SEARCH_SORT_STATUS: "manager.click.sortSearchByStatus",
  SEARCH_SORT_UPDATED: "manager.click.sortSearchByUpdated",
  SEARCH_RESULT: "manager.entry.searchResult",
  SEARCH_RESULT_NAME: "manager.data.searchResultName",
  SEARCH_RESULT_STATUS: "manager.data.searchResultStatus",
  SEARCH_RESULT_UPDATED: "manager.data.searchResultUpdated",
  SEARCH_RESULT_COMMENT: "manager.data.searchResultComment",
  SEARCH_RESULT_UPDATED_BY: "manager.data.searchResultUpdatedBy",
})

export const History = Object.freeze({ 
  BACK: 'button.goBackHome',
  STATUS: 'history.data.checkoutStatus',
  TOGGLE_STATUS: 'button.checkInCheckOut', // TODO 'history.click.toggleStatus',
  EDITION_NAME: 'history.data.editionName',
  LATEST_VERSION: 'history.data.latestVersion',
  UPDATED_AT: 'history.data.lastUpdated',
  UPDATED_BY: 'history.data.lastUpdatedBy',
  CREATED_AT: 'history.data.lastCreated',
  CREATED_BY: 'history.data.lastCreatedBy',
  EDIT: "button.continueEditing", //TODO 'history.click.resumeEditing',
  ASSET_SET: 'history.data.assetSet',
  VERSION: 'history.entry.version', // N.B. Was `item.editionhistory.${index}`
  VERSION_EDITION: 'history.data.versionEditionName',
  VERSION_LINK: "button.createALink", // TODO 'history.click.editionLink'
  VERSION_COPY: "button.copy", // TODO 'history.click.versionCopy'
  VERSION_CAPTURE: "button.queueCapture", // TODO 'history.click.capture'
  VERSION_PROMOTE: "button.promote",  // TODO history.click.promoteVersion'
  VERSION_SEMVER: "item.editionhistory.version", // TODO history.data.versionNumber
  VERSION_CREATEDAT: "item.editionhistory.created", // TODO history.data.versionCreatedAt
  VERSION_CREATEDBY: "item.editionhistory.createdBy", // TODO history.data.versionCreatedBy
  VERSION_COMMENT: "item.editionhistory.comment",
})

export const Links = Object.freeze({
  BACK: 'links.click.back',
})

export const Captures = Object.freeze({
  BACK: 'captures.click.back',
})
