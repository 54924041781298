import { types } from 'mobx-state-tree'
import Expression from '../Expression'
import { CheckboxTemplate } from '../Template'
import { Fill, Layerable, Rectangle, Stroke, Transformable, Visible, UI, LimitedStateful as Stateful } from './shared/props'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'Checkbox'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    value: Expression,
    disabled: Expression,
    checked: Expression,
    template: types.maybeNull(types.reference(CheckboxTemplate)),
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
    ...Stateful,
  })
  .views(assetViews)

export default model
