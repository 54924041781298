import { observable } from 'mobx'

export function Session(checkLogin, getMediaHost, getAdvisorHost, throwError, scheduler, isLocal) {
  const LOGIN_POLL_MS = 900000
  const LOGIN_POLL_KEY = "LOGIN_POLL"
  const LOGIN_ERR = `Sorry, it appears the application back-end is down or not functioning correctly.\n\nIf the problem persists please contact support.`

  const state = observable({
    advisorHost: "",
    isLocal,
    mediaHost: "",
    username: "",
    async login() {
      const { username } = await checkLogin()
      const { prefix: mediaHost } = await getMediaHost() 
      const { prefix: advisorHost } = await getAdvisorHost()
      this.advisorHost = advisorHost
      this.mediaHost = mediaHost
      this.username = username

      scheduler.poll(LOGIN_POLL_KEY,
        LOGIN_POLL_MS,
        () => checkLogin()
          .catch(err => {
            console.error(err)
            scheduler.cancel(LOGIN_POLL_KEY)
            throwError(LOGIN_ERR)
          }))
    },
  })

  return state
}



