import { runtime as adobeCanvasTransform } from 'adobe-canvas-transform'
import { migration, deserialize } from 'eplayer-core'
import { handleMediaPayload } from '../utils'
import { stripDataURLHeader } from './io'
import { readDataURL } from './io'
import { Media } from 'eplayer-core'
import { CURRENT_MODEL_VERSION } from '../constants'
import CONFIG from '../CONFIG'

export function translateHttpErr(status) {
  let msg = ""
  switch(status) {
    case 500:
      msg = "Something went wrong on the receiving end of your request, try again later or open a PRODREQ issue" 
      break
    case 400:
      msg = "The data we sent for your request wasn't valid, open a PRODREQ issue"
      break
    case 401: case 402: case 407:
      msg = "You were denied network authorization needed to complete this request, open a PRODREQ issue"
      break
    case 404: case 410:
      msg = "The network location needed to complete this requet has been moved, open a PRODREQ issue if this is unaddressed shortly"
      break
    case 405:
      msg = "The wrong kind of network operation was made needed to complete your request, open a PRODREQ issue"
      break
    case 408:
      msg = "The remote machine needed to complete this request failed to respond, open a PRODREQ issue"
      break
    default:
      msg = "We were unable to complete your request, try again later or open a PRODREQ issue" 
  }
  return msg
}

// HTTP helpers
export function httpGET(url) {
  return new Promise((done, fail) => {
    const req = new XMLHttpRequest()
    req.onload = () => (req.status === 200 ? done(req.response) : fail(new Error(req.statusText)))
    req.onerror = () => fail(new Error(req.statusText))
    req.open('GET', url, true)
    req.send()
  })
}

export function httpPUT(url) {
  return new Promise((done, fail) => {
    const req = new XMLHttpRequest()
    req.onload = () => (req.status === 200 ? done(req.response) : fail(new Error(req.statusText)))
    req.onerror = () => fail(new Error(req.statusText))
    req.open('PUT', url, true)
    req.send()
  })
}

export function httpPOST(url, json) {
  return new Promise((done, fail) => {
    const req = new XMLHttpRequest()
    req.onload = () => {
      if (req.status < 200 || req.status >= 300) fail(new Error(req.statusText))
      else done(JSON.parse(req.responseText))
    }
    req.onError = () => fail(new Error(req.statusText))
    req.open('POST', url, true)
    req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    req.send(json)
  })
}

export function checkLogin() {
  return new Promise((done, fail) => {
    const req = new XMLHttpRequest()
    req.onload = () => (req.status === 200 ? done(JSON.parse(req.response)) : fail(new Error(req.statusText)))
    req.onerror = () => {
      window.location = CONFIG.API.login.redirectURL
      // fail(new Error(req.statusText)) // Remove failure to favor redirect race condition
    }
    req.open('GET', CONFIG.API.login.user, true)
    req.setRequestHeader('Authorization', 'Basic ' + btoa('nobody:password'))
    req.send()
  })
}

export function getPatientData() {
  return httpGET(CONFIG.API.editions.metadata)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving patient data.')))
}

export function getRuntimeData() {
  return httpGET(CONFIG.API.editions.configdata)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving runtime data.')))
}

// Triggers API to pull legacy list of ALL unversioned editions
export function getLegacyEditionList() {
  return httpGET(CONFIG.API.editions.alllist)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving legacy edition list.')))
}

// Triggers API to pull list of current locally saved editions by user
export function getEditionList() {
  return httpGET(CONFIG.API.editions.list)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving locally saved edition list.')))
}

// Triggers API to pull list of editions checked out to the current user
export function getCheckedOutList() {
  return httpGET(CONFIG.API.checkedout.list)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving checked out edition list.')))
}

// Triggers API to pull a list of editions that match defined searchstring and filter parameters in editionmanager observables
export function getEditionSearch(searchObj) {
  return httpGET(
    CONFIG.API.versioned.list +
      '?name=' +
      encodeURIComponent(searchObj.value) +
      '&size=' +
      searchObj.recordsperpage +
      '&page=' +
      searchObj.activepage +
      '&sort=' +
      searchObj.filter +
      '%2C' +
      searchObj.filterdirection
  )
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving edition search results.')))
}

// Triggers API to pull a list of versions for a specific edition
export function getVersionHistoryList(edition) {
  return httpGET(CONFIG.API.versionhistory.list + edition)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving edition version history.')))
}

// Triggers API to pull details for the most recent version of an edition
export function getVersionSummary(edition) {
  return httpGET(CONFIG.API.versionhistory.summary + edition)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving edition version summary.')))
}

export function getCaptureCandidancy(edition, assetSetPath) {
  return httpGET(CONFIG.API.capture.canCapture(edition, assetSetPath))
    .then(() => true)
    .catch(() => false)
}
export function getAdvisorLinks(searchObj) {
  let url = CONFIG.API.editions.getAdvisorLinks
  if (searchObj.isMyLinks) {
    url = !searchObj.isActiveLinks
      ? CONFIG.API.editions.getAdvisorLinksByUser
      : CONFIG.API.editions.getAdvisorActiveLinksByUser
  } else if (searchObj.isActiveLinks) {
    url = CONFIG.API.editions.getAdvisorActiveLinks
  }
  return httpGET(
    url +
      '?name=' +
      encodeURIComponent(searchObj.value) +
      '&size=' +
      searchObj.recordsperpage +
      '&page=' +
      searchObj.activepage +
      '&sort=' +
      searchObj.filter +
      '%2C' +
      searchObj.filterdirection
  )
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving advisor link list.')))
}

export function saveLinkExpiration(ed) {
  return httpPOST(CONFIG.API.editions.saveLinkExpiration, JSON.stringify(ed))
    .catch((err) => Promise.reject(new Error('Error saving advisor link expiration.')))
}

export function saveSharableLink(data) {
  return httpPOST(CONFIG.API.editions.saveShareLink, JSON.stringify(data))
    .catch((err) => Promise.reject(new Error('Error saving advisor link.')))
}

// Legacy function - Triggers API to pull JSON Blob of a specific edition
export function readDraftEdition(id) {
  if (!id) throw new Error('Must provide valid edition ID to fetch draft edition')
  return httpGET(CONFIG.API.editions.readlatest(id))
    .then((payload) => JSON.parse(payload))
    .then(migrateJsonSchema)
    .catch((err) => Promise.reject(new Error('Error retrieving edition data.')))
}

// Triggers API to pull JSON blob and version details of a specific version of an edition
export function readVersionedEdition(id, version, newest) {
  if (newest === true) {
    return httpGET(CONFIG.API.editions.readlatest(id))
      .then((payload) => JSON.parse(payload))
      .then(migrateJsonSchema)
      .catch((err) => Promise.reject(new Error('Error retrieving latest version of edition.')))
  } else {
    return httpGET(CONFIG.API.editions.readversioned(id, version))
      .then((payload) => JSON.parse(payload))
      .then(migrateJsonSchema)
      .catch((err) => Promise.reject(new Error('Error retrieving versioned edition.')))
  }
}

// legacy function - Post JSON Blob of a specific edition to local storage
export function saveEdition(editionDetails) {
  return httpPOST(CONFIG.API.editions.save, JSON.stringify(editionDetails))
    .catch((err) => Promise.reject(new Error('Error saving edition.')))
}

// creates new version of an edition
export function saveVersion(editionDetails, type) {
  const saveError = `Error saving ${type} version of edition.`
  if (type == 'major') {
    return httpPOST(
      CONFIG.API.editions.savemajorversion(editionDetails.editionId),
      JSON.stringify(editionDetails)
    ).catch((err) => Promise.reject(new Error(saveError)))
  } else if (type == 'minor') {
    return httpPOST(
      CONFIG.API.editions.saveminorversion(editionDetails.editionId),
      JSON.stringify(editionDetails)
    ).catch((err) => Promise.reject(new Error(saveError)))
  } else if (type == 'working') {
    return httpPOST(
      CONFIG.API.editions.saveworkingversion(editionDetails.editionId),
      JSON.stringify(editionDetails)
    ).catch((err) => Promise.reject(new Error(saveError)))
  } else {
    return Promise.reject(new Error('No version type specified...'))
  }
}

// Changes status of a versioned edition to CHECKED_OUT
export function checkOutEdition(id) {
  return httpPUT(CONFIG.API.versioned.checkout(id))
    .catch((err) => Promise.reject(new Error('Error checking out edition.')))
}

// Changes status of a versioned edition to CHECKED_IN
export function checkInEdition(id) {
  return httpPUT(CONFIG.API.versioned.checkin(id))
    .catch((err) => Promise.reject(new Error('Error checking in edition.')))
}

// Discards any local saves/personal versions for an edition
export function discardEdition(id) {
  return httpPOST(CONFIG.API.editions.discard(id))
    .catch((err) => Promise.reject(new Error('Error discarding edition.')))
}
// Promotes an existing version to be a new working/minor/major version as the head version
export function promoteVersion(id, version, newType) {
  return httpPOST(CONFIG.API.editions.promote(id, version, newType))
    .catch((err) => Promise.reject(new Error('Error promoting edition.')))
}

// retrieve the list of possible asset sets
export function getAssetSets() {
  return httpGET(CONFIG.API.refcontroller.assetsets)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error getting list of asset sets.')))
}

// retrieve the asset manager host url
export function getAssetManagerHost() {
  return httpGET(CONFIG.API.media.host)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving asset manager host.')))
}

// retrieve the advisor eplayer url
export function getAdvisorLinkUrl() {
  return httpGET(CONFIG.API.advisor.url)
    .then((payload) => JSON.parse(payload))
    .catch((err) => Promise.reject(new Error('Error retrieving the advisor eplayer url.')))
}

export async function queueEditionCapture(editionId, editionVersion, editionAssetPath, previousVersion, ttmlList) {
  const headers = new Headers
  const reqBody = {
    editionPreviousVersion: previousVersion,
    ttmlList: ttmlList
  }
  headers.set("Content-Type", "application/json")
  return fetch(CONFIG.API.capture.edition(editionId, editionVersion, editionAssetPath), {
    headers,
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(reqBody),
  }).then(res => {
      const { status } = res
      if (status === 200) return res.json() 
      else throw new Error(translateHttpErr(status))
    })
    .then(json => JSON.parse(json))
}

export async function cancelEditionCapture(taskId) {
  return fetch(CONFIG.API.capture.task(taskId), {
    credentials: 'include',
    method: 'POST', })
  .then(res => {
    const { status } = res
    if (status === 200) return res.json()
    else throw new Error(translateHttpErr(status))
  })
  .then(json => JSON.parse(json))
}

export async function queryEditionCapture(taskId) {
  return fetch(CONFIG.API.capture.task(taskId), { credentials: 'include' })
    .then(res => { 
      const { status } = res
      if (status === 200) return res.json() 
      else throw new Error(translateHttpErr(status))
    })
    .then(json => JSON.parse(json))
}

export async function queryAllEditionCapture(order, sort, pageSize, pageNum, username, search) {
  return fetch(CONFIG.API.capture.tasks(order, sort, pageSize, pageNum, username, search), { credentials: 'include' })
    .then(res => {
      if (res.ok) return res.json()
      else throw new Error(translateHttpErr(res.status))
    })
    .then(json => JSON.parse(json))
}

export async function queryAllQueuedCaptures(order, sort, pageSize, pageNum) {
  return fetch(CONFIG.API.capture.queued(order, sort, pageSize, pageNum), { credentials: 'include' })
  .then(res => {
    if (res.ok) return res.json()
    else throw new Error(translateHttpErr(res.status))
})
.then(json => JSON.parse(json))
}

export const httpGetWithRetry = httpGetWithRetryFac(window && window.fetch || undefined)

export const httpPostWithRetry = httpPostWithRetryFac(window && window.fetch || undefined)

export function httpGetJSON(url) {
  return httpGetWithRetry(url, "json", 10)
}

export function httpGetText(url) {
  return httpGetWithRetry(url, "text", 10)
}

export function httpPostJSON(url, payload) {
  return httpPostWithRetry(url, payload, 10)
}

export function httpGetWithRetryFac(fetch) {
  return function httpGetWithRetry (url, type, attempts) {
    let attempted = 0

    const opts = {
      credentials: "same-origin",
      method: "GET",
      redirect: "follow"
    }

    const work = (done, fail, url, type, opts, attempts, attempted) =>
      fetch(url, opts)
        .then(res => {
          const { ok } = res
          const canRetry = ++attempted <= attempts

          if (ok) return done(res[type]())

          if (!canRetry) {
            return fail(res.status)
          }

          setTimeout(work, 1000, done, fail, url, type, opts, attempts, attempted)
        })
        .catch(err => {
          const canRetry = ++attempted <= attempts
          if (canRetry) setTimeout(work, 1000, done, fail, url, type, opts, attempts, attempted)
          else fail(err)
        })

    return new Promise((done, fail) =>
      work(done, fail, url, type, opts, attempts, attempted))
  }
}

export function httpPostWithRetryFac(fetch) {
  return function httpPostWithRetry(url, payload, attempts) {
    let attempted = 0

    const opts = {
      body: JSON.stringify(payload),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    }

    const work = (done, fail, url, opts, attempts, attempted) =>
      fetch(url, opts)
        .then(res => {
          const { ok } = res
          const canRetry = ++attempted <= attempts

          if (ok) return done(res.json())

          if (!canRetry) {
            return fail(res.status)
          }

          setTimeout(work, 1000, done, fail, url, opts, attempts, attempted)
        })
        .catch(err => {
          const canRetry = ++attempted <= attempts
          if (canRetry) setTimeout(work, 1000, done, fail, url, opts, attempts, attempted)
          else fail(err)
        })

    return new Promise((done, fail) =>
      work(done, fail, url, opts, attempts, attempted))
  }
}

export async function* updateRemoteMedia(cache,...files) {
  const readyPayload = (file) => (url) =>
    handleMediaPayload(file,
      file.type.match(/javascript/) ?
      adobeCanvasTransform(stripDataURLHeader(url)) :
      stripDataURLHeader(url))

  const sendPayload = async (payload) => {
    try {
      return await httpPostJSON(CONFIG.API.media.upload, payload)
    } catch(status) {
      const name = payload.filename
      let msg = ""

      switch(status) {
        case 500:
          msg = `We were unable to upload ${name} because of an error in the network destination, try again later or open a PRODREQ issue` 
          break
        case 400: case 415:
          msg = `The data sent to complete uploading ${name} was malformed for some reason, usually because the file is too large (MAX 20MB), open a PRODREQ issue`
          break
        case 401: case 402: case 407:
          msg = `You were denied network authorization needed to complete uploading ${name}, open a PRODREQ issue`
          break
        case 404: case 410:
          msg = `The network location needed to complete uploading ${name} has been moved, open a PRODREQ issue if this is unaddressed shortly`
          break
        case 408:
          msg = `The remote machine needed to complete uploading ${name} to respond, open a PRODREQ issue`
          break
        default:
          msg = `We were unable to upload ${name} because of an error in the network destination, try again later or open a PRODREQ issue` 
      }

      return Promise.reject(msg)
    }
  }

  const getMetadata = (rawMedia) => {
    switch(rawMedia.fileExt) {
      case 'jpeg': case 'jpg': case 'svg': case 'png':
        rawMedia = Media.RawSprite.withDefaults(rawMedia)
        break
      case 'mp4':
        rawMedia = Media.RawVideo.withDefaults(rawMedia)
        break
      case 'mp3':
        rawMedia = Media.RawAudio.withDefaults(rawMedia)
        break
      case 'html': case 'js':
        rawMedia = Media.RawHTML.withDefaults(rawMedia)
        break
      default:
        return Promise.reject(`Encountered unknown asset manager response type: ${JSON.stringify(rawMedia)}`)
    }
    cache.addResource(rawMedia)
    return cache.load(rawMedia.src)
      .then(([ resource ]) => {
        if (rawMedia.fileExt === 'js') {
          const adobeMovieClip = resource.source.movieClip
          const totalFrames = adobeMovieClip.totalFrames
          const labelList = adobeMovieClip.labels
          const labelDict = {}
          for (let i = 0, labelObj; labelObj = labelList[i]; i++) {
            const label = labelObj.label
            const position = labelObj.position
            const duration = labelList[i + 1]
              ? labelList[i + 1].position - 1
              : totalFrames
            labelDict[label] = { position, duration }
          }
          rawMedia.labels = labelDict
          rawMedia.totalFrames = totalFrames
          rawMedia.animationHeight = resource.source.height
          rawMedia.animationWidth = resource.source.width
        }
        return rawMedia
      })
  }

  const handleSuccess = file => rawMedia => {
    return [ file, rawMedia ]
  }

  const handleFailure = file => err => {
    const isErrType = err instanceof Error
    if (!isErrType) err = new Error(err.toString())
    return [ file, err ]
  }

  const upload = file =>
    readDataURL(file)
      .then(readyPayload(file))
      .then(sendPayload)
      .then(getMetadata)
      .then(handleSuccess(file))
      .catch(handleFailure(file))

  for (const file of files) {
    yield await upload(file)
  }
}

export function migrateJsonSchema(editionVersion) {
  const entities = {}
  const editionJSON = editionVersion.version.content
  const originVersion = editionVersion.version.modelVersion
  const targetVersion = CURRENT_MODEL_VERSION
  const migratedSchema = deserialize(editionJSON, (k, v) =>
    migration.tools.mapObjectTypes(k, v, entities))
  const changeset = migration.getChangesetsFromVersion(
    originVersion,
    targetVersion)
  migration.tools.migrate(entities, changeset)
  return {
    ...editionVersion,
    version: {
      ...editionVersion.version,
      content: migratedSchema
    }
  }
}
