import React from 'react'
import { observer } from 'mobx-react'
import Homepage from '../panels/EditionManager/Homepage'
import EditionDetails from '../panels/EditionManager/EditionDetails'
import EditionAdvisorLinkDetails from '../panels/EditionManager/EditionAdvisorLinkDetails'
import LoaderPanel from '../panels/EditionManager/Loader'
import { RemoteTaskQueue } from '../panels/EditionManager/manager-task-queue' 
import { Takeover } from '../components'
import Fade from '../components/Fade'
import { MANAGER_ROUTES } from '../constants'
 
// EditionManagerUI 
// Renders the Edition Manager which replaces the legacy file load/open mechanic
// and allows for access to and control of versioned editions
const EditionManagerUI = observer(class EditionManagerUI extends React.Component {
  render() {
    const { app } = this.props
    const { taskSearch } = this.props
    const { taskState } = this.props
    const { scheduler } = this.props
    const { session } = this.props
    const { editionmanager: editionManager } = app.state
    const isHome = editionManager.subroute.current === MANAGER_ROUTES.HOMEPAGE
    const isDetails = editionManager.subroute.current === MANAGER_ROUTES.DETAILS
    const isAdvisorLinks = editionManager.subroute.current === MANAGER_ROUTES.ADVISORLINKS
    const isLoader = editionManager.subroute.current === MANAGER_ROUTES.LOADER
    const isQueue = editionManager.subroute.current === MANAGER_ROUTES.QUEUE
    const { taskQueue } = this.props
    
    const vDom =
      <div id="editionmanager" tabIndex="0" onScroll={app.state.editionmanager.onScroll}>
        <Fade in={isHome}>
          <Homepage app={app} session={session}/>
        </Fade>
        <Fade in={isDetails}>
          <EditionDetails onClickBreadcrumb={editionManager.showHomepage} app={app} session={session} taskState={taskState}/>
        </Fade>
        <Fade in={isAdvisorLinks}>
          <EditionAdvisorLinkDetails onClickBreadcrumb={editionManager.showHomepage} app={app} session={session}/>
        </Fade>
        <Fade in={isQueue}>
          <RemoteTaskQueue  onClickBreadcrumb={editionManager.showHomepage}  scheduler={scheduler}  taskSearch={taskSearch}  visibility={app.visibility} taskQueue={taskQueue}  />
        </Fade>
        <Fade in={isLoader}>
          <Takeover style={{ width: '90%', bottom: '10%' }}>
            <LoaderPanel app={app} />
          </Takeover>
        </Fade>
      </div>
    return vDom
  }
})

export default EditionManagerUI
