import React from 'react'
import { observer } from 'mobx-react'
import { Loading } from "@common/ui.constants"

const Card = observer(
  ({
    card,
    activeCard,
    onClick,
    children,
    name,
    collapsed,
    height,
    innerHeight,
    collapsePanel,
    layoutView
  }) => {
    const selected = activeCard && activeCard.objectId === card.objectId
    const collapseThisPanel = () => collapsePanel(name)
    const props = {
      key: card.objectId,
      className: 'card ' + (selected ? 'selected' : ''),
      onClick: onClick,
      style: { height: height }
    }
    const contentProps = {
      style: {
        height: innerHeight,
        overflowY: name === 'Hierarchy' && layoutView ? 'hidden' : 'scroll',
        overflowX: 'hidden'
      } // Find a standardized way to handle this difference for overflow once we convert the script panel to match the navigator.
    }
    if (name && !collapsed) {
      return (
        <div {...props}>
          <div className="collapse-bar">
            <small className="card-label">{name}</small>
            <small className="close" onClick={collapseThisPanel}>
              x
            </small>
          </div>
          <div className="card-content" {...contentProps}>
            {children}
          </div>
        </div>
      )
    } else if (name && collapsed) return null
    // Add new else here for if collapsed
    else return <div {...props}>{children}</div>
  }
)

const TimelineCard = observer(({ card, activeCard, onClick, children, height, free }) => {
  const selected = activeCard && activeCard.objectId === card.objectId
  const props = {
    key: card.objectId,
    className: "timeline-card " + (free ? "free " : "") + (selected ? "selected" : ""),
    onClick: onClick
  }

  height = free ? height : height < 650 ? height : 650

  return (
    <div {...props} style={{ height }}>
      {children}
    </div>
  )
})
const WidgetsCard = observer(
  ({ card, activeCard, onClick, children, name, collapsed, height, collapsePanel }) => {
    const selected = activeCard && activeCard.objectId === card.objectId
    const props = {
      key: card.objectId,
      className: 'top-card ' + (selected ? 'selected' : ''),
      onClick: onClick,
      style: { height: height }
    }
    if (!collapsed) {
      return <div {...props}>{children}</div>
    } else return null
  }
)
const Modal = observer(({ onClick, children }) => {
  return (
    <div className="modal-outer">
      <div className="modal-inner">{children}</div>
      <div onClick={onClick} className="modal-under" />
    </div>
  )
})

const Takeover = observer(({ onClick, children, style, className }) => {
  return (
    <div className={`takeover-outer ${className}`}>
      <div className="takeover-inner" style={style}>
        {children}
      </div>
      <div onClick={onClick} className="takeover-under" />
    </div>
  )
})

const Spinner = observer(() => {
  return (
    <div className="loading-overlay" data-qa-hook={Loading.SPINNER}>
      <div className="spinner">
        <i className="sprite general spinner" />
      </div>
    </div>
  )
})

export { Card, TimelineCard, WidgetsCard, Modal, Takeover, Spinner }
