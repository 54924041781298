import React from 'react'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'
import { Alert } from "@common/ui.constants"
import { observer } from 'mobx-react'

const AlertItem = observer(({ alert, index }) => {
  let testId = ""
  const alertType = alert.type
  if (alertType === 'success') testId = Alert.OK
  else if (alertType === 'error') testId = Alert.ERR
  else if (alertType === 'warning') testId = Alert.WARN
  else throw Error(`Unknown alert type encountered -> '${alertType}'`)

  const className = `toolbar-alert toolbar-alert-${index} ${alert.type}`
  const msg = alert.message

  const dismiss = alert.onClick ?
    <button className="alert-dismiss" title="Close this alert" onClick={alert.onClick}>
      <strong>Ⅹ</strong>
    </button> :
    null

  const dom =
    <div className={className} data-qa-hook={testId}>
      <div className="alert-message">
        {msg}
      </div>
      {dismiss}
    </div>

  return dom
})

const Alerts = observer(({ alerts }) => {
  return (
    <TransitionGroup component="div" className="toolbar-alerts">
      {alerts.map((alert, i) => (
        <CSSTransition key={alert.id} classNames="flyIn" timeout={{ enter: 300, exit: 240 }}>
          <AlertItem alert={alert} index={i} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
})

export default Alerts
