import { types } from 'mobx-state-tree'

const model = types
  .model('RawAudio', {
    Type: types.literal('RawAudio'),
    objectId: types.identifier,
    fileName: types.string,
    fileExt: types.string,
    src: types.string,
    durationMs: types.number,
    dateTime: types.maybeNull(types.string),
    size: types.maybeNull(types.number),
    userName: types.maybeNull(types.string),
  })

export default model
