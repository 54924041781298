export const TASK_SORT_ORDER = {
  Author: "creator",
  Name: "name",
  Position: "position",
  Status: "status",
  Created: "created",
  Retries: "numRetries",
}

export const TASK_SORT_ORDER_VIEW = {
  creator: "Author",
  name: "Name",
  position: "Position",
  status: "Status",
  numRetries: "Retries",
}

export const TASK_SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
}

export const TASK_FILTER = {
  ALL: 'all',
  USER: 'user',
}
