import { v4 as UUID } from 'uuid'

const STAGGER_KEY = 'alert-stagger'
const STAGGER_MS = 1000
const ALERT_MS = 3500

export class Alert {
  pending = []
  visible = []
  activity = null
  scheduler = null

  static from(scheduler, activity) {
    return new Alert(scheduler, activity)
  }
  
  constructor(scheduler, activity) {
    this.scheduler = scheduler
    this.activity = activity
  }

  success({ msg, sticky, urgent }) {
    this.add('success', msg, sticky ?? false, urgent ?? false)
  }
  warning({ msg, sticky, urgent }) {
    this.add('warning', msg, sticky ?? false, urgent ?? false)
  }
  error({ msg, sticky, urgent }) {
    this.add('error', msg, sticky ?? false, urgent ?? false)
  }
  add(alertType, message, isSticky, isUrgent) {
    const { scheduler } = this
    const { activity } = this
    const { pending } = this
    const alert = {
      id: UUID(),
      type: alertType,
      onClick: isSticky || activity.isIdle ?
        () => { this.visible.remove(alert) } :
        null,
      message,
      isUrgent }

    if (isUrgent) pending.unshift(alert)
    else pending.push(alert)

    if (scheduler.has(STAGGER_KEY)) return

    this.dequeue()

    scheduler.poll(STAGGER_KEY, STAGGER_MS, () => {
      const { pending } = this
      const hasPending = pending.length > 0

      if (hasPending) return this.dequeue()
      else return scheduler.cancel(STAGGER_KEY)
    })
  }
  dequeue() {
    const { pending } = this
    const { visible } = this
    const { scheduler } = this
    const alert = pending.shift()

    visible.unshift(alert)
    if (alert.onClick instanceof Function) return

    scheduler.wait(`alert-${alert.id}`, ALERT_MS, alert => {
      visible.remove(alert)
    }, alert)
  }
} 

