import { types } from 'mobx-state-tree'
import Sequence from './Sequence'
import Statement from '../Statement'
import Option from '../Option'

const DecisionNodeCurrent = types.model('DecisionNodeCurrent', {
	position: types.array(types.number),
	radius: types.number,
	localMatrix: types.array(types.number),
	worldMatrix: types.array(types.number),
})

const model = types
	.model('DecisionNode', {
		Type: types.literal('DecisionNode'),
		objectId: types.identifier,
		name: types.string,
		description: types.string,
		next: types.late(() =>
			types.maybeNull(types.reference(types.union(Sequence, model)))
		),
		connections: types.late(() => types.array(Option)),
		onEnter: types.maybeNull(Statement),
		current: DecisionNodeCurrent,
	})

export default model
