import { types } from 'mobx-state-tree'

const CanvasLabel = types.model('CanvasLabel', {
  duration: types.number,
  position: types.number,
})

const model = types
  .model('RawHTML', {
    Type: types.literal('RawHTML'),
    objectId: types.identifier,
    fileName: types.string,
    fileExt: types.string,
    src: types.string,
    totalFrames: types.number,
    labels: types.map(CanvasLabel),
    animationWidth: types.number,
    animationHeight: types.number,
    animationFPS: types.number,
    dateTime: types.maybeNull(types.string),
    size: types.maybeNull(types.number),
    userName: types.maybeNull(types.string),
  })

export default model
