import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import { PropertyTimelines } from './properties'

const AssetTimelines = observer(({ stage, layoutState, timelineState, ...rest }) => {
  const drawChildren = (child, index) => {
    return (
      <div className="timeline" key={index}>
        <AssetTimeline
          key={child.objectId}
          asset={child}
          timelineState={timelineState}
          layoutState={layoutState}
          stage={stage}
          {...rest}
        />
        {child.timelineState.expanded
          ? child.children
              .slice(0)
              .reverse()
              .map(drawChildren)
          : null}
      </div>
    )
  }
  const assetTimelines = stage.children
    .slice(0)
    .filter(renderable => "Video" !== renderable.Type)
    .reverse()
    .map(drawChildren)
  return (
    <div className="asset-timelines">{timelineState.assetsExpanded ? assetTimelines : null}</div>
  )
})

class AssetTimelineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isDragging = false // tracks if this particular view component is being dragged, not just the row object
    this.dragStartX = 0
  }
  mouseDown = (e) => {
    const { asset, timelineState, layoutState } = this.props
    const { selections } = layoutState
    const isSelected = selections.assets.includes(asset)
    const isLocked = timelineState.assetsLocked || asset.timelineState.locked
    if (isLocked) return
    if (!isSelected && !e.shiftKey) {
      selections.clearAllSelections()
    }
    if (!isSelected || e.shiftKey) {
      selections.setAssetSelection(asset, e.shiftKey)
      selections.selectAssetKeyframes(asset, e.shiftKey)
    }
    const NODE = e.target.ownerDocument // document.getElementById("timeline")
    NODE.addEventListener('mousemove', this.mouseMove, false)
    NODE.addEventListener('mouseup', this.mouseUp, false)
    this.dragStartX = e.clientX
    this.isDragging = true
    timelineState.dragTransaction.start(asset.objectId)
  }
  mouseMove = (e) => {
    const { frameWidth, timelineState } = this.props
    const diff = e.clientX - this.dragStartX
    const frameDiff = Math.floor(diff / frameWidth)
    timelineState.dragTransaction.move(frameDiff)
  }
  mouseUp = (e) => {
    const { timelineState } = this.props
    const NODE = e.target.ownerDocument // document.getElementById("timeline")
    NODE.removeEventListener('mousemove', this.mouseMove, false)
    NODE.removeEventListener('mouseup', this.mouseUp, false)
    this.isDragging = false
    timelineState.dragTransaction.end()
  }
  toggleExpand = (e) => {
    e.stopPropagation()
    const { asset } = this.props
    if (asset.active) toggleProp('expanded', asset.timelineState)
  }
  render() {
    const { asset, timelineState, layoutState, frames, frameWidth, offset, width } = this.props
    const { assets: selections } = layoutState.selections

    const isSelected = selections.includes(asset)
    const isLocked = asset.timelineState.locked || timelineState.assetsLocked
    const isHidden = asset.timelineState.hidden || false
    const isExpanded = asset.timelineState.expanded || false
    const hasActiveKeyframes = asset.activeKeyframes.length

    const isDragging = this.isDragging
    let rectClass = ''
    if (isSelected) rectClass += ' selected'
    if (isDragging) rectClass += ' dragging'

    const propList = asset.timelineState.properties || []

    // TODO: abstract this to a separate master timeline?
    let masterRects = []
    // Full-width "inactive" bar
    masterRects.push(
      <rect
        key={asset.objectId}
        x="0"
        y="0"
        rx="2"
        ry="2"
        width={frames * frameWidth}
        height="19"
        fill="#FFFFFF"
        fillOpacity={hasActiveKeyframes ? 0.65 : 1}
        className={rectClass}
        style={{ transform: 'translateX(0) translateZ(0)' }}
      />
    )

    // Draw active areas on top
    if (hasActiveKeyframes) {
      for (let i = 0; i < hasActiveKeyframes; i++) {
        let keyframe = asset.activeKeyframes[i]
        let nextKeyframe =
          i + 1 < asset.activeKeyframes.length ? asset.activeKeyframes[i + 1] : null
        let x1,
          x2 = null

        if (keyframe.frame <= offset) {
          // if the keyframe exists before the viewable portion of the timeline...
          x1 = 0
        } else if (keyframe.frame > offset && keyframe.frame <= offset + frames) {
          // if the keyframe exists within the viewable portion of the timeline...
          x1 = (keyframe.frame - offset) * frameWidth + frameWidth / 2
        } else if (keyframe.frame > offset.frames) {
          // if the keyframe exists after the viewable portion of the timeline...
          x1 = null
        }

        if (nextKeyframe) {
          // there is a "next" keyframe...
          if (nextKeyframe.frame >= offset && keyframe.frame <= offset + frames) {
            // the next keyframe exists within the viewable portion of the timeline...
            x2 = (nextKeyframe.frame - offset) * frameWidth + frameWidth / 2
          } else if (nextKeyframe.frame > offset + frames) {
            // the next keyframe exists beyond the viewable portion of the timeline...
            // *and* the "current" keyframe exists before or within the viewable portion of the timeline...
            x2 = x1 !== null ? frames * frameWidth : null
          }
          // otherwise x2 remains null.
        } else {
          // there's no "next" keyframe...
          // if the "current" keyframe exists before or within the viewable portion of the timeline...
          x2 = x1 !== null ? frames * frameWidth : null
        }

        if (i === 0) {
          // This is the first keyframe.
          if (x1 > 0) {
            // This first keyframe exists within the viewable portion of the timeline...
            // We need to insert a rectangle to represent the space between the start of the sequence and this first keyframe.
            masterRects.push(
              <rect
                key={0}
                x={0}
                y={0}
                width={x1}
                height={19}
                rx="2"
                ry="2"
                fill={'#FFFFFF'}
                fillOpacity={1}
                className={`${rectClass} active`}
                style={{ transform: 'translateZ(0)' }}
              />
            )
          }
        }

        if (x1 !== null && x2 !== null && x2 > x1) {
          // Draw the rectangle...
          masterRects.push(
            <rect
              key={keyframe.objectId}
              x={0}
              y={0}
              width={x2 - x1}
              height={19}
              rx="2"
              ry="2"
              fill={keyframe.value === 1 ? '#FFFFFF' : '#000000'}
              fillOpacity={keyframe.value === 1 ? 1 : 0}
              className={`${isSelected ? 'selected' : ''} ${
                keyframe.value === 1 ? 'active' : 'inactive'
              } ${rectClass} ${keyframe.value === 1 ? 'active' : 'inactive'}`}
              style={{ transform: 'translateX(' + x1 + 'px) translateZ(0)' }}
            />
          )
        }
      }
    }

    // Master Keyframes
    // Flatten keyframe arrays and handle multiple keyframes on the same frame
    const keyframeMap = new Map()
    for (let property of propList) {
      let keyframeArray = asset[property + 'Keyframes'] || []
      for (let keyframe of keyframeArray) {
        keyframeMap.set(keyframe.frame, true)
      }
    }

    // Set up a container for our master keyframe objects
    let masterKeys = []
    for (let key of keyframeMap.keys()) {
      if (key >= offset && key <= offset + frames) {
        const xPos = (key - offset) * frameWidth + frameWidth / 2 - 5
        masterKeys.push(
          <polygon
            points="0,5 5,10 10,5 5,0"
            fill={asset.Type === 'Container' ? 'none' : '#000000'}
            stroke="#000000"
            key={key}
            style={{ transform: `translate(${xPos}px, 5px)`, pointerEvents: 'none' }}
          />
        )
      }
    }

    return (
      <div>
        <div
          className={`asset-timeline ${asset.Type.toLowerCase()} ${isSelected ? 'selected' : ''}`}>
          <div className="grid" onMouseDown={this.mouseDown} onDoubleClick={this.toggleExpand}>
            <svg
              width={width}
              height="20"
              className="rowRect"
              style={{ shapeRendering: 'optimizeSpeed' }}>
              <defs>
                <linearGradient id="selection-gradient" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" className="stop-top" />
                  <stop offset="100%" className="stop-bottom" />
                </linearGradient>
              </defs>
              {masterRects}
              {masterKeys}
            </svg>
          </div>
        </div>
        {isExpanded ? (
          <PropertyTimelines {...this.props} properties={propList} stage={this.props.stage} />
        ) : null}
      </div>
    )
  }
}

decorate(AssetTimelineComponent, {
  isDragging: observable,
})

var AssetTimeline = observer(AssetTimelineComponent)

export { AssetTimelines }
