import React from 'react'
import { observer } from 'mobx-react'
import vec3 from 'gl-vec3'
import { Vec3, Keyframe as KeyframeFactory } from 'eplayer-core'
import Input from '../../components/Input'
import { roundTo as round, roundForDisplay } from '../../utils'
import { updateProp } from '../../actions/edition'
import { addKeyframe } from '../../actions/edition'
import { removeKeyframeByProp } from '../../actions/edition'
import { duplicate } from '../../actions/edition'
import { toggleProp } from '../../actions/edition'
import { updateKeyframeValue } from '../../actions/edition'

function containsSelectedKeyframe(selections, keyframes) {
  for (let i = 0; i < keyframes.length; i++) {
    if (selections.includes(keyframes[i])) return true
  }
  return false
}

const AssetLabels = observer(({ stage, timelineState, layoutState }) => {
  const drawChildren = (child, index) => {
    const asset = child.baseAsset
    return (
      <div className="asset-wrapper" key={index}>
        <AssetLabel
          key={asset.objectId}
          asset={asset}
          renderable={child}
          timelineState={timelineState}
          layoutState={layoutState}
        />
        {asset.timelineState.expanded
          ? child.children
              .slice(0)
              .reverse()
              .map(drawChildren)
          : null}
      </div>
    )
  }
  const assetLabels = layoutState.renderable.current.children
    .slice(0)
    .filter(renderable => "Video" !== renderable.Type)
    .reverse()
    .map(drawChildren)

  return <div className="asset-labels inset">{timelineState.assetsExpanded ? assetLabels : null}</div>
})

const AssetLabel = observer(class AssetLabel extends React.Component {
  toggleExpand = (e) => {
    e.stopPropagation()
    const { asset } = this.props
    toggleProp('expanded', asset.timelineState)
  }
  select = (e) => {
    e.stopPropagation()
    const { asset, timelineState, layoutState } = this.props
    const { selections } = layoutState
    const isSelected = selections.assets.includes(asset)
    const isLocked = timelineState.assetsLocked || asset.timelineState.locked
    if (isLocked) return
    if (!isSelected && !e.shiftKey) {
      selections.clearAudioSelections()
      selections.clearActionSelections()
    }
    if (!isSelected || e.shiftKey) {
      selections.setAssetSelection(asset, e.shiftKey)
      selections.selectAssetKeyframes(asset, e.shiftKey)
    }
  }
  duplicate = () => {
    const { asset } = this.props
    duplicate(asset)
  }
  lock = () => {
    const { asset, layoutState } = this.props
    layoutState.selections.deselectAsset(asset)
    layoutState.selections.deselectAssetKeyframes(asset)
    toggleProp('locked', asset.timelineState)
  }
  hide = () => {
    const { asset, layoutState } = this.props
    layoutState.selections.deselectAsset(asset)
    layoutState.selections.deselectAssetKeyframes(asset)
    toggleProp('hidden', asset.timelineState)
  }
  render() {
    const { asset, renderable, timelineState, layoutState } = this.props
    const { assets: selections } = layoutState.selections
    const { player } = layoutState
    const { animateMode } = player

    const isSelected = selections.includes(asset)
    const isLocked = asset.timelineState.locked || timelineState.assetsLocked
    const isHidden = asset.timelineState.hidden || false
    const isExpanded = asset.timelineState.expanded || false

    const propList = asset.timelineState.properties || []

    return (
      <div className="asset">
        <div
          className={`asset-label ${isSelected ? 'selected' : ''} ${isExpanded ? 'expanded' : ''}`}>
          <button className="indicator" onMouseUp={this.toggleExpand}>
            <i className="sprite timeline caret" />
          </button>
          <div className="asset-name" onMouseUp={this.select}>
            <span className="truncate">{asset.name || 'Unnamed ' + asset.Type}</span>
          </div>
          <button
            type="button"
            title="Hide"
            className={`action ${isHidden ? 'active' : ''}`}
            onClick={this.hide}>
            <i className={`sprite timeline ${isHidden ? 'on' : 'off'}`} />
          </button>
          <button
            type="button"
            title="Lock"
            className={`action ${isLocked ? 'active' : ''}`}
            onClick={this.lock}>
            <i className={`sprite timeline ${isLocked ? 'on' : 'off'}`} />
          </button>
          <button type="button" title="Clone" className="action" onClick={this.duplicate}>
            <i className="sprite timeline off active-on" />
          </button>
        </div>
        {isExpanded ? (
          <PropertyList
            layoutState={layoutState}
            asset={asset}
            renderable={renderable}
            properties={propList}
            isSelected={isSelected}
          />
        ) : null}
      </div>
    )
  }
})

const PropertyList = observer(class PropertyList extends React.Component {
  render() {
    const { layoutState, asset, renderable, properties, isSelected } = this.props
    const propList = properties.map((prop, i) => (
      <PropertyListItem
        key={i}
        layoutState={layoutState}
        asset={asset}
        renderable={renderable}
        property={prop}
        isSelected={isSelected}
      />
    ))
    return <div className="asset-properties">{propList}</div>
  }
})

const PropertyListItem = observer(class PropertyListItem extends React.Component {
  insert = () => {
    const { layoutState, asset, renderable, property } = this.props
    const { player } = layoutState
    const { animateMode, frame } = player
    const value = property === 'canvas' ? renderable.canvasFrame : renderable[property]
    if (animateMode) {
      let easing = property === 'active' ? 'step' : 'linear'
      const keyframe = KeyframeFactory.Keyframe({
        fn: easing,
        frame: Math.floor(player.frame),
        value: value
      })
      addKeyframe(property, keyframe, asset)
    }
  }
  remove = (keyframe) => {
    const { layoutState, asset, property } = this.props
    const { player } = layoutState
    const { animateMode } = player
    if (animateMode) {
      // EAT-2409: make sure to deselect the keyframe before deleting
      layoutState.selections.deselectKeyframe(keyframe)
      removeKeyframeByProp(property, keyframe, asset)
    }
  }
  render() {
    const { layoutState, asset, renderable, property, isSelected } = this.props
    const { player } = layoutState
    const { frame, animateMode } = player
    const { keyframes: keyframeSelections } = layoutState.selections
    const propKeyframes = asset[`${property}Keyframes`] || []
    const isLocked = asset.timelineState.locked || false
    const containsSelectedKeyframes = isSelected
      ? containsSelectedKeyframe(keyframeSelections, propKeyframes)
      : false
    const value = property === 'canvas' ? renderable.canvasFrame : renderable[property]
    let action = this.insert
    let keyframe = null
    for (let k of propKeyframes) {
      if (k.frame === frame) {
        keyframe = k
        action = this.remove.bind(null, keyframe)
      }
    }
    const valueProps = {
      asset: asset,
      type: property,
      value: value,
      frame: frame,
      keyframe: keyframe,
      keyframes: propKeyframes || [],
      animateMode: animateMode,
      disabled: isLocked
    }
    return (
      <div
        className={`asset-property ${property} ${isLocked ? 'locked' : ''} ${
          containsSelectedKeyframes ? 'selected' : ''
        }`}>
        <div className={`property-icon ${isSelected ? 'has-selection' : ''}`} onClick={action}>
          <div className="rect filled" />
        </div>
        <label className="property-label">{property}</label>
        <div className="keyframe-value">{<PropertyListValue {...valueProps} />}</div>
      </div>
    )
  }
})

const PropertyListValue = observer(class PropertyListValue extends React.Component {
  render() {
    const { type } = this.props
    switch (type) {
      case 'active':
        return <ActiveInput {...this.props} />
      case 'opacity':
        return <OpacityInput {...this.props} />
      case 'position':
        return <PositionInput {...this.props} />
      case 'rotation':
        return <RotationInput {...this.props} />
      case 'scale':
        return <ScaleInput {...this.props} />
      case 'frame':
        return <FrameInput {...this.props} min={1} />
      case 'canvas':
        return <FrameInput {...this.props} min={0} />
      default:
        return null
    }
  }
})

const ActiveInput = observer(
  ({ asset, type, value, frame, keyframe, keyframes, animateMode, disabled }) => {
    const isDisabled = disabled
    const onSave = (e) => {
      const activeness = parseInt(e.currentTarget.value)
      if (keyframe) {
        updateKeyframeValue(keyframe, activeness)
      } else {
        const newKeyframe = KeyframeFactory.Keyframe({
          fn: 'step',
          frame: frame,
          value: activeness
        })
        addKeyframe(type, newKeyframe, asset)
      }
    }
    return (
      <div className="inputs">
        <select onChange={onSave} value={value.toString()} disabled={isDisabled}>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
      </div>
    )
  }
)

const OpacityInput = observer(
  ({ asset, type, value, frame, keyframe, keyframes, animateMode, disabled }) => {
    const postfix = '%'
    const min = 0
    const max = 1
    const onSave = (val) => {
      val = val.replace(postfix, '') // clear the postfix from value
      val = round(val, 2) // parse the string to a number
      let opacity = Number.isNaN(val) ? value : val / 100 // if NaN, save original value
      opacity = Math.max(opacity, min)
      opacity = Math.min(opacity, max)
      if (animateMode) {
        if (keyframe) {
          updateKeyframeValue(keyframe, opacity)
        } else {
          const newKeyframe = KeyframeFactory.Keyframe({
            fn: 'linear',
            frame: frame,
            value: opacity
          })
          addKeyframe(type, newKeyframe, asset)
        }
      } else {
        const delta = value === 0 ? 1 : opacity / value // Prevent divide by zero
        const oldBase = asset[type]
        let newBase = oldBase * delta
        newBase = Math.max(newBase, min)
        newBase = Math.min(newBase, max)
        updateProp(type, newBase, asset)
        for (let i = 0; i < keyframes.length; i++) {
          let k = keyframes[i]
          let newKeyframeValue = k.value * delta
          newKeyframeValue = Math.max(newKeyframeValue, min)
          newKeyframeValue = Math.min(newKeyframeValue, max)
          updateKeyframeValue(k, newKeyframeValue)
        }
      }
    }
    return (
      <div className="inputs">
        <Input
          type="text"
          value={`${round(value * 100, 2)}${postfix}`}
          onSave={onSave}
          disabled={disabled}
          disableOnBlur={false}
        />
      </div>
    )
  }
)

const PositionInput = observer(
  ({ asset, type, value, frame, keyframe, keyframes, animateMode, disabled }) => {
    const savePosition = (position) => {
      if (animateMode) {
        if (keyframe) {
          updateKeyframeValue(keyframe, position)
        } else {
          const newKeyframe = KeyframeFactory.Keyframe({
            fn: 'linear',
            frame: frame,
            value: position
          })
          addKeyframe(type, newKeyframe, asset)
        }
      } else {
        const delta = vec3.subtract(Vec3(0, 0, 0), position, value)
        const oldBase = asset[type]
        const newBase = vec3.add(Vec3(0, 0, 0), oldBase, delta)
        updateProp(type, newBase, asset)
        for (let i = 0; i < keyframes.length; i++) {
          let k = keyframes[i]
          const newKeyframeValue = vec3.add(Vec3(0, 0, 0), k.value, delta)
          updateKeyframeValue(k, newKeyframeValue)
        }
      }
    }
    const onSaveX = (val) => {
      val = round(val, 2)
      let newX = Number.isNaN(val) ? value[0] : val // if NaN, save original value
      let position = Vec3(newX, value[1], value[2])
      savePosition(position)
    }
    const onSaveY = (val) => {
      val = round(val, 2)
      let newY = Number.isNaN(val) ? value[1] : val // if NaN, save original value
      let position = Vec3(value[0], newY, value[2])
      savePosition(position)
    }
    return (
      <div className="inputs">
        <Input
          type="text"
          placeholder={0}
          value={`${round(value[0], 2)}`}
          onSave={onSaveX}
          disabled={disabled}
          disableOnBlur={false}
        />,
        <Input
          type="text"
          placeholder={0}
          value={`${round(value[1], 2)}`}
          onSave={onSaveY}
          disabled={disabled}
          disableOnBlur={false}
        />
      </div>
    )
  }
)

const RotationInput = observer(
  ({ asset, type, value, frame, keyframe, keyframes, animateMode, disabled }) => {
    const postfix = '\xB0'
    const degrees = value[2] * 180 / Math.PI // radians to degrees
    const onSave = (val) => {
      val = val.replace(postfix, '') // clear the postfix from value
      val = round(val, 2) // parse the string to a number
      let radians = Number.isNaN(val) ? value[2] : val * Math.PI / 180 // degrees to radians; if NaN, save original value
      let rotation = Vec3(0, 0, radians)
      if (animateMode) {
        if (keyframe) {
          updateKeyframeValue(keyframe, rotation)
        } else {
          const newKeyframe = KeyframeFactory.Keyframe({
            fn: 'linear',
            frame: frame,
            value: rotation
          })
          addKeyframe(type, newKeyframe, asset)
        }
      } else {
        let delta = radians - value[2]
        const oldBase = asset[type]
        const newBase = Vec3(0, 0, oldBase[2] + delta)
        updateProp(type, newBase, asset)
        for (let i = 0; i < keyframes.length; i++) {
          let k = keyframes[i]
          let newKeyframeValue = k.value[2] + delta
          updateKeyframeValue(k, Vec3(0, 0, newKeyframeValue))
        }
      }
    }
    return (
      <div className="inputs">
        <Input
          type="text"
          value={`${degrees.toFixed()}${postfix}`}
          onSave={onSave}
          disabled={disabled}
          disableOnBlur={false}
        />
      </div>
    )
  }
)

const ScaleInput = observer(
  ({ asset, type, value, frame, keyframe, keyframes, animateMode, disabled }) => {
    const scaleLinked = asset.timelineState.scaleLinked || false
    const postfix = '%'
    const saveScale = (scale) => {
      if (animateMode) {
        if (keyframe) {
          updateKeyframeValue(keyframe, scale)
        } else {
          const newKeyframe = KeyframeFactory.Keyframe({ fn: 'linear', frame: frame, value: scale })
          addKeyframe(type, newKeyframe, asset)
        }
      } else {
        const deltaX = value[0] === 0 ? 1 : scale[0] / value[0] // Prevent divide by zero
        const deltaY = value[1] === 0 ? 1 : scale[1] / value[1] // Prevent divide by zero
        const deltaZ = value[2] === 0 ? 1 : scale[2] / value[2] // Prevent divide by zero
        const delta = Vec3(deltaX, deltaY, deltaZ)
        const oldBase = asset[type]
        const newBase = vec3.multiply(Vec3(0, 0, 0), oldBase, delta)
        updateProp(type, newBase, asset)
        for (let i = 0; i < keyframes.length; i++) {
          let k = keyframes[i]
          const newKeyframeValue = vec3.multiply(Vec3(0, 0, 0), k.value, delta)
          updateKeyframeValue(k, newKeyframeValue)
        }
      }
    }
    const onSaveX = (val) => {
      val = val.replace(postfix, '') // clear the postfix from value
      val = round(val, 2) // parse the string to a number
      let newX = Number.isNaN(val) ? value[0] : val / 100 // if NaN, save original value
      if (newX === 0) newX = 0.00000001 // Instead of 0, so future deltas work as expected
      let newY
      let delta
      delta = newX / value[0]
      newY = value[1] * delta
      newY = scaleLinked ? newY : value[1]
      let scale = Vec3(newX, newY, value[2])
      saveScale(scale)
    }
    const onSaveY = (val) => {
      val = val.replace(postfix, '') // clear the postfix from value
      val = round(val, 2) // parse the string to a number
      let newY = Number.isNaN(val) ? value[1] : val / 100 // if NaN, save original value
      if (newY === 0) newY = 0.00000001 // Instead of 0, so future deltas work as expected
      let newX
      let delta
      delta = newY / value[1]
      newX = value[0] * delta
      newX = scaleLinked ? newX : value[0]
      let scale = Vec3(newX, newY, value[2])
      saveScale(scale)
    }
    const onLink = () => {
      toggleProp('scaleLinked', asset.timelineState)
    }
    return (
      <div className="inputs">
        <button className={`link-inputs ${scaleLinked ? 'on' : 'off'}`} onClick={onLink}>
          <i className="bts bt-link" />
        </button>
        <Input
          type="text"
          placeholder="100%"
          title={value[0]}
          value={`${roundForDisplay(value[0] * 100, 2)}${postfix}`}
          onSave={onSaveX}
          disabled={disabled}
          disableOnBlur={false}
        />,
        <Input
          type="text"
          placeholder="100%"
          title={value[1]}
          value={`${roundForDisplay(value[1] * 100, 2)}${postfix}`}
          onSave={onSaveY}
          disabled={disabled}
          disableOnBlur={false}
        />
      </div>
    )
  }
)

const FrameInput = observer(
  ({ asset, type, value, frame, keyframe, keyframes, animateMode, disabled, min }) => {
    const isDisabled = disabled
    const onSave = (val) => {
      val = Number(val)
      if (val < min) return
      if (keyframe) {
        updateKeyframeValue(keyframe, val)
      } else {
        const newKeyframe = KeyframeFactory.Keyframe({ fn: 'linear', frame: frame, value: val })
        addKeyframe(type, newKeyframe, asset)
      }
    }
    return (
      <div className="inputs">
        <Input
          type="text"
          value={`${value}`}
          onSave={onSave}
          disabled={isDisabled}
          disableOnBlur={false}
        />
      </div>
    )
  }
)

export default AssetLabels
