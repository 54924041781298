import { Asset } from 'eplayer-core'
import { BaseContent } from 'eplayer-core'
import { Keyframe } from 'eplayer-core'
import { Statement as StatementData } from 'eplayer-core'
import { Option as OptionData } from 'eplayer-core'
import { TextOption as TextOptionData } from 'eplayer-core'
import { AudioRow as AudioRowData } from 'eplayer-core'
import { VideoRow as VideoRowData } from 'eplayer-core'
import { Action as ActionData } from 'eplayer-core'
import { Media } from 'eplayer-core'
import { RGBA } from 'eplayer-core'

import { getParent } from 'mobx-state-tree'
import { getSnapshot } from 'mobx-state-tree'
import { isStateTreeNode } from 'mobx-state-tree'
import { detach } from 'mobx-state-tree'
import { getRoot } from 'mobx-state-tree'
import { unprotect } from 'mobx-state-tree'

import vec3 from 'gl-vec3'

import { action } from 'mobx'

import Option from '../models/Option'
import Sequence from '../models/BaseContent/Sequence'
import Statement from '../models/Statement'
import TextOption from '../models/TextOption'
import AudioRow from '../models/AudioRow'
import Action from '../models/Action'
import Edition from '../models/BaseContent/Edition'
import RawAudio from '../models/Media/RawAudio'
import RawHTML from '../models/Media/RawHTML'
import RawVideo from '../models/Media/RawVideo'
import RawSprite from '../models/Media/RawSprite'
import VideoRow from '../models/VideoRow'
import { ListItem } from '../models/Asset'
import { MenuGroupItem } from '../models/Asset'
import { Button } from '../models/Asset'

import { connectionsWhere } from '../models/queries'
import { selectAllWhereRawAudio } from '../models/queries'
import { selectAllWhereRawVideo } from '../models/queries'
import { selectAllWhereAssetMedia } from '../models/queries'

import { walkTree } from '../models/utils'

import { isAssetType } from '../typed.utils'

import { MAX_SEQUENCE_DURATION } from '../models/constants'
import { MIN_SEQUENCE_DURATION } from '../models/constants'
import { SEQUENCE_OFFSET_Y } from '../models/constants'
import { KEYFRAME_PROPS } from '../models/constants'

import { CHAPTER_OFFSET_X } from '../constants'
import { SEQUENCE_OFFSET_X } from '../constants'
import { TEMPLATE_PROPS_EXCLUDE } from '../constants'


const childDefaults = {
  backgroundColor: RGBA(0, 122, 195, 1), // #007ac3
  borderRaidus: 4,
  boxShadowColor: RGBA(0, 0, 0, 0.8),
  boxShadowX: 6,
  boxShadowY: 6,
  fontColor: RGBA(255, 255, 255, 1), // #FFFFFF
  fontSize: 50,
  fontWeight: 500,
  height: 120,
  textAlign: 'center',
  width: 411,
}

const childHoverDefaults = {
  backgroundColor: RGBA(64, 155, 210, 1), // #409BD2
  boxShadowColor: RGBA(0, 0, 0, 0.8),
  boxShadowX: 8,
  boxShadowY: 8,
  boxShadowBlur: 10,
}

const childActiveDefaults = {
  backgroundColor: RGBA(0, 91, 146, 1),
  boxShadowColor: RGBA(0, 0, 0, 0),
  boxShadowX: 8,
  boxShadowY: 8,
  boxShadowBlur: 10,
}

const childDisabledDefaults = {
  fontColor: RGBA(128, 189, 225, 1), // #80BDE1
}

const Kf = Keyframe.Keyframe

export const updateOptionCondition = action('updateOptionCondition', function (option, value) {
  option.condition.string = value
  // Re-compile the expression (some of this should probably just be computed)
  OptionData.Expression.compile(option.condition)
})

export const updateOptionValue = action('updateOptionValue', function(option, value) {
  option.value = value
})

export const updateProp = action('updateProp', function updateProp(prop, value, entity) {
  entity[prop] = value
})

export const updateTemplatedProp = action('updateTemplatedProp', function updateTemplatedProp(prop, value, entity) {
  const template = entity.template
  if (template && !TEMPLATE_PROPS_EXCLUDE[prop]) {
    const refs = template.refs()
    for (const ref of refs) updateProp(prop, value, ref)
    updateProp(prop, value, template.properties)
  } else {
    updateProp(prop, value, entity)
  }
})

export const updateModalProp = action('updateModalProp', function(mode, prop, value, entity) {
  entity.states[mode][prop] = value
})

export const updateTemplatedModalProp = action('updateTemplatedModalProp', function(mode, prop, value, entity) {
  const template = entity.template
  const refs = template?.refs()
  if (template) {
    for (const ref of refs) {
      if (mode === 'normal') updateProp(prop, value, ref)
      else updateModalProp(mode, prop, value, ref)
    }
    if (mode === 'normal') updateProp(prop, value, template.properties)
    else updateModalProp(mode, prop, value, template.properties)
  } else {
    if (mode === 'normal') updateProp(prop, value, entity)
    else updateModalProp(mode, prop, value, entity)
  }
})


export const updateVec = action('updateVec', function(prop, [ x, y, z ], entity) {
  entity[prop][0] = x
  entity[prop][1] = y
  entity[prop][2] = z
})

export const updateColor = action('updateColor', function( prop, [ r, g, b, a ], entity) {
  entity[prop][0] = r
  entity[prop][1] = g
  entity[prop][2] = b
  entity[prop][3] = a
})

export const updateModalColor = action('updateModalColor', function(mode, prop, rgba, entity) {
  const { states } = entity
  if (mode === 'normal') {
    updateColor(prop, rgba, entity)
    return
  }
  const [ r, g, b, a ] = rgba
  const state = states[mode]
  state[prop][0] = r
  state[prop][1] = g
  state[prop][2] = b
  state[prop][3] = a
})

export const updateTemplatedModalColor = action('updateModalColor', function(mode, prop, [ r, g, b ,a ], entity) {
  const template = entity.template
  const refs  = template?.refs()
  if (template) {
    const tProps = mode === 'normal' ?
      template.properties :
      template.properties.states[mode]
    for (const ref of refs) {
      const props = mode === 'normal' ? ref : ref.states[mode]
      props[prop][0] = r
      props[prop][1] = g
      props[prop][2] = b
      props[prop][3] = a
    }
    tProps[prop][0] = r
    tProps[prop][1] = g
    tProps[prop][2] = b
    tProps[prop][3] = a
  } else {
    const props = mode === 'normal' ? entity : entity.states[mode]
    props[prop][0] = r
    props[prop][1] = g
    props[prop][2] = b
    props[prop][3] = a
  }
})

export const updateExpression = action('updateExpression', function(prop, value, entity) {
  entity[prop].string = value
})

export const updateTemplatedExpression = action('updateTemplatedExpression', function(prop, value, entity) {
  const template = entity.template
  const refs = template?.refs()
  if (template) {
    for (const ref of refs) updateExpression(prop, value, ref)
    updateExpression(prop, value, template.properties)
  } else {
    updateExpression(prop, value, entity)
  }
})

export const addKeyframe = action('addKeyframe', function(prop, kf, entity) {
  kf = kf ?? Kf()
  const f = kf.frame
  const kfs = entity[`${prop}Keyframes`]
  const currKf = kfs.find(kf => kf.frame === f)

  if (currKf) currKf.value = kf.value
  else kfs.push(kf)
})

// TODO should be superceded by `addKeyframe`
export const removeKeyframeByProp = action('removeKeyframesByProp', function(prop, kf, entity) {
  // Presumes IObservableArray type
  entity[`${prop}Keyframes`].remove(kf)
})

// This fn is exclusively used to reconcile mouse placement
// of keyframes on mouseup so extant keyframes at a frame are
// removed in being displaced by a keyframe moved 'on top' of 
// it. That code should be re-factored to gather all required
// data on mousedown instead of bespoke reconcilliation logic
// happening exclusively on mouseup. The same goes for most 
// of these other functions here, they only need `getParent`
// and other mobx-state-tree fns because the code doesn't
// correctly stage all the dependencies, eg. parents, etc
export const removeKeyframe = action('removeKeyframe', function(kfs, kf) {
  kfs.remove(kf)
})

export const toggleHierarchyState = action('toggleHierarchyState', function(prop, entity) {
  const toggle = !entity.hierarchyState[prop]
  entity.hierarchyState[prop] = toggle
})

export const forceTrueHierarchyState = action('forceTrueHierarchyState', function(prop, entity) {
  entity.hierarchyState[prop] = true
})

export const cloneDecisionNode = action('cloneDecisionNode', function(decisionNode) {
  const decisionData = getSnapshot(decisionNode)
  // run graph clone to setup new UUIDs and such
  const copy = BaseContent.DecisionNode.clone(decisionData)
  // Clear decision node connections while maintaining conditions
  copy.next = null
  for (let i = 0; i < copy.connections.length; i++) {
    let connection = copy.connections[i]
    connection.value = null
  }
  return copy
})

export const cloneChapter = action('cloneChapter', function(chapter) {
  const chapterData = isStateTreeNode(chapter) ?  getSnapshot(chapter) : chapter
  // run graph clone to setup new UUIDs and such
  const copy = BaseContent.Chapter.clone(chapterData)
  // Get internal structure references
  let internalRefs = []
  Array.prototype.push.apply(internalRefs, copy.sequences.map((seq) => seq.objectId))
  Array.prototype.push.apply(internalRefs, copy.decisionNodes.map((dN) => dN.objectId))
  // Walk the Chapter structure and disconnect anything external
  for (let i = 0, l = copy.sequences.length; i < l; i++) {
    const seq = copy.sequences[i]
    if (!internalRefs.includes(seq.next)) seq.next = null
  }
  for (let i = 0, l = copy.decisionNodes.length; i < l; i++) {
    const dN = copy.decisionNodes[i]
    if (!internalRefs.includes(dN.next)) dN.next = null
    // Check connections
    for (let j = 0; j < dN.connections.length; j++) {
      const connection = dN.connections[j]
      if (!internalRefs.includes(connection.value)) connection.value = null
    }
  }
  return copy
})

export const clone = action('clone', function(entity) {
  const entityType = entity.Type
  if (isAssetType(entityType)) {
    return Asset.clone(getSnapshot(entity))
  }
  switch(entityType) {
    case 'Edition':
      return BaseContent.Edition.clone(getSnapshot(entity))
    case 'Chapter':
      return cloneChapter(entity)
    case 'DecisionNode':
      return cloneDecisionNode(entity)
    case 'Sequence':
      return BaseContent.Sequence.clone(getSnapshot(entity))
    case 'AudioRow':
      return AudioRow.clone(getSnapshot(entity))
    case 'VideoRow':
      return VideoRow.clone(getSnapshot(entity))
    case 'Keyframe':
      return Keyframe.clone(getSnapshot(entity))
    default:
      throw new Error(`Attempted to clone non-clonable type: ${entityType}`)
  }
})

export const duplicateVideoRow = action('duplicateVideoRow', function(videoRow) {
  const sequence = parentOf(videoRow)
  addVideoRow(sequence, clone(videoRow))
})

export const duplicateSequence = action('duplicateSequence', function(sequence) {
  const chapter = parentOf(sequence)
  const cloned = clone(sequence)
  // set clone's default positioning
  cloned.current.position[1] = sequence.current.position[1] + SEQUENCE_OFFSET_Y
  addSequence(chapter, cloned)
})

export const duplicateDecisionNode = action('duplicateDecisionNode', function(decisionNode) {
  const parent = parentOf(decisionNode)
  const cloned = cloneDecisionNode(decisionNode)
  addDecisionNode(parent, cloned)
})

export const duplicateChapter = action('duplicateChapter', function(chapter) {
  const edition = parentOf(chapter)
  let duplicated = clone(chapter)
  for (let i = 0; i < duplicated.sequences.length; i++) {
    duplicated.sequences[i].current.position[0] =
      chapter.current.position[0] +
      CHAPTER_OFFSET_X +
      SEQUENCE_OFFSET_X * i -
      SEQUENCE_OFFSET_X * chapter.sequences.length / 2
  }
  addChapter(edition, duplicated)
})

// TODO address getParent
// TODO differentiate duplicate fns
export const duplicate = action('duplicate', function(entity) {
  const entityType = entity.Type
  if (isAssetType(entityType)) {
    const template = entity.template
    const parent = getParent(entity, 2)
    const newEntity = clone(entity)
    addChildAsset(parent, newEntity)
    if (template) template.linkOrphan(newEntity)
  } else if (entityType === 'Chapter') {
    return duplicateChapter(entity)
  } else if (entityType === 'Sequence') {
    return duplicateSequence(entity)
  } else if (entityType === 'DecisionNode') {
    return duplicateDecisionNode(entity)
  } else if (entityType === 'Edition') {
    const newEdition = Edition.create(clone(entity))
    unprotect(newEdition)
    return newEdition
  } else if (entityType === 'VideoRow') {
    return duplicateVideoRow(entity)
  }
})

// NB This is actually more like 'delete'
export const removeAsset = action('remove', function(asset) {
    const p = getParent(asset, 2)
    const t = asset.template

    if (t) { t.unlink(asset) }
    p.children.remove(asset)
})

export const paste = action('paste', function(targetEntity, entityData, isSameDomain) {
  const targetType = targetEntity.Type
  if (isAssetType(targetType)) {
    if (isSameDomain) addChildAsset(targetEntity, Asset.clone(entityData))
    else addChildAsset(targetEntity, Asset.cloneWithoutMedia(entityData))

    const e = getRoot(targetEntity)

    // This looks fucked but the data object we get isn't
    // the MST tree node and linkOrphan is a noop if there's
    // no template
    e.templateMedia.forEach(t => 
      targetEntity.children.forEach(asset =>
        t.linkOrphan(asset)))
  } else if (targetType === 'Chapter') {
    if (entityData.Type === 'DecisionNode') addDecisionNode(targetEntity, BaseContent.DecisionNode.clone(entityData))
    // Clear Media from certain asset types if domains do not match
    if (entityData.Type === 'Sequence') {
      if (isSameDomain) addSequence(targetEntity, BaseContent.Sequence.clone(entityData))
      else addSequence(targetEntity, BaseContent.Sequence.cloneWithoutMedia(entityData))
    }
  } else if (targetType === 'Sequence') {
    if (entityData.Type === 'DecisionNode') addDecisionNode(parentOf(targetEntity), BaseContent.DecisionNode.clone(entityData))
    // Clear Media from certain asset types if domains do not match
    if (entityData.Type === 'Sequence') {
      if (isSameDomain) addSequence(parentOf(targetEntity), BaseContent.Sequence.clone(entityData))
      else addSequence(parentOf(targetEntity), BaseContent.Sequence.cloneWithoutMedia(entityData))
    }
  } else if (targetType === 'Edition') {
    if (entityData.Type === 'DecisionNode') addDecisionNode(targetEntity, clone(entityData))
    // Clear Media from certain asset types if domains do not match
    if (entityData.Type === 'Chapter') {
      if (isSameDomain) addChapter(targetEntity, clone(entityData))
      else addChapter(targetEntity, BaseContent.Chapter.cloneWithoutMedia(entityData))
    }
  }
})

export const removeChildAsset = action('removeChildAsset', function(childAsset, parentAsset) {
  parentAsset.children.remove(childAsset)
})

export const addChildAsset = action('addChildAsset', function(parentAsset, childAsset) {
  const parentType = parentAsset.Type
  const childType = childAsset?.Type
  const isVideoInsert =
    'Stage' === parentType &&
    'Video' === childType
  detach(childAsset)
  if (isVideoInsert) parentAsset.children.unshift(childAsset)
  else parentAsset.children.push(childAsset)
})

export const addListItem = action('addListItem', function(parentList, listItem) {
  if (listItem) parentList.children.push(listItem)
  else parentList.children.push(ListItem.create(Asset.ListItem.withDefaults({ name: 'Untitled List Item' })))
})

export const addMenuGroupItem = action('addMenuGroupItem', function(parentGroup, groupItem) {
  if (parentGroup.children.length === 8) return
  else if (!groupItem) parentGroup.children.push(MenuGroupItem.create(Asset.MenuGroupItem.withDefaults({ name: 'Untitled Menu Group Item' })))
  else if (groupItem?.Type === 'MenuGroupItem') parentGroup.children.push(groupItem)
  else return
})

export const addButtonGroupItem = action('addButtonGroupItem', function(parentGroup, groupItem) {
  const gapWidth = parentGroup.gutterSize
  const nowNumChild = parentGroup.children.length 

  if (groupItem == null) {
    groupItem = Button.create(Asset.Button.withDefaults({ name: 'Untitled Button' }))
  }

  if (groupItem.Type !== 'Button') return
  else if (nowNumChild === 3) return

  const width = groupItem.width
  const height = groupItem.height
  const groupWidth = parentGroup.children.reduceRight(
    (gW, child) => gW += child.width + gapWidth,
    width)

  if (height > parentGroup.height) updateProp('height', height, parentGroup)
  updateProp('width', groupWidth, parentGroup)
  addChildAsset(parentGroup, groupItem)

  for (const key of Object.keys(childDefaults)) {
    updateProp(key, childDefaults[key], groupItem)
    updateTemplatedModalProp('active', key, childDefaults[key], groupItem)
    updateTemplatedModalProp('disabled', key, childDefaults[key], groupItem)
    updateTemplatedModalProp('hover', key, childDefaults[key], groupItem)
  }
  for (const key of Object.keys(childActiveDefaults)) {
    updateTemplatedModalProp('active', key, childActiveDefaults[key], groupItem)
  }
  for (const key of Object.keys(childDisabledDefaults)) {
    updateTemplatedModalProp('disabled', key, childDisabledDefaults[key], groupItem)
  }
  for (const key of Object.keys(childHoverDefaults)) {
    updateTemplatedModalProp('hover', key, childHoverDefaults[key], groupItem)
  }

})

export const removeButtonGroupItem = action('removeButtonGroupItem', function(buttonGroup, groupItem) {
  const { children } = buttonGroup
  const { gutterSize: gapWidth } = buttonGroup
  const nextNumChild = children.length - 1

  children.remove(groupItem)
  if (nextNumChild === 0) updateProp('width', 245, buttonGroup)
  else if (nextNumChild === 1) updateProp('width', children[0].width, buttonGroup)
  else updateProp('width', children.slice(1).reduceRight(
    (w, child) => w += child.width + gapWidth,
    children[0].width), buttonGroup)
})

export const addChildAssetAfter = action('addChildAssetAfter', function(parentAsset, targetAsset, newAsset) {
  const { children } = parentAsset
  const { length: end } = children
  if (isStateTreeNode(newAsset)) detach(newAsset)
  for (let i = 0, child; i < end; i++) {
    const isIndex = child.objectId === targetAsset.objectId
    if (!isIndex) continue
    children.splice(i, 0, newAsset)
    return
  }
})

export const acceptsDrop = function(entity) {
  if (!entity) return false
  switch(entity.Type) {
    case 'ButtonGroup':
    case 'MenuGroupItem':
    case 'MenuGroup':
    case 'ListItem':
    case 'List':
      return false
    default:
      return true
  }
}

export const updateKeyframeValue = action('updateKeyframeValue', function(kf, value) {
  kf.value = value
})

export const updateKeyframeFrame = action('updateKeyframeFrame', function(kf, f) {
  kf.frame = f
})

export const updateKeyframeEasing = action('updateKeyframeEasing', function(kf, fn) {
  kf.fn = fn
})

export const alignXMinLayout = action('alignXMinLayout', function(boundingEntity, entity) {
    const selfPos = entity.position
    const selfPosKfs = entity.positionKeyframes

    const targetPos = boundingEntity.position

    const selfX = selfPos[0]
    const targetX = targetPos[0]

    const dlta = targetX - selfX

    const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

    updateVec("position", nextSelfPos, entity)

    for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
      kf = selfPosKfs[i]
      kfVal = kf.value
      nextVal = [ kfVal[0] + dlta, kfVal[1], kfVal[2] ]

      updateKeyframeValue(kf, nextVal)
    }
})

export const alignXMaxLayout = action('alignXMaxLayout', function alignXMaxLayout(boundingEntity, entity) {
  const selfW = entity.width
  const selfPos = entity.position
  const selfPosKfs = entity.positionKeyframes

  const targetW = boundingEntity.width
  const targetPos = boundingEntity.position

  const selfX = selfPos[0]
  const targetX = targetPos[0] + targetW

  const dlta = (targetX - selfX) - selfW

  const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

  updateVec("position", nextSelfPos, entity)

  for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
    kf = selfPosKfs[i]
    kfVal = kf.value
    nextVal = [ kfVal[0] + dlta, kfVal[1], kfVal[2] ]

    updateKeyframeValue(kf, nextVal)
  }
})

export const alignXCenterLayout = action('alignXCenterLayout', function alignXCenterLayout(boundingEntity, entity) {
  const selfW = entity.width
  const selfPos = entity.position
  const selfPosKfs = entity.positionKeyframes

  const targetW = boundingEntity.width
  const targetPos = boundingEntity.position

  const selfX = selfPos[0]
  const targetX = targetPos[0] + (targetW / 2)

  const dlta = (targetX - selfX) - (selfW / 2)

  const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

  updateVec("position", nextSelfPos, entity)

  for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
    kf = selfPosKfs[i]
    kfVal = kf.value
    nextVal = [ kfVal[0] + dlta, kfVal[1], kfVal[2] ]

    updateKeyframeValue(kf, nextVal)
  }
})

export const alignYMinLayout = action('alignYMinLayout', function alignYMinLayout(boundingEntity, entity) {
  const selfPos = entity.position
  const selfPosKfs = entity.positionKeyframes

  const targetPos = boundingEntity.position

  const selfY = selfPos[1]
  const targetY = targetPos[1]

  const dlta = targetY - selfY

  const nextSelfPos = [ selfPos[0], selfY + dlta, selfPos[2] ]

  updateVec("position", nextSelfPos, entity)

  for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
    kf = selfPosKfs[i]
    kfVal = kf.value
    nextVal = [ kfVal[0], kfVal[1] + dlta, kfVal[2] ]

    updateKeyframeValue(kf, nextVal)
  }
})

export const alignYMaxLayout = action('alignYMaxLayout', function alignYMaxLayout(boundingEntity, entity) {
  const selfH = entity.height
  const selfPos = entity.position
  const selfPosKfs = entity.positionKeyframes

  const targetH = boundingEntity.height
  const targetPos = boundingEntity.position

  const selfY = selfPos[1]
  const targetY = targetPos[1] + targetH

  const dlta = (targetY - selfY) - selfH

  const nextSelfPos = [ selfPos[0], selfY + dlta, selfPos[2] ]

  updateVec("position", nextSelfPos, entity)

  for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
    kf = selfPosKfs[i]
    kfVal = kf.value
    nextVal = [ kfVal[0], kfVal[1] + dlta, kfVal[2] ]

    updateKeyframeValue(kf, nextVal)
  }
})

export const alignYCenterLayout = action('alignYCenterLayout', function alignYCenterLayout(boundingEntity, entity) {
  const selfH = entity.height
  const selfPos = entity.position
  const selfPosKfs = entity.positionKeyframes

  const targetH = boundingEntity.height
  const targetPos = boundingEntity.position

  const selfY = selfPos[1]
  const targetY = targetPos[1] + (targetH / 2)

  const dlta = (targetY - selfY) - (selfH / 2)

  const nextSelfPos = [ selfPos[0], selfY + dlta, selfPos[2] ]

  updateVec("position", nextSelfPos, entity)

  for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
    kf = selfPosKfs[i]
    kfVal = kf.value
    nextVal = [ kfVal[0], kfVal[1] + dlta, kfVal[2] ]

    updateKeyframeValue(kf, nextVal)
  }
})

export const alignXMinAnimate = action('alignXMinAnimate', function alignXMinAnimate(selfRend, targetRend, frame) {
  const selfPos = selfRend.position

  const targetPos = targetRend.position

  const selfX = selfPos[0]
  const targetX = targetPos[0]

  const dlta = targetX - selfX

  const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

  const kf = Kf({ frame, value: nextSelfPos })

  addKeyframe("position", kf, selfRend.baseAsset)
})

export const alignXMaxAnimate = action('alignXMaxAnimate', function alignXMaxAnimate(selfRend, targetRend, frame) {
  const selfW = selfRend.width
  const selfPos = selfRend.position

  const targetW = targetRend.width
  const targetPos = targetRend.position

  const selfX = selfPos[0] + selfW
  const targetX = targetPos[0] + targetW

  const dlta = targetX - selfX

  const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

  const kf = Kf({ frame, value: nextSelfPos })

  addKeyframe("position", kf, selfRend.baseAsset)
})

export const alignXCenterAnimate = action('alignXCenterAnimate', function alignXCenterAnimate(selfRend, targetRend, frame) {
  const selfW = selfRend.width
  const selfPos = selfRend.position

  const targetW = targetRend.width
  const targetPos = targetRend.position

  const selfX = selfPos[0] + (selfW / 2)
  const targetX = targetPos[0] + (targetW / 2)

  const dlta = targetX - selfX

  const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

  const kf = Kf({ frame, value: nextSelfPos })

  addKeyframe("position", kf, selfRend.baseAsset)
})

export const alignYMinAnimate = action('alignYMinAnimate', function alignYMinAnimate(selfRend, targetRend, frame) {
  const selfPos = selfRend.position

  const targetPos = targetRend.position

  const selfY = selfPos[1]
  const targetY = targetPos[1]

  const dlta = targetY - selfY

  const nextSelfPos = [ selfPos[1], selfY + dlta, selfPos[2] ]

  const kf = Kf({ frame, value: nextSelfPos })

  addKeyframe("position", kf, selfRend.baseAsset)
})

export const alignYMaxAnimate = action('alignYMaxAnimate', function alignYMaxAnimate(selfRend, targetRend, frame) {
  const selfH = selfRend.height
  const selfPos = selfRend.position

  const targetH = targetRend.height
  const targetPos = targetRend.position

  const selfY = selfPos[1] + selfH
  const targetY = targetPos[1] + targetH

  const dlta = targetY - selfY

  const nextSelfPos = [ selfPos[1], selfY + dlta, selfPos[2] ]

  const kf = Kf({ frame, value: nextSelfPos })

  addKeyframe("position", kf, selfRend.baseAsset)
})

export const alignYCenterAnimate = action('alignYCenterAnimate', function alignYCenterAnimate(selfRend, targetRend, frame) {
  const selfH = selfRend.height
  const selfPos = selfRend.position

  const targetH = targetRend.height
  const targetPos = targetRend.position

  const selfY = selfPos[1] + (selfH / 2)
  const targetY = targetPos[1] + (targetH / 2)

  const dlta = targetY - selfY

  const nextSelfPos = [ selfPos[1], selfY + dlta, selfPos[2] ]

  const kf = Kf({ frame, value: nextSelfPos })

  addKeyframe("position", kf, selfRend.baseAsset)
})

export const updateActionCode = action('updateActionCode', function(action, codeStr) {
  const props = { args: "", string: codeStr }
  action.code = Statement.create({
    ...StatementData(),
    ...props
  })
})

export const addMediaOption = action('addMediaOption', function(mediaEntity, mediaType, option) {
  option = option ?? Option.create(OptionData.Option())
  mediaEntity[`${mediaType}MediaOptions`].push(option)
})

export const removeMediaOption = action('removeMediaOption', function(mediaEntity, mediaType, option) {
  mediaEntity[`${mediaType}MediaOptions`].remove(option)
})

export const addTextOption = action('addTextOption', function(textEntity, option) {
  option = option ?? TextOption.create(TextOptionData())
  option.updateTextValue('')
  textEntity.textOptions.push(option)
})

export const removeTextOption = action('removeTextOption', function(textEntity, option) {
  textEntity.textOptions.remove(option)
})

export const toggleProp = action('toggleProp', function(prop, entity) {
  entity[prop] = !entity[prop]
})

export const setAudioRowMedia = action('setAudioRowMedia', function(audioRow, audioMedia) {
  audioRow.audioMedia = audioMedia
})

export const setAudioRowTemplateVar = action('setAudioRowTemplateVar', function(audioRow, key, value) {
  audioRow.variables[key] = value
})

export const setEmptyPosition = action('setEmptyPosition', function(navEntity, delta) {
  vec3.add(navEntity.emptyPosition, navEntity.emptyPosition, delta)
})

export const parentOf = function(entity) {
  return getParent(entity, 2)
}

export const setNext = action('setStartNodeNext', function(startNode, value) {
  startNode.next = value
})

export const addSequence = action('addSequence', function(chapter, sequence) {
  chapter.sequences.push(sequence)
})

export const addSequenceAtIndex = action('addSequenceAtIndex', function(chapter, i, sequence) {
  chapter.sequences.splice(i, 0, sequence)
})

export const addDecisionNode = action('addDecisionNode', function(parentNode, decisionNode) {
  parentNode.decisionNodes.push(decisionNode)
})

export const addDecisionNodeAtIndex = action('addDecisionNodeAtIndex', function(chapter, i, decisionNode) {
  chapter.decisionNodes.splice(i, 0, decisionNode)
})

export const insertSequenceInChapter = action('insertSequenceInChapter', function(chapter, position) {
  position = position === undefined ? chapter.sequences.length : position
  var preceedingSequence = chapter.sequences[position - 1]
  var nextSequence = chapter.sequences[position]
  var x = chapter.current.position[0] + (nextSequence ? SEQUENCE_OFFSET_X : 0)
  var y = preceedingSequence
    ? preceedingSequence.current.position[1] +
    (nextSequence ? SEQUENCE_OFFSET_X / 2 : SEQUENCE_OFFSET_X)
    : chapter.current.position[1]
  var sequence = BaseContent.Sequence()
  sequence.current.position[0] = x
  sequence.current.position[1] = y
  sequence = Sequence.create(sequence)
  addSequenceAtIndex(chapter, position, sequence)
  if (preceedingSequence) setNext(preceedingSequence, sequence)
  if (nextSequence) setNext(sequence, nextSequence)
})

export const removeSequence = action('removeSequence', function(chapter, sequence) {
  const e = parentOf(chapter)
  // Find connections
  const refs = connectionsWhere(n => {
    if (n.next && n.next === sequence) return true
    if (n.connections) return n.connections.find(conn => conn.value === sequence)
    return false
  }, e)
  // Remove connections
  for (let i = 0; i < refs.length; i++) {
    var ref = refs[i]
    if (ref.next && ref.next === sequence) setNext(ref, null)
    if (ref.connections) {
      ref.connections.forEach(conn => {
        if (conn.value === sequence) updateOptionValue(conn, null)
      })
    }
  }
  // Handle StartNode connection
  if (
    e.startNode.next &&
    e.startNode.next.objectId === sequence.objectId
  ) {
    setNext(e.startNode, null)
  }
  // Remove sequence itself
  chapter.sequences.remove(sequence)
})

export const moveSequence = action('moveSequence', function(chapter, sequence, position) {
  detach(sequence)
  addSequenceAtIndex(chapter, position, sequence)
})

// Dragging a Chapter or DecisionNode onto the Edtion in Structure Hierarchy
// Delete selected DecisionNode from Edition
export const removeDecisionNode = action('removeDecisionNode', function(parentNode, decisionNode) {
  const e = parentNode.Type === 'Edition' ?
    parentNode :
    parentOf(parentNode)
  // Find connections
  const refs = connectionsWhere(n => {
    if (n.next && n.next === decisionNode) return true
    if (n.connections) return n.connections.find(conn => conn.value === decisionNode)
    return false
  }, e)
  // Remove connections
  for (let i = 0; i < refs.length; i++) {
    var ref = refs[i]
    if (ref.next && ref.next === decisionNode) setNext(ref, null)
    if (ref.connections) {
      ref.connections.forEach(conn => {
        if (conn.value === decisionNode) updateOptionValue(conn, null)
      })
    }
  }
  // Handle StartNode connection
  if (
    e.startNode.next &&
    e.startNode.next.objectId === decisionNode.objectId
  ) {
    setNext(e.startNode, null)
  }
  // Remove decisionNode itself
  parentNode.decisionNodes.remove(decisionNode)
})

export const setSequenceDuration = action('setSequenceDuration', function(sequence, newDuration) {
  newDuration = Math.max(newDuration, MIN_SEQUENCE_DURATION) // floor of 1
  newDuration = Math.min(newDuration, MAX_SEQUENCE_DURATION) // ceiling

  sequence.duration =  newDuration
})

export const addAudioRow = action('addAudioRow', function(sequence, audioRow) {
  sequence.audioRows.push(audioRow)
})

export const addAudioRowAtIndex = action('addAudioRowAtIndex', function(sequence, index, audioRow) {
  sequence.audioRows.splice(index, 0, audioRow)
})

export const insertAudioRow = action('insertAudioRow', function(sequence, position) {
  position = position === undefined ? sequence.audioRows.length : position
  var row = AudioRow.create(AudioRowData())
  addAudioRowAtIndex(sequence, position, row)
  return row
})

export const moveAudioRow = action('moveAudioRow', function(sequence, audioRow, position) {
  detach(audioRow)
  addAudioRowAtIndex(sequence, position, row)
})

export const removeAudioRow = action('removeAudioRow', function(sequence, audioRow) {
  sequence.audioRows.remove(audioRow)
})

export const addVideoRow = action('addVideoRow', function(sequence, videoRow) {
  sequence.videoRows.push(videoRow)
})

export const addVideoRowAtIndex = action('addVideoRow', function(sequence, index, videoRow) {
  sequence.videoRows.splice(index, 0, videoRow)
})

export const setVideoMedia = action('setVideoMedia', function(videoRow, videoMedia) {
  videoRow.videoMedia = videoMedia
})

export const insertVideoRow = action('addVideoRow', function(sequence, position) {
  position = position === undefined ? sequence.videoRows.length : position
  var row = VideoRow.create(VideoRowData())
  addVideoRowAtIndex(sequence, position, row)
  return row
})

export const moveVideoRow = action('moveVideoRow', function(sequence, videoRow, position) {
  detach(videoRow)
  addVideoRowAtIndex(sequence, position, videoRow)
})

export const removeVideoRow = action('removeVideoRow', function(sequence, videoRow) {
  sequence.videoRows.remove(videoRow)
})

export const insertAction = action('insertAction', function(sequence) {
  sequence.actions.push(Action.create(ActionData()))
})

export const removeAction = action('removeAction', function(sequence, action) {
  sequence.actions.remove(action)
})

export const updateEvent = action('updateEvent', function(sequence, event, value) {
  const props = { args: '', string: value }
  sequence[event] = Statement.create({
    ...StatementData(),
    ...props,
  })
})

export const translateNode = action('translateNode', function(nodeEntity, delta) {
  if (nodeEntity.Type !== 'Chapter') {
    vec3.add(nodeEntity.current.position, nodeEntity.current.position, delta)
    return
  }
  const numSequences = nodeEntity['sequences'].length
  const numDecisions = nodeEntity['decisionNodes'].length
  const isEmpty = !numSequences && !numDecisions
  for (let j = 0; j < numSequences; j++) {
    var s = nodeEntity['sequences'][j]
    translateNode(s, delta)
  }
  for (var k = 0; k < numDecisions; k++) {
    var d = nodeEntity['decisionNodes'][k]
    translateNode(d, delta)
  }
  if (isEmpty) {
    setEmptyPosition(nodeEntity.current, delta)
  }
})

// Dragging any node in Structure Viewport
export const translateNodes = action('translateNodes', function(delta, nodes) {
  for (var i = 0; i < nodes.length; i++) {
    var node = nodes[i]
    // make sure we're not double translating nested selections
    if (!nodes.includes(parentOf(node))) translateNode(node, delta)
  }
})

// Dragging a Sequence onto another Sequence in Structure Hierarchy
export const moveContent = action('moveContent', function(targetEntity, movedEntity) {
  const targetType = targetEntity.Type
  if (targetType === 'Sequence') {
    const newParent = parentOf(targetEntity)
    const newPeers = newParent['sequences']
    const i = newPeers.indexOf(targetEntity)
    detach(movedEntity)
    addSequenceAtIndex(newParent, i, movedEntity)
  } else if (targetType === 'DecisionNode') {
    const dN = targetEntity
    const newParent = parentOf(dN)
    const newPeers = newParent['decisionNodes']
    const i = newPeers.indexOf(dN)
    detach(movedEntity)
    addDecisionNodeAtIndex(newParent, i, movedEntity)
  } else if (targetType === 'Chapter') {
    const c = targetEntity
    const newParent = parentOf(c)
    const newPeers = newParent['chapters']
    const i = newPeers.indexOf(c)
    detach(movedEntity)
    addChapterAtIndex(newParent, i, movedEntity)
  }
})

export const timelineShift = action('timelineShift', function(sequence, frameAt, modifierFn) {
  // Shift trailing Actions by 1
  for (let i = 0, l = sequence.actions.length; i < l; i++) {
    const action = sequence.actions[i]
    if (action.frame <= frameAt) continue
    updateProp('frame', modifierFn(action.frame), action)
  }
  // Shift trailing Audio by 1
  for (let i = 0, l = sequence.audioRows.length; i < l; i++) {
    const row = sequence.audioRows[i]
    if (row.startTime <= frameAt) continue
    updateProp('startTime', modifierFn(row.startTime), row)
  }
  // Shift trailing Keyframes by 1
  walkTree((n) => {
    // Loop through each of the keyframe properties
    for (let i = 0, l = KEYFRAME_PROPS.length; i < l; i++) {
      const keyframeProp = KEYFRAME_PROPS[i]
      // Check for non-shared keyframe props
      if (n.hasOwnProperty(keyframeProp)) {
        const kfs = n[keyframeProp]
        // Loop through each of the keyframe properties keyframes
        for (let j = 0, l2 = kfs.length; j < l2; j++) {
          const kf = kfs[j]
          if (kf.frame <= frameAt) continue
          updateFrame(kf, modifierFn(kf.frame))
        }
      }
    }
  }, sequence.stage)
})

export const insertFrame = action('insertFrame', function(sequence, frameAt) {
  const increment = (prevFrame) => ++prevFrame
  let newDuration = parseInt(sequence.duration) + 1
  setSequenceDuration(sequence, newDuration)
  timelineShift(sequence, frameAt, increment)
})

export const removeFrame = action('removeFrame', function(sequence, frameAt) {
  const decrement = (prevFrame) => --prevFrame
  let newDuration = parseInt(sequence.duration) - 1
  setSequenceDuration(sequence, newDuration)
  timelineShift(sequence, frameAt, decrement)
})

export const addConnection = action('addConnection', function(decisionNode, option) {
  option = option || Option.create(OptionData.Option())
  decisionNode.connections.push(option)
})

export const removeConnection = action('removeConnection', function(decisionNode, option) {
  decisionNode.connections.remove(option)
})

export const addChapter = action('addChapter', function addChapter(edition, chapter) {
  edition.chapters.push(chapter)
})

export const addChapterAtIndex = action('addChapterAtIndex', function(edition, position, chapter) {
  edition.chapters.splice(position, 0, chapter)
})

export const setContentParent = action('setContentParent', function(parentNode, childNode) {
  const parentNodeType = parentNode.Type
  const childNodeType = childNode.Type
  const edition = parentNodeType === 'Edition' ?
    parentNode :
    getRoot(parentNode)
  switch(parentNodeType) {
    case 'Chapter':
      if (childNodeType === 'Sequence') {
        detach(childNode)
        addSequence(parentNode, childNode)
      } else if (childNodeType === 'DecisionNode') {
        detach(childNode)
        addDecisionNode(parentNode, childNode)
      }
      return
    case 'Edition':
      if (childNodeType === 'Chapter') {
        detach(node)
        edition.chapters.push(childNode)
      } else if (childNodeType === 'DecisionNode') {
        detach(node)
        edition.decisionNodes.push(childNode)
      }
      return
    default:
      return
  }
})

// Dragging any widget onto the Viewport
export const addContent = action('addContent', function(edition, parentEntity, childNodes, mousePosition) {
  const parentType = parentEntity?.Type ?? 'Edition'
  const isParentEdition = parentType === 'Edition'
  const isParentChapter = parentType === 'Chapter'
  
  childNodes.forEach(childNode => {
    const childType = childNode.Type
    const isChildChapter = childType === 'Chapter'
    const isChildSequence = childType === 'Sequence'
    const isChildDecisionNode = childType === 'DecisionNode'
    const isEditionChild =
      isChildChapter ||
      isChildDecisionNode
    const isChapterChild =
      isChildSequence ||
      isChildDecisionNode
    if (isParentEdition && isEditionChild) {
      if (isChildChapter) {
        addChapter(edition, childNode)
        setEmptyPosition(childNode.current, mousePosition)
      } else if (isChildDecisionNode) {
        addDecisionNode(edition, childNode)
        translateNode(childNode, mousePosition)
      }
      return
    }
    if (isParentChapter && isChapterChild) {
      if (isChildSequence) {
        addSequence(parentEntity, childNode)
        translateNode(childNode, mousePosition)
      } else if (isChildDecisionNode) {
        addDecisionNode(parentEntity, childNode)
        translateNode(childNode, mousePosition)
      }
    }
  })
})

export const moveChapter = action('moveChapter', function(edition,chapter, position) {
  detach(chapter)
  edition.chapters.splice(position, 0, chapter)
})

// Script Panel dropdown remove Chapter
export const removeChapter = action('removeChapter', function(edition, chapter) {
  const chapters = edition.chapters
  const sequences = chapter.sequences
  const decisions = chapter.decisionNodes
  // Remove sequences (we have to do this to clean up connection references)
  for (let i = 0; i < sequences.length; i++) {
    var sequence = sequences[i]
    removeSequence(chapter, sequence)
  }
  // Remove decisionNodes (we have to do this to clean up connection references)
  for (let i = 0; i < decisions.length; i++) {
    var decision = decisions[i]
    removeDecisionNode(chapter, decision)
  }
  // Remove chapter itself
  chapters.remove(chapter)
})

// Script Panel dropdown insert Chapter
export const insertChapter = action('insertChapter', function insertChapter(edition, position) {
  position = position === undefined ? edition.chapters.length : position
  var preceedingChapter = edition.chapters[position - 1]
  var x = preceedingChapter ? preceedingChapter.current.position[0] : 0
  var y = preceedingChapter ? preceedingChapter.current.position[1] : 0
  var chapter = BaseContent.Chapter()
  chapter.current.emptyPosition[0] = x + CHAPTER_OFFSET_X
  chapter.current.emptyPosition[1] = y
  addChapterAtIndex(edition, position, chapter)
})

export const addAudio = action('addAudio', function(edition, props) {
  const checksum = props.checksum || props.objectId
  if (!checksum) return
  // Prevent duplicates
  const exists = edition.audioMedia.find((m) => m.objectId === checksum)
  if (!exists) {
    edition.audioMedia.push(
      RawAudio.create({
        ...Media.RawAudio(checksum),
        ...props,
      })
    )
  }
})

export const addSprite = action('addSprite', function(edition, props) {
  const checksum = props.checksum || props.objectId
  if (!checksum) return
  // Prevent duplicates
  const exists = edition.spriteMedia.find((m) => m.objectId === checksum)
  if (!exists) {
    edition.spriteMedia.push(
      RawSprite.create({
        ...Media.RawSprite(checksum),
        ...props,
      })
    )
  }
})

export const addCanvas = action('addCanvas', function(edition, props) {
  const checksum = props.checksum || props.objectId
  if (!checksum) return
  // Prevent duplicates
  const exists = edition.canvasMedia.find((m) => m.objectId === checksum)
  if (!exists) {
    edition.canvasMedia.push(
      RawHTML.create({
        ...Media.RawHTML(checksum),
        ...props,
      })
    )
  }
})

export const addVideo = action('addVideo', function(edition, props) {
  const checksum = props.checksum || props.objectId
  if (!checksum) return
  // Prevent duplicates
  const exists = edition.videoMedia.find((m) => m.objectId === checksum)
  if (!exists) {
    edition.videoMedia.push(
      RawVideo.create({
        ...Media.RawVideo(checksum),
        ...props,
      })
    )
  }
})

export const addTemplate = action('addTemplate', function(edition, props) {
  const id = props.objectId
  const exists = edition.templateMedia.find(t => id === t.objectId) ? true : false
  if (exists) { return }
  edition.templateMedia.push(props)
})

export const addMedia = action('addMedia', function(edition, media) {
  const { Type } = media
  if ('RawAudio' === Type) return addAudio(edition, media)
  if ('RawSprite' === Type) return addSprite(edition, media)
  if ('RawVideo' === Type) return addVideo(edition, media)
  if ('RawHTML' === Type) return addCanvas(edition, media)
  if (Type.match("Template")) return addTemplate(edition, media)
})

export const removeAudio = action('removeAudio', function(edition, audio, newAudio) {
  const refs = selectAllWhereRawAudio(edition, audio)
  for (let i = 0, ref; i < refs.length; i++) {
    ref = refs[i]
    if (ref.audioMedia !== undefined && ref.audioMedia === audio) {
      ref.audioMedia = newAudio ? newAudio : null
      continue
    }
    if (ref.value !== undefined && ref.value === audio) {
      ref.value = newAudio ? newAudio : null
      continue
    }
  }
  edition.audioMedia.remove(audio)
})

export const removeSprite = action('removeSprite', function(edition, sprite, newSprite) {
  const refs = selectAllWhereAssetMedia(edition, sprite)
  for (let i = 0, ref; i < refs.length; i++) {
    ref = refs[i]
    if (ref.spriteMedia !== undefined && ref.spriteMedia === sprite) {
      ref.spriteMedia = newSprite ? newSprite : null
      continue
    }
    if (ref.value !== undefined && ref.value === sprite) {
      ref.value = newSprite ? newSprite : null
      continue
    }
  }
  edition.spriteMedia.remove(sprite)
})

export const removeVideo = action('removeVideo', function(edition, video, newVideo) {
  const refs = selectAllWhereRawVideo(edition, video)
  for (let i = 0, ref; i < refs.length; i++) {
    ref = refs[i]
    if (ref.videoMedia !== undefined && ref.videoMedia === video) {
      ref.videoMedia = newVideo ? newVideo : null
      continue
    }
    if (ref.value !== undefined && ref.value === video) {
      ref.value = newVideo ? newVideo : null
      continue
    }
  }
  edition.videoMedia.remove(video)
})
export const removeCanvas = action('removeCanvas', function(edition, canvas, newCanvas) {
  const e = edition
  const refs = selectAllWhereAssetMedia(e, canvas)
  for (let i = 0, ref; i < refs.length; i++) {
    ref = refs[i]
    if (ref.canvasMedia !== undefined && ref.canvasMedia === canvas) {
      ref.canvasMedia = newCanvas ? newCanvas : null
      continue
    }
    if (ref.value !== undefined && ref.value === canvas) {
      ref.value = newCanvas ? newCanvas : null
      continue
    }
  }
  e.canvasMedia.remove(canvas)
})

export const removeTemplate = action('removeTemplate', function(edition, template) {
  template.remove()
  edition.templateMedia.remove(template)
})

export const removeMedia = action('removeMedia', function (edition, media, replacementMedia) {
  const { Type } = media
  if ('RawAudio' === Type) return removeAudio(edition, media, replacementMedia)
  if ('RawSprite' === Type) return removeSprite(edition, media, replacementMedia)
  if ('RawVideo' === Type) return removeVideo(edition, media, replacementMedia)
  if ('RawHTML' === Type) return removeCanvas(edition, media, replacementMedia)
  if (Type.match("Template")) return removeTemplate(edition, media)
})

// Re-linking Media
export const updateResponseMedia = action('updateResponseMedia', function(edition, oldMedia, mediaResponses) {
  if (mediaResponses.length !== 1) return
  const newMediaResponse = mediaResponses[0] // For now, we can only do 1 at a time (mediaResponses should have length === 1)

  // N.B. ED-504: Data integrity issue with Media files wrt checksum property being improperly migrated from, should remove
  // property value check when addressed, until then, fix issue whereby re-linking doesn't happen if checksum property is missing
  if ((newMediaResponse.checksum || newMediaResponse.objectId) === oldMedia.objectId) {
    return oldMedia
  }
  const fileExt = newMediaResponse.fileExt
  // Add the new Media to the associated array just like uploading normally
  writeResponseMedia(edition, [newMediaResponse])
  // Retrieve the newly created object (TODO: there's probably a better way to do this i.e. returning it from the functions)
  let newMedia
  if ('mp4' === fileExt) newMedia = edition.videoMedia[edition.videoMedia.length - 1]
  else if ('js' === fileExt) newMedia = edition.canvasMedia[edition.canvasMedia.length - 1]
  else if ('mp3' === fileExt) newMedia = edition.audioMedia[edition.audioMedia.length - 1]
  else if ('png' === fileExt || 'svg' === fileExt || 'jpg' === fileExt) newMedia = edition.spriteMedia[edition.spriteMedia.length - 1]
  // Replace and Remove the previous Media
  removeMedia(edition, oldMedia, newMedia)
  return newMedia
})

export const setVersion = action('setVersion', function(edition, major, minor, working) {
  edition.version.major = major
  edition.version.minor = minor
  edition.version.working = working
})

export const updatePatientData = action('updatePatientData', function(edition, key, value) {
  edition.variables.patient.set(key, value)
})

export const initPatientData = action('initPatientData', function(edition, vars) {
  // Accepts an array of string values that are the variable keys
  vars.forEach(v => {
    if (edition.variables.patient.has(v.key)) return // don't initialize already existing vars
    updatePatientData(edition, v.key, v.value)
  })
})

export const initRuntimeData = action('initRuntimeData', function(edition, vars) {
  // Accepts an array of string values that are the variable keys
  vars.forEach(v => {
    if (edition.variables.runtime.has(v.key)) return // don't initialize already existing vars
    else updateRuntimeData(edition, v.key, v.value)
  })
})

export const updateRuntimeData = action('updateRuntimeData', function(edition, key, value) {
  edition.variables.runtime.set(key, value)
})

export const writeResponseMedia = action('writeResponseMedia', function(edition, media) {
  for (let i = 0, serverProps, fileExt; i < media.length; i++) {
    serverProps = media[i]
    fileExt = serverProps.fileExt
    if ('mp4' === fileExt) addVideo(edition, serverProps)
    else if ('js' === fileExt) addCanvas(edition, serverProps)
    else if ('mp3' === fileExt) addAudio(edition, serverProps)
    else if ('png' === fileExt || 'svg' === fileExt || 'jpg' === fileExt || 'jpeg') addSprite(edition, serverProps)
    else console.warn('[ERROR] Unsupported media type: ' + fileExt)
  }
})

export const addVariable = action('addVariable', function(variableGroup, key, value) {
  if (key) variableGroup.set(key, value || '')
})

export const removeVariable = action('removeVariable', function(variableGroup, key) {
  variableGroup.delete(key)
})

export const updateVariable = action('updateVariable', function(variableGroup, key, value) {
  variableGroup.set(key, value)
})

export const updateDefaultText = action('updateDefaultText', function(textEntity, expr) {
  textEntity.text.value.string = expr
})

export const updateOptionText = action('updateOptionText', function(option, expr) {
  option.value.value.string = expr
})
