import { types } from 'mobx-state-tree'
import StartNode from './StartNode'
import Chapter from './Chapter'
import DecisionNode from './DecisionNode'
import { ButtonTemplate } from "../Template"
import { CheckboxTemplate } from "../Template"
import { ContainerTemplate } from "../Template"
import { DropdownTemplate } from "../Template"
import { EmbedTemplate } from "../Template"
import { InputTemplate } from "../Template"
import { ListTemplate } from "../Template"
import { ListItemTemplate } from "../Template"
import { RadioTemplate } from "../Template"
import { SliderTemplate } from "../Template"
import { SpriteTemplate } from "../Template"
import { TextTemplate } from "../Template"
import { ParagraphTemplate } from "../Template"
import { TitleTemplate } from "../Template"
import RawSprite from '../Media/RawSprite'
import RawAudio from '../Media/RawAudio'
import RawVideo from '../Media/RawVideo'
import RawHTML from '../Media/RawHTML'

const EditionVars = types.model('EditionVars', {
  runtime: types.map(
    types.optional(
      types.union(
        types.string,
        types.number,
        types.boolean,
        types.literal(null)
      ),
      null
    )
  ),
  patient: types.map(
    types.optional(
      types.union(
        types.string,
        types.number,
        types.boolean,
        types.literal(null)
      ),
      null
    )
  ),
  custom: types.map(
    types.optional(
      types.union(
        types.string,
        types.number,
        types.boolean,
        types.literal(null)
      ),
      null
    )
  ),
})

const EditionVersion = types
  .model('EditionVersion', {
    major: types.number,
    minor: types.number,
    working: types.number,
  })
  .views(self => ({
    get isUnversioned() {
      const { major, minor, working } = self
      return major === 0 && minor === 0 && working === 0
    },
    get displayString() {
      const { major, minor, working } = self
      return `${major}.${minor}.${working}`
    },
    nextVersion(versionType) {
      let nextVersion = {}
      if (versionType === 'major')
        nextVersion = { major: self.major + 1, minor: 0, working: 0 }
      if (versionType === 'minor')
        nextVersion = { major: self.major, minor: self.minor + 1, working: 0 }
      if (versionType === 'working')
        nextVersion = {
          major: self.major,
          minor: self.minor,
          working: self.working + 1,
        }
      return `${nextVersion.major}.${nextVersion.minor}.${nextVersion.working}`
    },
  }))

const model = types
  .model('Edition', {
    Type: types.literal('Edition'),
    objectId: types.identifier,
    name: types.string,
    title: types.string,
    description: types.string,
    assetSetId: types.number,
    startNode: types.late(() => StartNode),
    chapters: types.late(() => types.array(Chapter)),
    decisionNodes: types.late(() => types.array(DecisionNode)),
    audioMedia: types.array(RawAudio),
    videoMedia: types.array(RawVideo),
    canvasMedia: types.array(RawHTML),
    spriteMedia: types.array(RawSprite),
    templateMedia: types.array(types.union(
      ButtonTemplate,
      CheckboxTemplate,
      ContainerTemplate,
      DropdownTemplate,
      EmbedTemplate,
      InputTemplate,
      ListTemplate,
      ListItemTemplate,
      RadioTemplate,
      ParagraphTemplate,
      SliderTemplate,
      SpriteTemplate,
      TextTemplate,
      TitleTemplate)),
    variables: EditionVars,
    version: EditionVersion,
  })
  .views(self => ({
    get unversioned() {
      const { version } = self
      return version.major === 0 && version.minor === 0 && version.working === 0
    },
    get duration() {
      let frameSum = self.chapters
        .reduce((a, b) => a.concat(b.sequences.slice()), [])
        .map(a => Number(a.duration))
        .reduce((a, b) => b ? a+b : a, 0)
      return frameSum/24
    },
    get durationWithoutRisksOrIntro() {
      let notIntro = (chapter) => !/^Intro/i.test(chapter.name)
      let notRiskSeq = (sequence) => !/\/ r(end|a|b2|d|e|e2|el|er|f|gh|n|n2|nh|nhb|s|t|v)$/i.test(sequence.name)
      let frameSum = self.chapters
        .filter(notIntro)
        .reduce((a, b) => a.concat(b.sequences.slice()), [])
        .filter(notRiskSeq)
        .map(a => Number(a.duration))
        .reduce((a, b) => b ? a+b : a, 0)
      return frameSum/24
    },
    get sortedAudio() {
      return self.audioMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedSprites() {
      return self.spriteMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedCanvases() {
      return self.canvasMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedVideo() {
      return self.videoMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedTemplate() {
      return self.templateMedia.sort((a, b) => {
        a.name.localeCompare(b.name)
      })
    },
    get sortedAssetTemplate() {
      return self.sortedTemplate.filter(a =>
        "SequenceTemplate" !== a.Type)
    },
    get tocJSON() {
      const chapters = self.chapters.map((c, i) => {
        return { name: c.name, number: i + 1 }
      })
      return JSON.stringify(chapters)
    },
    // This should probably be a transformer
    get scriptObjects() {
      var flattened = []
      for (let c = 0; c < self.chapters.length; c++) {
        var chapter = self.chapters[c]
        flattened.push(chapter.objectId)
        for (let s = 0; s < chapter.sequences.length; s++) {
          var sequence = chapter.sequences[s]
          flattened.push(sequence.objectId)
          for (let r = 0; r < sequence.audioRows.length; r++) {
            var row = sequence.audioRows[r]
            flattened.push(row.objectId)
          }
        }
      }
      return flattened
    },
  }))

export default model
