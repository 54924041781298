import { types } from 'mobx-state-tree'
import RawSprite from '../Media/RawSprite'
import Option from '../Option'
import Keyframe from '../Keyframe'
import { SpriteTemplate } from '../Template'
import { Layerable, Rectangle, Stroke, Transformable, Visible, UI } from './shared/props'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'Sprite'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    alt: types.string,
    // MediaOptions
    spriteMedia: types.maybeNull(types.reference(RawSprite)),
    spriteMediaOptions: types.late(() => types.array(Option)),
    frame: types.number,
    frameKeyframes: types.array(Keyframe),
    template: types.maybeNull(types.reference(SpriteTemplate)),
    ...Layerable,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)

export default model
