import {
  autorun,
  observable,
  extendObservable,
  computed,
  action,
  observe,
  reaction,
  decorate,
  set
} from 'mobx'
import { createTransformer } from 'mobx-utils'
import { Asset, AABB, Keyframe, Vec3 } from 'eplayer-core'
import { utils } from 'eplayer-core'
import { MIN_SEQUENCE_DURATION } from '../../../models/constants'
import { Player } from 'eplayer-core'
import ViewportState from './Viewport'
import { createDOMCamera } from '../../DOMCamera'
import { walkTree } from '../../../utils'
import { FPS } from '../../../constants'
import { PlayingState } from '../../../PlayerStates'
import { ReadyState } from '../../../PlayerStates'
import { ErrorState } from '../../../PlayerStates'
import { updateProp } from '../../../actions/edition'
import { addKeyframe } from '../../../actions/edition'
import { duplicate } from '../../../actions/edition'
import { removeKeyframe } from '../../../actions/edition'
import { removeAsset } from '../../../actions/edition'
import { removeChildAsset } from '../../../actions/edition'
import { alignXMinAnimate } from '../../../actions/edition'
import { alignXMaxAnimate } from '../../../actions/edition'
import { alignXCenterAnimate } from '../../../actions/edition'
import { alignYMinAnimate } from '../../../actions/edition'
import { alignYMaxAnimate } from '../../../actions/edition'
import { alignYCenterAnimate } from '../../../actions/edition'
import { alignXMinLayout } from '../../../actions/edition'
import { alignXMaxLayout } from '../../../actions/edition'
import { alignXCenterLayout } from '../../../actions/edition'
import { alignYMinLayout } from '../../../actions/edition'
import { alignYMaxLayout } from '../../../actions/edition'
import { alignYCenterLayout } from '../../../actions/edition'
import { updateKeyframeValue } from '../../../actions/edition'
import { parentOf } from '../../../actions/edition'
import { removeAudioRow } from '../../../actions/edition'
import { removeVideoRow } from '../../../actions/edition'
import { removeAction } from '../../../actions/edition'
import { removeFrame } from '../../../actions/edition'
import { insertFrame } from '../../../actions/edition'
import { updateKeyframeFrame } from '../../../actions/edition'
import { getParent } from 'mobx-state-tree'

const Kf = Keyframe.Keyframe

const { append } = utils

// Nudging constants
const stepUpOne = Vec3(0, -1, 0)
const stepUpTen = Vec3(0, -10, 0)
const stepDownOne = Vec3(0, 1, 0)
const stepDownTen = Vec3(0, 10, 0)
const stepLeftOne = Vec3(-1, 0, 0)
const stepLeftTen = Vec3(-10, 0, 0)
const stepRightOne = Vec3(1, 0, 0)
const stepRightTen = Vec3(10, 0, 0)
const discreteStepTimeout = 200

// AT ePlayer Config
const eplayerConfig = {
  debug: false,
  env: 'jellyfish',
  mediaHost: '',
  disclaimer: false,
  track: false,
  captions: {
    enabled: false,
    active: false // active to start?
  },
  hideControls: false,
  pauseOnBlur: false
}

export default function LayoutState(app, clock, global) {
  // requestAnimationFrame Callback
  let requestAnimationFrameId = null
  // Camera
  const camera = createDOMCamera()
  // Player
  const player = Player.setup(null, eplayerConfig)
  decorate(player.systems[1], {
    activeVideo: observable.ref
  })
  decorate(player, {
    activeSequence: observable.ref,
    config: observable,
    fps: observable,
    playbackRate: observable,
    frame: observable,
    isReady: observable,
    isPaused: observable,
    isSeeking: observable,
    state: observable.ref,
  })
  extendObservable(player, {
    animateMode: false,
    get playing() {
      return !this.isPaused
    },
    setFrame: function(frame) {
      let newFrame = parseInt(frame) > 0 ? parseInt(frame) : 1
      newFrame = Math.max(newFrame, 1) // floor of 1
      newFrame = Math.min(newFrame, this.activeSequence.duration) // ceiling of sequence duration
      this.isSeeking = true
      this.frame = newFrame
    },
    setMediaHost: function(hostUrl) {
      this.config.mediaHost = hostUrl
    },
    togglePlayback: function() {
      this.playing ? this.pause() : this.play()
    },
    toggleAnimateMode: function() {
      this.animateMode = !this.animateMode
    },
    setActiveSequence: function(sequence) {
      this.activeSequence = sequence
    },
    setVariables: function(vars) {
      this.variables = vars
    },
    setReadiness: function(flag) {
      this.isReady = flag
    },
    reset: function() {
      this.animateMode = false
      this.frame = 1
      this.isReady = false
      this.isPaused = true
      this.isSeeking = false
      this.state = null
    },
    previousFrame: function() {
      let duration = this.activeSequence.duration
      const newFrame = this.frame - 1 < 1 ? this.frame : this.frame - 1 // loop to end
      this.pause()
      this.setFrame(newFrame)
    },
    backTenFrames: function() {
      let duration = this.activeSequence.duration
      const newFrame = this.frame - 10 < 1 ? 1 : this.frame - 10 // loop to end
      this.pause()
      this.setFrame(newFrame)
    },
    nextFrame: function() {
      let duration = this.activeSequence.duration
      const newFrame = this.frame + 1 > duration ? this.frame : this.frame + 1 // loop back to 1
      this.pause()
      this.setFrame(newFrame)
    },
    forwardTenFrames: function() {
      let duration = this.activeSequence.duration
      const newFrame = this.frame + 10 > duration ? duration : this.frame + 10 // loop back to 1
      this.pause()
      this.setFrame(newFrame)
    }
  }, {
    setFrame: action.bound,
    setMediaHost: action.bound,
    togglePlayback: action.bound,
    toggleAnimateMode: action.bound,
    setActiveSequence: action.bound,
    setVariables: action.bound,
    setReadiness: action.bound,
    reset: action.bound,
    previousFrame: action.bound,
    backTenFrames: action.bound,
    nextFrame: action.bound,
    forwardTenFrames: action.bound
  })
  set(player, 'fps', FPS)
  set(player, 'isPaused', true)
  set(player, 'pause', action(() => {
    player.isPaused = true
    // Stop on next whole frame
    player.setFrame(Math.ceil(player.frame))
  }))
  set(player, 'play', action(() => {
    player.isPaused = false
  }))

  // default player state
  player.state = new ReadyState(player)
  // This would be handy if we only want to reset when the activeSequence changes
  // (i.e. You exit a sequence, but return to the same sequence)
  // observe(player, 'activeSequence', (change) => {
  //   console.log('active sequence changed!')
  // })

  // Selections
  const selections = observable({
    actions: [],
    assets: [],
    audioRows: [],
    videoRows: [],
    keyframes: [],
    targetStage: false,
    get renderables() {
      // Map selected assets to renderables
      const assets = this.assets
      const root = renderable.current
      const selected = []
      walkTree((node, parent) => {
        const a = assets.find((a) => a.objectId === node.objectId)
        if (a) selected.push(node)
      }, root)
      return selected
    },
    get toJS() {
      return {
        actions: this.actions.map((action) => action.objectId),
        assets: this.assets.map((asset) => asset.objectId),
        audioRows: this.audioRows.map((row) => row.objectId),
        videoRows: this.videoRows.map( row => row.objectId ),
        keyframes: this.keyframes.map((keyframe) => keyframe.objectId)
      }
    },
    // alias: layoutState.hasSelections
    get hasAny() {
      return this.assets.length || this.audioRows.length || this.videoRows.length || this.keyframes.length || this.actions.length
    },
    get hasSingleAsset() {
      return this.assets.length === 1
    },
    get singleAsset() {
      const { hasSingleAsset } = this
      const { assets } = this
      return hasSingleAsset && assets[0] || null
    },
    // alias: layoutState.canDuplicateSelected
    get canDuplicate() {
      if (!this.hasAny) return false
      const assetsSelected = this.assets.length
      const audioRowsSelected = this.audioRows.length
      const keyframesSelected = this.keyframes.length
      const actionsSelected = this.actions.length
      const duplicable = (assetsSelected || audioRowsSelected) && !keyframesSelected && !actionsSelected

      if (duplicable) return true
      else return false
    },
    get timelineShiftOffsetMin() {
      let frames = []
      const selections = this
      const keyframesLength = selections.keyframes.length
      const audioLength = selections.audioRows.length
      const videoLength = selections.videoRows.length
      const actionsLength = selections.actions.length
      if (keyframesLength) {
        for (let i = 0; i < keyframesLength; i++) {
          const kf = selections.keyframes[i]
          frames.push(kf.frame)
        }
      }
      if (audioLength) {
        for (let i = 0; i < audioLength; i++) {
          const r = selections.audioRows[i]
          frames.push(r.startTime)
        }
      }
      if (videoLength) {
        for (let i = 0; i < videoLength; i++) {
          const r = selections.videoRows[i]
          frames.push(r.startTime)
        }
      }
      if (actionsLength) {
        for (let i = 0; i < actionsLength; i++) {
          const a = selections.actions[i]
          frames.push(a.frame)
        }
      }
      let lowerBound = Math.min(...frames)
      return -Math.abs(lowerBound - MIN_SEQUENCE_DURATION)
    },
    fromJS: function(selectionsObject) {
      if (!player.activeSequence) return
      const actions = player.activeSequence.actions
      const stage = player.activeSequence.stage
      const audioRows = player.activeSequence.audioRows
      const videoRows = player.activeSequence.videoRows
      // Actions
      let restoredActions = []
      selectionsObject.actions.forEach((objectId) => {
        for (let i = 0; i < actions.length; i++) {
          const action = actions[i]
          if (objectId === action.objectId) restoredActions.push(action)
        }
      })
      // Assets
      let restoredAssets = []
      selectionsObject.assets.forEach((objectId) => {
        walkTree((asset) => {
          if (objectId === asset.objectId) restoredAssets.push(asset)
        }, stage)
      })
      // AudioRows
      let restoredAudioRows = []
      selectionsObject.audioRows.forEach((objectId) => {
        for (let i = 0; i < audioRows.length; i++) {
          const row = audioRows[i]
          if (objectId === row.objectId) restoredAudioRows.push(row)
        }
      })
      // VideoRows
      let restoredVideoRows = []
      selectionsObject.videoRows.forEach((objectId) => {
        for (let i = 0; i < videoRows.length; i++) {
          const row = videoRows[i]
          if (objectId === row.objectId) restoredVideoRows.push(row)
        }
      })
      // Keyframes
      let restoredKeyframes = []
      selectionsObject.keyframes.forEach((objectId) => {
        walkTree((asset) => {
          // TODO: The Stage needs to be fixed at some point. It's Keyframe arrays do not match up with the timelineState properties.
          const propList = asset.Type === 'Stage' ? [] : asset.timelineState.properties
          for (let property of propList) {
            let keyframeArray = asset[property + 'Keyframes']
            for (let i = 0; i < keyframeArray.length; i++) {
              const keyframe = keyframeArray[i]
              if (objectId === keyframe.objectId) {
                restoredKeyframes.push(keyframe)
              }
            }
          }
        }, stage)
      })
      this.actions.replace(restoredActions)
      this.assets.replace(restoredAssets)
      this.audioRows.replace(restoredAudioRows)
      this.videoRows.replace(restoredVideoRows)
      this.keyframes.replace(restoredKeyframes)
    },
    setAssetSelection: function(node, shiftKey) {
      if (!node) {
        this.clearAssetSelections()
        return
      }
      if (shiftKey) this.assets.includes(node) ? this.assets.remove(node) : this.assets.push(node)
      else this.assets.replace([node])
    },
    selectAssetKeyframes: function(asset, shiftKey) {
      let allKeyframes = []
      const propList = asset.timelineState.properties
      for (let property of propList) {
        let keyframeArray = asset[property + 'Keyframes']
        // Concat arrays without creating a new array each time
        Array.prototype.push.apply(allKeyframes, keyframeArray)
      }
      this.setKeyframeGroupSelection(allKeyframes, shiftKey)
    },
    deselectAsset: function(asset) {
      this.assets.remove(asset)
    },
    deselectAssetKeyframes: function(asset) {
      const propList = asset.timelineState.properties
      for (let property of propList) {
        let keyframeArray = asset[property + 'Keyframes']
        for (let keyframe of keyframeArray) {
          if (this.keyframes.includes(keyframe)) {
            this.deselectKeyframe(keyframe)
          }
        }
      }
    },
    clearAssetSelections: function() {
      this.assets.clear()
      this.clearKeyframeSelections()
    },
    setAudioSelection: function(row, shiftKey) {
      if (!row) {
        this.clearAudioSelections()
        return
      }
      if (shiftKey) this.audioRows.includes(row) ? this.audioRows.remove(row) : this.audioRows.push(row)
      else this.audioRows.replace([row])
    },
    deselectAudio: function(row) {
      this.audioRows.remove(row)
    },
    clearAudioSelections: function() {
      this.audioRows.clear()
    },
    setVideoSelection: function(row, shiftKey) {
      if (!row) {
        this.clearVideoSelections()
        return
      }
      if (shiftKey) this.videoRows.includes(row) ? this.videoRows.remove(row) : this.videoRows.push(row)
      else this.videoRows.replace([row])
    },
    deselectVideo: function(row) {
      this.videoRows.remove(row)
    },
    clearVideoSelections: function() {
      this.videoRows.clear()
    },
    setActionSelection: function(action, shiftKey) {
      if (!action) {
        this.clearActionSelections()
        return
      }
      if (shiftKey)
        this.actions.includes(action) ? this.actions.remove(action) : this.actions.push(action)
      else this.actions.replace([action])
    },
    clearActionSelections: function() {
      this.actions.clear()
    },
    setKeyframeSelection: function(keyframe, shiftKey) {
      if (!keyframe) {
        this.clearKeyframeSelections()
        return
      }
      if (shiftKey)
        this.keyframes.includes(keyframe)
          ? this.keyframes.remove(keyframe)
          : this.keyframes.push(keyframe)
      else this.keyframes.replace([keyframe])
    },
    setKeyframeGroupSelection: function(keyframes, shiftKey) {
      if (shiftKey) {
        keyframes.forEach((kf) => {
          this.keyframes.includes(kf) ? this.keyframes.remove(kf) : this.keyframes.push(kf)
        })
      } else this.keyframes.replace(keyframes)
    },
    deselectKeyframe: function(keyframe) {
      this.keyframes.remove(keyframe)
    },
    clearKeyframeSelections: function() {
      this.keyframes.clear()
    },
    clearAllSelections: function() {
      this.clearAssetSelections()
      this.clearAudioSelections()
      this.clearVideoSelections()
      this.clearActionSelections()
      this.clearKeyframeSelections()
    },
    shiftTimelineSelected: function(delta) {
      if (!this.hasAny) return
      if (delta < this.timelineShiftOffsetMin) return
      app.history.startGroup()
      const selections = this
      const keyframesLength = selections.keyframes.length
      const audioLength = selections.audioRows.length
      const videoLength = selections.videoRows.length
      const actionsLength = selections.actions.length
      if (keyframesLength) {
        for (let i = 0; i < keyframesLength; i++) {
          const kf = selections.keyframes[i]
          let newFrame = kf.frame + delta
          updateKeyframeFrame(kf, newFrame)
          //removeDuplicateKeyframes
          // We want to remove keyframes on an asset property that occupy the same frame
          // as the selected keyframes that were just moved (to said frame).
          const kfs = getParent(kf)
          for (let k of kfs) {
            if (k === kf || selections.keyframes.includes(k)) continue
            if (k.frame === newFrame) {
              removeKeyframe(kfs, k)
            }
          }
        }
      }
      if (audioLength) {
        for (let i = 0; i < audioLength; i++) {
          const r = selections.audioRows[i]
          let newFrame = r.startTime + delta
          updateProp('startTime', newFrame, r)
        }
      }
      if (videoLength) {
        for (let i = 0; i < videoLength; i++) {
          const r = selections.videoRows[i]
          let newFrame = r.startTime + delta
          updateProp('startTime', newFrame, r)
        }
      }
      if (actionsLength) {
        for (let i = 0; i < actionsLength; i++) {
          const a = selections.actions[i]
          let newFrame = a.frame + delta
          updateProp('frame', parseInt(newFrame), a)
        }
      }
      app.history.stopGroup()
    },
    duplicateSelected: function() {
      if (!this.canDuplicate) return false
      const selections = this
      if (selections.assets.length) {
        for (let i = 0; i < selections.assets.length; i++) {
          const asset = selections.assets[i]
          duplicate(asset)
        }
      }
      if (selections.audioRows.length) {
        for (let i = 0; i < selections.audioRows.length; i++) {
          const row = selections.audioRows[i]
          duplicate(row)
        }
      }
      if (selections.videoRows.length) {
        for (let i = 0; i < selections.videoRows.length; i++) {
          const row = selections.videoRows[i]
          duplicate(row)
        }
      }
    },
    deleteSelected: function() {
      if (!this.hasAny) return
      const selections = this
      // Note: Only delete assets if no keyframes are selected
      if (selections.assets.length && !selections.keyframes.length) {
        for (let i = 0; i < selections.assets.length; i++) {
          const asset = selections.assets[i]
          removeAsset(asset)
        }
      }
      if (selections.keyframes.length) {
        const keyframes = selections.keyframes
        for (let i = 0; i < keyframes.length; i++) {
          const k = keyframes[i]
          const kfs = getParent(k)
          removeKeyframe(kfs, k)
        }
      }
      if (selections.actions.length) {
        for (let i = 0; i < selections.actions.length; i++) {
          const action = selections.actions[i]
          removeAction(player.activeSequence, action)
        }
      }
      if (selections.audioRows.length) {
        for (let i = 0; i < selections.audioRows.length; i++) {
          const row = selections.audioRows[i]
          removeAudioRow(player.activeSequence, row)
        }
      }
      if (selections.videoRows.length) {
        for (let i = 0; i < selections.videoRows.length; i++) {
          const row = selections.videoRows[i]
          removeVideoRow(player.activeSequence, row)
        }

        for (let i = 0, children = player.activeSequence.stage.children, child; i < children.length; i++) {
          child = children[i]
          if ('Video' === child.Type) removeChildAsset(player.activeSequence.stage, child)
        }

        player.cache.removeExcept()
      }
      this.clearAllSelections()
    },
    toggleTargetStage() {
      const bool = this.targetStage

      this.targetStage = !bool
    },
    alignXMin() {
      const isAnimateMode = player.animateMode
      const isTargetStage = this.targetStage

      if (isTargetStage && isAnimateMode) {
          const frame = player.frame
          const stage = renderable.current

          this.alignXMinAnimate(stage, frame)
      } else if (isTargetStage && !isAnimateMode) {
          const stage = renderable.current

          this.alignXMinLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const target =
            this.renderables.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[0] <= asset.position[0] ?
                      match :
                      asset,
                null
            )
          const frame = player.frame
          
          this.alignXMinAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const target =
            this.renderables.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[0] <= asset.position[0] ?
                      match :
                      asset,
                null
            )
          
        this.alignXMinLayout(target)
      }
    },
    alignXCenter() {
      const isAnimateMode = player.animateMode
      const isTargetStage = this.targetStage

      if (isTargetStage && isAnimateMode) {
          const frame = player.frame
          const stage = renderable.current

          this.alignXCenterAnimate(stage, frame)
      } else if (isTargetStage && !isAnimateMode) {
          const stage = renderable.current

          this.alignXCenterLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            width: aabb.lr[0] - aabb.ul[0]
          }
          const frame = player.frame
          
          this.alignXCenterAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            width: aabb.lr[0] - aabb.ul[0]
          }
          const frame = player.frame
          
          this.alignXCenterLayout(target)
      }
    },
    alignXMax() {
      const isAnimateMode = player.animateMode
      const isTargetStage = this.targetStage

      if (isTargetStage && isAnimateMode) {
          const frame = player.frame
          const stage = renderable.current

          this.alignXMaxAnimate(stage, frame)
      } else if (isTargetStage && !isAnimateMode) {
          const stage = renderable.current

          this.alignXMaxLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const target =
            this.renderables.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[0] >= asset.position[0] ?
                      match :
                      asset,
                null
            )
          const frame = player.frame
          
          this.alignXMaxAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const target =
            this.renderables.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[0] >= asset.position[0] ?
                      match :
                      asset,
                null
            )
          
        this.alignXMaxLayout(target)
      }
    },
    alignYMin() {
      const isAnimateMode = player.animateMode
      const isTargetStage = this.targetStage

      if (isTargetStage && isAnimateMode) {
          const frame = player.frame
          const stageRend = renderable.current

          this.alignYMinAnimate(stageRend, frame)
      } else if (isTargetStage && !isAnimateMode) {
          const stage = renderable.current

          this.alignYMinLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const target =
            this.renderables.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[1] <= asset.position[1] ?
                      match :
                      asset,
                null
            )
          const frame = player.frame
          
          this.alignYMinAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const target =
            this.assets.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[1] <= asset.position[1] ?
                      match :
                      asset,
                null
            )
          
        this.alignYMinLayout(target)
      }
    },
    alignYCenter() {
      const isAnimateMode = player.animateMode
      const isTargetStage = this.targetStage

      if (isTargetStage && isAnimateMode) {
          const frame = player.frame
          const stage = renderable.current

          this.alignYCenterAnimate(stage, frame)
      } else if (isTargetStage && !isAnimateMode) {
          const stage = renderable.current

          this.alignYCenterLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            height: aabb.lr[1] - aabb.ul[1]
          }
          const frame = player.frame
          
          this.alignYCenterAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            height: aabb.lr[1] - aabb.ul[1]
          }
          const frame = player.frame
          
          this.alignYCenterLayout(target)
      }
    },
    alignYMax() {
      const isAnimateMode = player.animateMode
      const isTargetStage = this.targetStage

      if (isTargetStage && isAnimateMode) {
          const frame = player.frame
          const stage = renderable.current

          this.alignYMaxAnimate(stage, frame)
      } else if (isTargetStage && !isAnimateMode) {
          const stage = renderable.current.baseAsset

          this.alignYMaxLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const target =
            this.renderables.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[1] >= asset.position[1] ?
                      match :
                      asset,
                null
            )
          const frame = player.frame
          
          this.alignYMaxAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const target =
            this.renderables.reduce(
                (match, asset) =>
                  !match ?
                    asset :
                    match.position[1] >= asset.position[1] ?
                      match :
                      asset,
                null
            )
          
        this.alignYMaxLayout(target)
      }
    },
    distributeY() {
      const isTargetStage = this.targetStage
      const isAnimateMode = player.mode

      if (isTargetStage && isAnimateMode) {
        const frame = player.frame
        const stage = renderable.current

        this.distributeYAnimate(stage, frame)
      } else if (isTargetStage && !isAnimateMode) {
        const stage = renderable.current

        this.distributeYLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const frame = player.frame
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            height: aabb.lr[1] - aabb.ul[1],
            width: aabb.lr[0] - aabb.ul[0]
          }

          this.distributeYAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            height: aabb.lr[1] - aabb.ul[1],
            width: aabb.lr[0] - aabb.ul[0]
          }

          this.distributeYLayout(target)
      }
    },
    distributeX() {
      const isTargetStage = this.targetStage
      const isAnimateMode = player.mode

      if (isTargetStage && isAnimateMode) {
        const frame = player.frame
        const stage = renderable.current

        this.distributeXAnimate(stage, frame)
      } else if (isTargetStage && !isAnimateMode) {
        const stage = renderable.current

        this.distributeXLayout(stage)
      } else if (!isTargetStage && isAnimateMode) {
          const frame = player.frame
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            height: aabb.lr[1] - aabb.ul[1],
            width: aabb.lr[0] - aabb.ul[0]
          }

          this.distributeXAnimate(target, frame)
      } else if (!isTargetStage && !isAnimateMode) {
          const aabb = AABB()
          AABB.surrounding(aabb, this.renderables)

          const target = {
            position: [aabb.ul[0], aabb.ul[1]],
            height: aabb.lr[1] - aabb.ul[1],
            width: aabb.lr[0] - aabb.ul[0]
          }

          this.distributeXLayout(target)
      }
    },
    alignXMinLayout(targetAsset) {
      const selected = this.assets
      if (!selected.length) return

      selected.forEach(selectedAsset =>
        alignXMinLayout(targetAsset, selectedAsset)
      )
    },
    alignXCenterLayout(targetAsset) {
      const selected = this.assets
      if (!selected.length) return

      selected.forEach(selectedAsset =>
        alignXCenterLayout(targetAsset, selectedAsset)
      )
    },
    alignXMaxLayout(targetAsset) {
      const selected = this.assets
      if (!selected.length) return

      selected.forEach(selectedAsset =>
        alignXMaxLayout(targetAsset, selectedAsset)
      )
    },
    alignYMinLayout(targetAsset) {
      const selected = this.assets
      if (!selected.length) return

      selected.forEach(selectedAsset =>
        alignYMinLayout(targetAsset, selectedAsset)
      )
    },
    alignYCenterLayout(targetAsset) {
      const selected = this.assets
      if (!selected.length) return

      selected.forEach(selectedAsset =>
        alignYCenterLayout(targetAsset, selectedAsset)
      )
    },
    alignYMaxLayout(targetAsset) {
      const selected = this.assets
      if (!selected.length) return

      selected.forEach(selectedAsset =>
        alignYMaxLayout(targetAsset, selectedAsset)
      )
    },
    alignXMinAnimate(targetRenderable, frame) {
      const selected = this.assets
      if (!selected.length) return

      selected.renderables.forEach(selectedRenderable =>
        alignXMinAnimate(selectedRenderable, targetRenderable, frame)
      )
    },
    alignXCenterAnimate(targetRenderable, frame) {
      const selected = this.assets
      if (!selected.length) return

      selected.renderables.forEach(selectedRenderable =>
        alignXCenterAnimate(selectedRenderable, targetRenderable, frame)
      )
    },
    alignXMaxAnimate(targetRenderable, frame) {
      const selected = this.assets
      if (!selected.length) return

      selected.renderables.forEach(selectedRenderable =>
        alignXMaxAnimate(selectedRenderable, targetRenderable, frame)
      )
    },
    alignYMinAnimate(targetRenderable, frame) {
      const selected = this.assets
      if (!selected.length) return

      selected.renderables.forEach(selectedRenderable =>
        alignYMinAnimate(selectedRenderable, targetRenderable, frame)
      )
    },
    alignYCenterAnimate(targetRenderable, frame) {
      const selected = this.assets
      if (!selected.length) return

      selected.renderables.forEach(selectedRenderable =>
        alignYCenterAnimate(selectedRenderable, targetRenderable, frame)
      )
    },
    alignYMaxAnimate(targetRenderable, frame) {
      const selected = this.assets
      if (!selected.length) return

      selected.renderables.forEach(selectedRenderable =>
        alignYMaxAnimate(selectedRenderable, targetRenderable, frame)
      )
    },
    distributeYLayout(targetAsset) {
      const { assets: selected } = this
      const { length: numSelected } = selected

      if (numSelected < 2) return

      const targetW = targetAsset.width
      const targetH = targetAsset.height

      const [ minX, minY ] = targetAsset.position

      const maxX = targetW + minX
      const maxY = targetH + minY

      const sorted = selected.sort((a, b) => {
        const { position: [ aX, aY ] } = a
        const { position: [ bX, bY ] } = b

        const { width: aW } = a
        const { width: bW } = b

        const { height: aH } = a
        const { height: bH } = b

        if (aY < bY) return -1
        if (aY === bY && aX <= bX) return -1

        return 1
      })

      const sumH = sorted.reduce((sum, selected) =>
        sum + selected.height, 0)

      if (sumH > targetH) return

      const free = targetH - sumH
      const part = free / (numSelected - 1)

      for (let i = 0, taken = 0, selection; selection = sorted[i]; i++) {
        const [ selectedX, selectedY, selectedZ ] = selection.position
        const { height: selectedH } = selection
        const { positionKeyframes: posKfs } = selection

        const delta = (minY - selectedY) + taken

        taken += selectedH + part

        const nextPos = Vec3(selectedX, selectedY + delta, selectedZ)

        updateProp("position", nextPos, selection)

        for (let j = 0, nextVal, kf; kf = posKfs[j]; j++) {
          nextVal = Vec3(kf[0], kf[1] + delta, kf[2])

          updateValue(kf, nextVal)
        }
      }
    },
    distributeXLayout(targetAsset) {
      const { assets: selected } = this
      const { length: numSelected } = selected

      if (numSelected < 2) return

      const targetW = targetAsset.width
      const targetH = targetAsset.height

      const [ minX, minY ] = targetAsset.position

      const maxX = targetW + minX
      const maxY = targetH + minY

      const sorted = selected.sort((a, b) => {
        const { position: [ aX, aY ] } = a
        const { position: [ bX, bY ] } = b

        const { width: aW } = a
        const { width: bW } = b

        const { height: aH } = a
        const { height: bH } = b

        if (aX < bX) return -1
        if (aX === bX && aY <= bY) return -1

        return 1
      })

      const sumW = sorted.reduce((sum, selected) =>
        sum + selected.width, 0)

      if (sumW > targetW) return

      const free = targetW - sumW
      const part = free / (numSelected - 1)

      for (let i = 0, taken = 0, selection; selection = sorted[i]; i++) {
        const [ selectedX, selectedY, selectedZ ] = selection.position
        const { width: selectedW } = selection
        const { positionKeyframes: posKfs } = selection

        const delta = (minX - selectedX) + taken

        taken += selectedW + part

        const nextPos = Vec3(selectedX + delta, selectedY, selectedZ)

        updateProp("position", nextPos, selection)

        for (let j = 0, nextVal, kf; kf = posKfs[j]; j++) {
          nextVal = Vec3(kf[0] + delta, kf[1], kf[2])

          updateKeyframeValue(kf, nextVal)
        }
      }
    },
    distributeYAnimate(targetRenderable, frame) {
      const { renderables: selected } = this
      const { length: numSelected } = selected

      if (numSelected < 2) return

      const targetW = targetRenderable.width
      const targetH = targetRenderable.height

      const [ minX, minY ] = targetRenderable.position

      const maxX = targetW + minX
      const maxY = targetH + minY

      const sorted = selected.sort((a, b) => {
        const { position: [ aX, aY ] } = a
        const { position: [ bX, bY ] } = b

        const { width: aW } = a
        const { width: bW } = b

        const { height: aH } = a
        const { height: bH } = b

        if (aY < bY) return -1
        if (aY === bY && aX <= bX) return -1

        return 1
      })

      const sumH = sorted.reduce((sum, selected) =>
        sum + selected.height, 0)

      if (sumH > targetH) return

      const free = targetH - sumH
      const part = free / (numSelected - 1)

      for (let i = 0, taken = 0, selection; selection = sorted[i]; i++) {
        const [ selectedX, selectedY, selectedZ ] = selection.position
        const { height: selectedH } = selection

        const delta = (minY - selectedY) + taken

        taken += selectedH + part

        const value = Vec3(selectedX, selectedY + delta, selectedZ)

        const kf = Kf({ frame, value })

        addKeyframe("position", kf, selection.asset)
      }
    },
    distributeXAnimate(targetRenderable, frame) {
      const { renderables: selected } = this
      const { length: numSelected } = selected

      if (numSelected < 2) return

      const targetW = targetRenderable.width
      const targetH = targetRenderable.height

      const [ minX, minY ] = targetRenderable.position

      const maxX = targetW + minX
      const maxY = targetH + minY

      const sorted = selected.sort((a, b) => {
        const { position: [ aX, aY ] } = a
        const { position: [ bX, bY ] } = b

        const { width: aW } = a
        const { width: bW } = b

        const { height: aH } = a
        const { height: bH } = b

        if (aX < bX) return -1
        if (aX === bX && aY <= bY) return -1

        return 1
      })

      const sumW = sorted.reduce((sum, selected) =>
        sum + selected.width, 0)

      if (sumW > targetW) return

      const free = targetW - sumW
      const part = free / (numSelected - 1)

      for (let i = 0, taken = 0, selection; selection = sorted[i]; i++) {
        const [ selectedX, selectedY, selectedZ ] = selection.position
        const { width: selectedW } = selection

        const delta = (minX - selectedX) + taken

        taken += selectedW + part

        const value = Vec3(selectedX + delta, selectedY, selectedZ)

        const kf = Kf({ frame, value })

        addKeyframe("position", kf, selection.asset)
      }
    },

  }, {
    actions: observable.shallow,
    assets: observable.shallow,
    audioRows: observable.shallow,
    keyframes: observable.shallow,
    targetStage: observable,
    fromJS: action.bound,
    setAssetSelection: action.bound,
    selectAssetKeyframes: action.bound,
    deselectAsset: action.bound,
    deselectAssetKeyframes: action.bound,
    clearAssetSelections: action.bound,
    setAudioSelection: action.bound,
    deselectAudio: action.bound,
    clearAudioSelections: action.bound,
    setActionSelection: action.bound,
    clearActionSelections: action.bound,
    setKeyframeSelection: action.bound,
    setKeyframeGroupSelection: action.bound,
    deselectKeyframe: action.bound,
    clearKeyframeSelections: action.bound,
    clearAllSelections: action.bound,
    shiftTimelineSelected: action.bound,
    duplicateSelected: action.bound,
    deleteSelected: action.bound,
    toggleTargetStage: action.bound,
    alignXMin: action.bound,
    alignXCenter: action.bound,
    alignXMax: action.bound,
    alignYMin: action.bound,
    alignYCenter: action.bound,
    alignYMax: action.bound,
    distributeX: action.bound,
    distributeY: action.bound,
    alignXMinLayout: action.bound,
    alignXCenterLayout: action.bound,
    alignXMaxLayout: action.bound,
    alignYMinLayout: action.bound,
    alignYCenterLayout: action.bound,
    alignYMaxLayout: action.bound,
    alignXMinAnimate: action.bound,
    alignXCenterAnimate: action.bound,
    alignXMaxAnimate: action.bound,
    alignYMinAnimate: action.bound,
    alignYCenterAnimate: action.bound,
    alignYMaxAnimate: action.bound,
    distributeYLayout: action.bound,
    distributeXLayout: action.bound,
    distributeYAnimate: action.bound,
    distributeXAnimate: action.bound,
  })
  // Renderable
  const renderable = observable({
    get current() {
      if (!player.activeSequence) return
      const stage = player.activeSequence.stage
      return Asset.renderable.transformAssetForAuthoring(
        Asset.renderable.transformAssetForDisplay(
          stage,
          null,
          player,
          (src) => player.cache.getAsset(player.activeSequence.objectId, src)
        )
      )
    },
    renderList: []
  })

  autorun(() => {
    const { activeSequence } = player
    const stage = activeSequence && activeSequence.stage
    if (stage) {
      const layerWise = flatMapTree(stage)
      layerWise.forEach((a, i) => updateProp('zIndex', i, a))
    }
  })

  autorun(() => {
    const isLayout = app.route.current === 'Layout'
    const playerState = player.state
    const isReady = player.isReady
    if (!isLayout) return
    if (playerState instanceof PlayingState) {
      if (isReady) app.state.global.setLoading(false)
    } else if (playerState instanceof ErrorState) {
      const cache = player.cache
      const sequenceId = player.activeSequence.objectId
      const failedFileNames = cache.getResourceDeps(sequenceId)
        .reduce((failed, { fileName }) => append(failed, fileName))
        .join(", ")
      const msg = `Failed to load external media for this sequence (${failedFileNames}). You may be
      encountering network issues or a remote service may not be able to
      fulfill the request.`
      app.state.global.alert.show('error', msg)
      app.state.global.setLoading(false)
      exit()
    } else {
      app.state.global.setLoading(true)
    }
  })

  const flatMapTree = createTransformer((a) => {
    const { children } = a
    return children && children.length
      ? children
          .reverse()
          .map((a) => flatMapTree(a))
          .reduce((xs, x) => x.concat(xs), [])
          .concat([a])
      : [a]
  })
  // Viewport State
  const viewport = ViewportState(app.storage, app.history, camera, player, selections, renderable, global)
  // Details State
  const details = {
    expanded: observable({
      base: true,
      option: true,
      form: true,
      character: true,
      paragraph: true,
      background: true,
      border: true,
      children: true,
      template: true,
      toggleSection: function(section) {
        this[section] = !this[section]
      }
    }, {
      toggleSection: action.bound
    })
  }

  // F5 utilities (where should these live? on Timeline?)
  const insertFrameCb = (e) => {
    // Prevent the default refresh event under WINDOWS system
    e.preventDefault()
    if (!player.activeSequence) return
    app.history.startGroup()
    insertFrame(player.activeSequence, player.frame)
    app.history.stopGroup()
  }
  const removeFrameCb = (e) => {
    // Prevent the default refresh event under WINDOWS system
    e.preventDefault()
    if (!player.activeSequence) return
    app.history.startGroup()
    removeFrame(player.activeSequence, player.frame)
    app.history.stopGroup()
  }
  // Reactions
  const reactions = observable({
    disposers: [],
    addReaction: function(reaction) {
      this.disposers.push(reaction)
    },
    disposeReactions: function() {
      this.disposers.forEach((disposer) => disposer())
    },
    handleDurationChange: function() {
      // EAT-2393: make sure scrubber can't get out of bounds when changing duration
      const sequenceDurationReaction = reaction(
        () => player.activeSequence.duration,
        (newDuration) => player.setFrame(player.frame) // Note: the setFrame action already ensures a max of the duration
      )
      this.addReaction(sequenceDurationReaction)
    },
    handleAddedAudio: function() {
      // Handle adding Audio media to the currently opened sequence (i.e. the AudioManager needs to be re-Initialized)
      const audioResetter = reaction(
        () => player.activeSequence.audioRows.map((row) => row.audioMedia),
        (audioMedia) => (player.state = new ReadyState(player))
      )
      this.addReaction(audioResetter)
    },
    handleAddedVideo: function() {
      // See above
      const videoResetter = reaction(
        () => (player.activeSequence.videoRows.length, player.activeSequence.videoRows.map((row) => (row.videoMedia, row.startTime))),
        (videoMedia) => (player.state = new ReadyState(player))
      )
      this.addReaction(videoResetter)
    },
    handlePanelSizing: function() {
      const widgetsPanel = observe(app.state.ui.collapseState.panels, 'Widgets', (change) => {
        requestAnimationFrame(viewport.resize)
      })
      this.addReaction(widgetsPanel)
    }
  }, {
    addReaction: action.bound,
    disposeReactions: action.bound,
    handleDurationChange: action.bound,
    handleAddedAudio: action.bound,
    handleAddedVideo: action.bound,
    handlePanelSizing: action.bound
  })

  // Hotkeys
  // Key map (key sequence/combo to event & function)
  const hotKeyMap = [
    ['mod+d', 'keydown', selections.duplicateSelected],
    ['backspace', 'keyup', selections.deleteSelected],
    ['del', 'keyup', selections.deleteSelected],
    ['esc', 'keyup', () => { selections.clearAllSelections; app.state.timeline.easingContextMenu.hide()}],
    ['=', 'keydown', camera.zoomIn],
    ['-', 'keydown', camera.zoomOut],
    ["'", 'keydown', () => selections.shiftTimelineSelected(1)],
    ["shift+'", 'keydown', () => selections.shiftTimelineSelected(10)],
    [';', 'keydown', () => selections.shiftTimelineSelected(-1)],
    ['shift+;', 'keydown', () => selections.shiftTimelineSelected(-10)],
    ['shift+,', 'keydown', player.backTenFrames],
    [',', 'keydown', player.previousFrame],
    ['shift+.', 'keydown', player.forwardTenFrames],
    ['.', 'keydown', player.nextFrame],
    ['mod+0', 'keydown', viewport.resetCameraZoom],
    ['mod+1', 'keydown', viewport.resetCameraCenter],
    ['mod+\\', 'keydown', viewport.toggleAssetOutline],
    ['space', 'keydown', player.togglePlayback],
    ['ctrl+k', 'keydown', insertFrameCb],
    ['ctrl+shift+k', 'keydown', removeFrameCb],
    [
      'up',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepUpOne))
    ],
    [
      'shift+up',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepUpTen))
    ],
    [
      'down',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepDownOne))
    ],
    [
      'shift+down',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepDownTen))
    ],
    [
      'left',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepLeftOne))
    ],
    [
      'shift+left',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepLeftTen))
    ],
    [
      'right',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepRightOne))
    ],
    [
      'shift+right',
      'keydown',
      action(() => viewport.translateSelectionsByVec(selections.renderables, stepRightTen))
    ],
    ['shift+`', 'keydown', () => app.state.ui.timelineRow.toggleExpand()]
  ]
  const handleHotkeys = action((keyboard) => {
    for (let i = 0, binding; (binding = hotKeyMap[i]); i++) {
      const [hotkey, evt, fn] = binding
      keyboard.bind(hotkey, fn, evt)
    }
  })
  const disposeHotkeys = action((keyboard) => {
    for (let i = 0, binding; (binding = hotKeyMap[i]); i++) {
      const [hotkey, evt, fn] = binding
      keyboard.unbind(hotkey, evt)
    }
  })
  // Reset on Edition change
  const reset = action(() => {
    // Note: for now nothing from camera, clock, renderable, viewport or details needs to be reset
    app.state.timeline.reset()
    player.reset()
    player.cache.removeExcept()
    player.setActiveSequence(null)
    selections.clearAllSelections()
  })
  // Setup when entering Layout view
  const setup = action((vars, sequence) => {
    if ("string" === typeof sequence) {
      sequence = app.edition.chapters.reduce((match, ch) =>
        match ?
          match :
          ch.sequences.find(({ objectId }) =>
            sequence === objectId),
        null)
    }
    player.cache.removeExcept()
    app.state.timeline.reset()
    player.setActiveSequence(sequence)
    player.setVariables(vars)
    selections.clearAllSelections()
  })
  // Setup when traversing EditionNavigator
  const setupFromNavigator = action((vars, sequenceId) => {
    app.edition.chapters.map((chapter) => {
      chapter.sequences.map((sequence) => {
        if (sequence.objectId === sequenceId) {
          setup(vars, sequence)
          app.state.structure.selections.setSelection(sequence)
        }
      })
    })
  })
  // rAF update loop
  const update = action(() => {
    player.isPaused ? clock.pause() : clock.tick()
    player.update(clock.dT)
    requestAnimationFrameId = requestAnimationFrame(update)
  })
  const enter = action(() => {
    player.reset()
    player.state = new ReadyState(player)
    selections.clearAllSelections()
    reactions.handleDurationChange()
    reactions.handleAddedAudio()
    reactions.handleAddedVideo()
    reactions.handlePanelSizing()
    handleHotkeys(app.signals.keyboard)
    update()
  })
  const exit = action(() => {
    // TODO: TimelineState should probably just be a part of LayoutState
    player.reset()
    selections.clearAllSelections()
    reactions.disposeReactions()
    disposeHotkeys(app.signals.keyboard)
    cancelAnimationFrame(requestAnimationFrameId)
    requestAnimationFrameId = null
  })
  return {
    camera,
    clock,
    player,
    selections,
    renderable,
    viewport,
    details,
    reset,
    setup,
    setupFromNavigator,
    enter,
    exit
  }
}
