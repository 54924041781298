import { action } from 'mobx'
import { observable } from 'mobx'
import { TASK_SORT_DIR } from './task-search.constants'
import { TASK_SORT_ORDER } from './task-search.constants'
import { TASK_STATE } from './task-state.constants'
import { UUID } from 'eplayer-core'



export function taskQueueStore(queryAll) {
  const bindings = {
    err: observable.ref,
    queryChangedFilter: action.bound,
    queryChangedSearch: action.bound,
    queryChangedSort: action.bound,
    queryNextPage: action.bound,
    remove: action.bound,
    reset: action.bound,
  }

  const state = {
    err: null,
    data: [],
    pageSize: 20,
    pageNumber: -1,
    sortDir: TASK_SORT_DIR.ASC,
    sortProp: TASK_SORT_ORDER.Position,
    searchValue: '',
    hasLastPage: false,
    filterUser: '',
    fetchingNextPage: false,
    fetchingQuery: false,

    async queryChangedFilter(username) {
      const { searchValue } = this
      const { sortProp } = this
      const { pageSize } = this
      const { sortDir } = this

      let page
      const pageNumber = 0
      const filter = username ? username : ''
      this.filterUser = filter
      this.fetchingQuery = true
      
      try {
        page = await queryAll(sortProp, sortDir, pageSize, pageNumber, filter, searchValue)
      } catch(err) {
        this.filterUser = ''
        this.err = err
        this.fetchingQuery = false
        return
      }

      const { results } = page
      const { lastPage } = page
      const { currentPage } = page
      this.pageNumber = currentPage
      this.hasLastPage = lastPage
      this.filterUser = filter
      this.data.replace(results)
      this.fetchingQuery = false
    },

    async queryChangedSearch(nextValue) {
      const { filterUser  } = this
      const { searchValue  } = this
      const { sortProp } = this
      const { pageSize } = this
      const { sortDir } = this

      let page
      const pageNumber = 0
      const nextSearchValue = nextValue ? nextValue : ''
      this.searchValue = nextSearchValue
      this.fetchingQuery = true
      try {
        page = await queryAll(sortProp, sortDir, pageSize, pageNumber, filterUser, nextSearchValue)
      } catch(err) {
        this.err = err
        this.searchValue = searchValue
        this.fetchingQuery = false
        return
      }
      const { results } = page
      const { lastPage } = page
      const { currentPage } = page
      this.pageNumber = currentPage
      this.hasLastPage = lastPage
      this.searchValue = nextSearchValue
      this.data.replace(results)
      this.fetchingQuery = false
    },

    async queryChangedSort(nextValue) {
      const { searchValue } = this
      const { filterUser  } = this
      const { sortProp } = this
      const { pageSize } = this
      const { sortDir } = this

      const isSameSortProp = nextValue === sortProp 
      const nextSortProp = isSameSortProp ? sortProp : nextValue
      const nextSortDir = isSameSortProp ?
        sortDir === TASK_SORT_DIR.DESC ?
          TASK_SORT_DIR.ASC :
          TASK_SORT_DIR.DESC :
        TASK_SORT_DIR.DESC
      const pageNumber = 0
      let page

      if (isSameSortProp) {
        this.sortDir = nextSortDir
      } else {
        this.sortDir = nextSortDir
        this.sortProp = nextSortProp
      }
      this.fetchingQuery = true
      try {
        page = await queryAll(nextSortProp, nextSortDir, pageSize, pageNumber, filterUser, searchValue)
      } catch(err) {
        this.err = err
        this.sortProp = sortProp
        this.sortDir = sortDir
        this.fetchingQuery = false
        return
      }
      const { results } = page
      const { lastPage } = page
      const { currentPage } = page
      this.pageNumber = currentPage
      this.hasLastPage = lastPage
      this.sortProp = nextSortProp
      this.sortDir = nextSortDir
      this.data.replace(results)
      this.fetchingQuery = false
    },
    async queryNextPage() {
      const { filterUser } = this
      const { searchValue } = this
      const { pageNumber } = this
      const { sortProp } = this
      const { pageSize } = this
      const { sortDir } = this

      const nextPage = pageNumber + 1
      let page

      this.fetchingNextPage = true
      try {
        page = await queryAll(sortProp, sortDir, pageSize, nextPage, filterUser, searchValue)
      } catch(err) {
        this.err = err
        this.fetchingNextPage = false
        return
      }
      const { results } = page
      const { lastPage } = page
      const { currentPage } = page
      this.pageNumber = currentPage
      this.hasLastPage = lastPage
      this.data.push(...results)
      this.fetchingNextPage = false
    },
    remove(taskId) {
      const { data } = this
      data.remove(data.find(t => t.id === taskId))
    },
    reset() {
      this.err = null
      this.pageSize = 20
      this.pageNumber = -1
      this.sortDir = TASK_SORT_DIR.DESC
      this.sortProp = TASK_SORT_ORDER.Position
      this.searchValue = ''
      this.hasLastPage = false
      this.filterUser = ''
      this.data.clear()
    },
  }

  return observable(state, bindings)
}
