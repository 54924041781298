import { Temporal } from 'temporal-polyfill'
import { Intl } from 'temporal-polyfill'

export function toZonedDateTime(unixEpoch: number) {
  const zone = Temporal.Now.timeZoneId()
  const instant = Temporal.Instant.fromEpochMilliseconds(unixEpoch)
  const dateTime = instant.toZonedDateTimeISO(zone)
  return dateTime
}

export function getDateDay(dateTime: Temporal.ZonedDateTime) {
  const date = dateTime.toPlainDate()
  const formattedDate = new Intl.DateTimeFormat('en-us', { dateStyle: 'short'});
  return formattedDate.format(date)
}

export function getDateTime(dateTime: Temporal.ZonedDateTime) {
  const time = dateTime.toPlainTime()
  const formattedTime = new Intl.DateTimeFormat('en-us', { timeStyle: 'short'});
  return formattedTime.format(time)
}
