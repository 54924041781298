import React from 'react'
import { computed } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import Controls from './controls'
import Playhead from './playhead'
import Ruler from './ruler'
import AudioLabels from './audio-labels'
import AssetLabels from './asset-labels'
import VideoLabels from './video-labels'
import MasterActionsTimeline from './actions-timelines'
import { MasterAudioTimeline, AudioTimelines } from './audio-timelines'
import { MasterVideoTimeline, VideoTimelines } from "./video-timelines"
import { AssetTimelines } from './asset-timelines'
import {
  TIMELINE_SNAP_HEIGHT,
  TIMELINE_HEADER_HEIGHT,
  TIMELINE_AUDIO_MIN_HEIGHT,
  TIMELINE_ASSERT_MIN_HEIGHT
} from '../../constants'
import { toggleProp } from '../../actions/edition'
import { setSequenceDuration } from '../../actions/edition'

class TimelineComponent extends React.Component {
  get frames() {
    const { state, sequence } = this.props
    const { timeline: timelineState } = state
    return timelineState.zoom * sequence.duration
  }
  get frameWidth() {
    const { state, sequence } = this.props
    const { timeline: timelineState } = state
    return timelineState.timelineElSize.timelineWidth / this.frames
  }
  toggleLabels = (e) => {
    const { state } = this.props
    const { timeline: timelineState } = state
    timelineState.toggleAudioText()
  }
  toggleMute = (e) => {
    const { state } = this.props
    const { timeline: timelineState } = state
    const { audioRows } = this.props.sequence

    timelineState.toggleAudioMute()

    for (const row of audioRows) {
      toggleProp('muted', row.timelineState)
    }
  }
  toggleAudioLock = (e) => {
    const { state } = this.props
    const { layout: layoutState, timeline: timelineState } = state
    layoutState.selections.clearAudioSelections()
    timelineState.toggleAudioLock()
  }
  toggleAssetsLock = (e) => {
    const { state } = this.props
    const { layout: layoutState, timeline: timelineState } = state
    layoutState.selections.clearAssetSelections()
    timelineState.toggleAssetsLock()
  }
  setSequenceDuration = (val) => {
    const { sequence } = this.props
    let newDuration = parseInt(val)
    setSequenceDuration(sequence, newDuration)
  }
  toggleTimelineHeight = () => {
    const { state } = this.props
    const { ui } = state
    ui.timelineRow.toggleExpand()
  }
  componentDidMount() {
    const { state } = this.props
    const { timeline: timelineState } = state
    // Initialize Size and Position
    timelineState.resize()
  }

  render() {
    const { state, sequence, signals } = this.props
    const { layout: layoutState, timeline: timelineState, global: globalState, ui } = state

    const { setTimelineEl, timelineElSize, zoom, offset } = timelineState
    const { player } = layoutState
    const { frame, playing, animateMode } = player

    const duration = sequence.duration || 1
    const wholeFrame = Math.floor(frame)

    const frames = this.frames
    const frameWidth = this.frameWidth
    const { timelineRow } = ui
    const { free: timelineFree } = timelineRow
    // TODO: still not entirely sure what this is supposed to be doing aside from moving the viewable section along with the playhead
    // if (wholeFrame === 1 && playing) setFrameOffset(timeline, 1, duration, frames)
    // else if (frame > offset + frames && playing) setFrameOffset(timeline, Math.floor(offset + frames), duration, frames)
    // else if (frame < offset && playing) setFrameOffset(timeline, Math.floor(offset - frames), duration, frames)

    const timelineProps = {
      id: 'timeline',
      className: `${timelineFree ? 'free' : ''} ${animateMode ? 'animate-mode' : ''}`,
      ref: setTimelineEl,
      tabIndex: 0
    }

    const sharedProps = {
      globalState,
      layoutState,
      timelineState,
      duration,
      frames,
      frameWidth,
      offset,
      width: timelineElSize.timelineWidth
    }

    const onGrabberDown = (e, row) => {
      document.body.addEventListener('mousemove', onGrabberMove, false)
      document.body.addEventListener('mouseup', onGrabberUp, false)
      this.startY = e.clientY

      this.startHeight = timelineRow.audioHeight
    }
    const onGrabberMove = (e) => {
      const diff = this.startY - e.clientY
      const headerHeight = TIMELINE_HEADER_HEIGHT

      const currentHeight = this.startHeight - diff
      const assertHeight = timelineRow.height - headerHeight - currentHeight
      if (
        !(currentHeight < TIMELINE_AUDIO_MIN_HEIGHT || assertHeight < TIMELINE_ASSERT_MIN_HEIGHT)
      ) {
        timelineRow.setAudioHeight(currentHeight)
        timelineRow.setAssertHeight(assertHeight)
      }
    }
    const onGrabberUp = (e) => {
      document.body.removeEventListener('mousemove', onGrabberMove, false)
      document.body.removeEventListener('mouseup', onGrabberUp, false)
      this.startY = null
      this.startHeight = null
    }

    return (
      <div {...timelineProps}>
        <button onClick={() => timelineRow.toggleFree()}>
          { timelineFree ? "Close" : "Expand" }
        </button>
        <Controls {...sharedProps} setSequenceDuration={this.setSequenceDuration} />
        <div className="timeline-ruler">
          <Playhead {...sharedProps} height={timelineFree ? window.innerHeight : timelineRow.height}   stage={sequence.stage} />
          <Ruler {...sharedProps} />
          <div
            className={
              timelineRow.height > TIMELINE_SNAP_HEIGHT
                ? `chevron-xtrasmall desc`
                : `chevron-xtrasmall asc`
            }
            onClick={this.toggleTimelineHeight}
          />
        </div>
        <div className="master-content">
          <div className="labels">
            <div className="actions-labels">
              <div className={`actions-label master`}>
                <div className="icon">
                  <i className="sprite timeline actions" />
                </div>
                <span className="asset-name">
                  <span className="truncate">Actions</span>
                </span>
              </div>
            </div>
          </div>
          <div className="timelines">
            <div className="actions-timelines">
              <MasterActionsTimeline {...sharedProps} actions={sequence.actions} />
            </div>
          </div>
        </div>
        <div className="master-content">
          <div className="labels">
            <div className="audio-labels">
              <div className={`audio-label master expanded`}>
                <div className="icon">
                  <i className="sprite timeline audio" />
                </div>
                <span className="asset-name">
                  <span className="truncate">Video</span>
                </span>
                <button
                  type="button"
                  title="Toggle Row Labels"
                  onClick={this.toggleLabels}
                  className={`action ${timelineState.showText ? 'active' : ''}`}>
                  <i className="sprite timeline display" />
                </button>
                <button
                  type="button"
                  title="Mute"
                  onClick={this.toggleMute}
                  className={`action ${timelineState.muteAll ? 'active' : ''}`}>
                  <i className="sprite timeline mute" />
                </button>
                <button
                  type="button"
                  title="Lock"
                  onClick={this.toggleVideoLock}
                  className={`action ${timelineState.videoLocked ? 'active' : ''}`}>
                  <i className="sprite timeline lock" />
                </button>
                <button
                  type="button"
                  title="Duplicate"
                  className="action"
                  style={{ cursor: 'default' }}>
                  <i className="sprite timeline clone" />
                </button>
              </div>
            </div>
          </div>
          <div className="timelines">
            <div className="audio-timelines">
              <MasterVideoTimeline {...sharedProps} rows={sequence.videoRows} />
            </div>
          </div>
        </div>
        <div className="timeline-content" style={{ height: timelineRow.audioHeight }}>
          <div className="labels">
            <VideoLabels
              timelineState={timelineState}
              layoutState={layoutState}
              rows={sequence.videoRows}
            />
          </div>
          <div className="timelines">
            <VideoTimelines {...sharedProps} rows={sequence.videoRows} />
          </div>
        </div>
        <div className="master-content">
          <div className="labels">
            <div className="audio-labels">
              <div
                className={`audio-label master expanded`}>
                <div className="icon">
                  <i className="sprite timeline audio" />
                </div>
                <span className="asset-name">
                  <span className="truncate">Audio</span>
                </span>
                <button
                  type="button"
                  title="Toggle Row Labels"
                  onClick={this.toggleLabels}
                  className={`action ${timelineState.showText ? 'active' : ''}`}>
                  <i className="sprite timeline display" />
                </button>
                <button
                  type="button"
                  title="Mute"
                  onClick={this.toggleMute}
                  className={`action ${timelineState.muteAll ? 'active' : ''}`}>
                  <i className="sprite timeline mute" />
                </button>
                <button
                  type="button"
                  title="Lock"
                  onClick={this.toggleAudioLock}
                  className={`action ${timelineState.audioLocked ? 'active' : ''}`}>
                  <i className="sprite timeline lock" />
                </button>
                <button
                  type="button"
                  title="Duplicate"
                  className="action"
                  style={{ cursor: 'default' }}>
                  <i className="sprite timeline clone" />
                </button>
              </div>
            </div>
          </div>
          <div className="timelines">
            <div className="audio-timelines">
              <MasterAudioTimeline {...sharedProps} rows={sequence.audioRows} />
            </div>
          </div>
        </div>
        <div className="timeline-content" style={{ height: timelineRow.audioHeight }}>
          <div className="labels">
            <AudioLabels
              timelineState={timelineState}
              layoutState={layoutState}
              rows={sequence.audioRows}
            />
          </div>
          <div className="timelines">
            <AudioTimelines {...sharedProps} rows={sequence.audioRows} />
          </div>
        </div>
        <div className="asset-container" style={{ height: timelineFree ? undefined : timelineRow.assertHeight }}>
          <button type="button" className="grabber horizontal" onMouseDown={onGrabberDown} />
          <div className="asset-labels">
            <div className={`asset-label master expanded`}>
              <div className="icon">
                <i className="sprite timeline assets" />
              </div>
              <span className="asset-name">
                <span className="truncate">Assets</span>
              </span>
              <button type="button" title="Hide" className="action">
                <i className="sprite timeline hide" />
              </button>
              <button
                type="button"
                title="Lock"
                onClick={this.toggleAssetsLock}
                className={`action ${timelineState.assetsLocked ? 'active' : ''}`}>
                <i className="sprite timeline lock" />
              </button>
              <button
                type="button"
                title="Duplicate"
                className="action"
                style={{ cursor: 'default' }}>
                <i className="sprite timeline clone" />
              </button>
            </div>
          </div>

          <div className="timeline-content">
            <div className="labels">
              <AssetLabels
                timelineState={timelineState}
                layoutState={layoutState}
                stage={sequence.stage}
              />
            </div>
            <div className="timelines">
              <AssetTimelines {...sharedProps} stage={sequence.stage} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

decorate(TimelineComponent, {
  frames: computed,
  frameWidth: computed,
})
const Timeline = observer(TimelineComponent)

export default Timeline
